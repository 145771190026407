<template>
  <v-container>
    <v-row v-if="dasboardData && dasboardData.subscriptionPlan.free && false">
      <v-col cols="12">
        <v-card class="card-corner-radius">
          <a target="_blank" href="https://www.amazon.com.br/gp/search?ie=UTF8&tag=exatamente0c-20&linkCode=ur2&linkId=90b4f02dd852157e683267a22c1a51d9&camp=1789&creative=9325&index=electronics&keywords=macbook">
            <video class="card-corner-radius" width="100%" playsinline="" muted="" oncontextmenu="return false;" autoplay loop>
              <source src="/images/ads/macbook-ads.mp4" type="video/mp4" />
            </video>
            <!-- <v-img 
            class="card-corner-radius"
            src="https://www.apple.com/v/macbook-air/q/images/overview/macbook_air__d2234mv3oe0y_large_2x.jpg" 
            >
            <v-footer absolute color="transparent">
              <v-card>
              </v-card>
            </v-footer>
            </v-img> -->
          </a>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card-actions class="m-0 p-0">
          <v-spacer/>
          <v-chip v-if="startAt || endAt" small color="primary" outlined>
            <span v-if="startAt">{{$t('startAt')}} {{new Date(startAt).toLocaleDateString()}}</span>
            <span v-if="endAt" class="ml-1">{{ $t('endAt')}} {{new Date(endAt).toLocaleDateString()}}</span>
          </v-chip>
          <v-badge
            :color="startAt || endAt ? `red lighten-1` : `transparent`"
            overlap
            right
            small
          >
            <v-badge
              bordered
              color="primary lighten-1"
              icon="mdi-calendar"
              overlap
            >
              <v-menu
                v-model="dialogFilter"
                offset-y
                left
                :close-on-click="false"
                :close-on-content-click="false"
                min-width="300"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-actions>
                    Filtros
                    <v-spacer/>
                    <v-btn dark icon color="red" @click="dialogFilter = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-divider/>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-menu
                          ref="startAt"
                          v-model="menuStartAt"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          left
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="startAt"
                              :label="$t('startAt')"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="startAt"
                            :active-picker.sync="activePickerStart"
                            :max="endAt ? endAt : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="get(), menuStartAt = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col>
                        <v-menu
                          ref="endAt"
                          v-model="menuEndAt"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          left
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="endAt"
                              :label="$t('endAt')"
                              prepend-icon="mdi-calendar-end"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="endAt"
                            :active-picker.sync="activePickerEnd"
                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                            :min="startAt"
                            @change="get(), menuEndAt = false, dialogFilter = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn text @click="startAt = null, endAt = null, get(), dialogFilter = false" color="red">
                      {{$t('clean')}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-badge>
          </v-badge>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-card class="card-corner-radius" elevation="0">
      <v-card-title class="blue--text">
        <v-icon color="blue" class="mr-2">mdi-chart-bar</v-icon>
        {{$t('metrics')}}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader type="list-item-avatar" v-if="loading"/>
            <v-fade-transition leave-absolute hide-on-leave>
              <v-card dark color="primary" v-if="!loading" dense class="card-corner-radius">
                <v-card-title class="white--text">
                  <b v-if="!dasboardData.subscriptionPlan.free" class="mr-2">{{dasboardData.clicks}}</b>
                  <b v-else class="free-plan-obfuscate mr-2">{{dasboardData.clicks}}</b>
                  <b>{{$t('views')}}</b>
                </v-card-title>
                <v-card-subtitle v-if="!dasboardData.onlineAddress">{{$t('countAllLinks')}}</v-card-subtitle>
                <v-card-subtitle v-else>
                  {{host}}/{{dasboardData.onlineAddress.code}}
                </v-card-subtitle>
                <v-card-subtitle v-if="false">
                  {{$t('countLinksTo')}} 
                  {{dasboardData.onlineAddress.tags}} 
                  <v-chip small>{{dasboardData.onlineAddress.code}}</v-chip>
                </v-card-subtitle>
                <v-card-actions>
                  <v-spacer/>
                  <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_BASIC')" :planData="planData" v-if="dasboardData.subscriptionPlan.free"/>
                </v-card-actions>
              </v-card>
            </v-fade-transition>
          </v-col>
          <v-col cols="12">
            <v-skeleton-loader type="card-heading, article, actions" v-if="loading"/>
            <v-fade-transition leave-absolute hide-on-leave>
              <v-card v-if="!loading && dasboardData.biographyDashboard" class="card-corner-radius">
                <v-card-title class="primary--text">{{$t('biopage')}}</v-card-title>
                <v-card-subtitle>
                  <b x-small color="primary" v-if="!dasboardData.subscriptionPlan.free">{{dasboardData.biographyDashboard.clicks}}</b> 
                  <v-chip x-small color="primary" v-else class="free-plan-obfuscate">?</v-chip> 
                  {{$t('totalClicksInBioPage')}}
                </v-card-subtitle>
                <v-card-text v-if="!dasboardData.subscriptionPlan.free">
                  <v-card-actions v-if="!dasboardData.biographyDashboard.onlineAddresses.filter(i => i.clicks).length">
                    <v-spacer/>
                     <v-chip x-small>{{$t(`periodWithoutResult`)}}</v-chip>
                    <v-spacer/>
                  </v-card-actions>
                  <GChart
                    v-else
                    type="PieChart"
                    :data="dasboardData.biographyDashboard.data"
                    :options="dasboardData.biographyDashboard.options"
                  />
                </v-card-text>
                <v-card-text v-else class="free-plan-obfuscate">
                  <GChart
                    type="PieChart"
                    :data="obfuscateDataBiograpgy"
                    :options="{
                      slices: {  4: {offset: 0.2},
                        12: {offset: 0.3},
                        14: {offset: 0.4},
                        15: {offset: 0.5},
                      }
                    }"    
                  />
                </v-card-text>
                <v-card-actions >
                  <v-btn v-if="false"  rounded x-small color="primary">{{dasboardData.biographyDashboard.onlineAddresses.length}}</v-btn>
                  <v-spacer/>
                  <v-chip-group v-if="!dasboardData.subscriptionPlan.free">
                    <v-chip  class="ml-1" x-small :color="dasboardData.biographyDashboard.options.colors[index]" rounded dark v-for="(a, index) in dasboardData.biographyDashboard.onlineAddresses" :key="a.id" disabled @click="get(a.id)" >
                      <!-- <v-icon>mdi-cursor-pointer</v-icon> -->
                      <span v-if="!dasboardData.subscriptionPlan.free">{{a.clicks}}</span>
                      <span v-else>
                        ?
                      </span>
                      <v-icon>mdi-circle-small</v-icon>
                      <!-- <v-icon v-if="isYoutube(a.tags)">mdi-youtube</v-icon>
                      <v-icon v-else-if="isFacebook(a.tags)">mdi-facebook</v-icon>
                      <v-icon v-else-if="isInstagram(a.tags)">mdi-instagram</v-icon>
                      <v-icon v-else-if="isLinkedin(a.tags)">mdi-linkedin</v-icon>
                      <v-icon v-else-if="isWhatsapp(a.tags)">mdi-whatsapp</v-icon>
                      <v-icon v-else-if="isEmail(a.tags)">mdi-email</v-icon>
                      <v-icon v-else-if="isEducation(a.tags)">mdi-school</v-icon>
                      <v-icon v-else-if="isTwitter(a.tags)">mdi-twitter</v-icon>
                      <v-icon v-else>mdi-web</v-icon> -->
                      <span class="ml-1">{{a.tags}}</span>
                    </v-chip>
                  </v-chip-group>
                  <v-chip-group v-else class="free-plan-obfuscate">
                    <v-chip class="ml-1" x-small color="primary" rounded dark v-for="(a) in dasboardData.biographyDashboard.onlineAddresses" :key="a.value">
                    <!-- <v-icon>mdi-cursor-pointer</v-icon>  -->
                    ???
                    </v-chip>
                  </v-chip-group>
                </v-card-actions>
                <v-card-actions v-if="dasboardData.subscriptionPlan.free">
                  <v-spacer/>
                  <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
                </v-card-actions>
              </v-card>
            </v-fade-transition>
          </v-col>
    
          <v-col cols="12">
            <v-skeleton-loader type="card-heading, article, actions" v-if="loading"/>
            <v-fade-transition leave-absolute hide-on-leave>
              <v-card v-if="!loading" class="card-corner-radius">
                <v-card-title class="primary--text">{{$t('appView')}}</v-card-title>
                <v-card-subtitle>{{$t('appViewDesc')}}</v-card-subtitle>
                <div v-if="!dasboardData.subscriptionPlan.free">
                  <v-card-actions v-if="!dasboardData.appFrom.filter(i => i.clicks).length">
                    <v-spacer/>
                    <v-chip x-small>{{$t(`periodWithoutResult`)}}</v-chip>
                    <v-spacer/>
                  </v-card-actions>
                  <v-card-text v-else>
                    <GChart 
                      type="PieChart"
                      :data="dasboardData.appFromData"
                      :options="dasboardData.appFromOptions"
                    />
                  </v-card-text>
                </div>
                <v-card-text v-else class="free-plan-obfuscate">
                  <GChart
                    type="PieChart"
                    :data="obfuscateData"
                    :options="{
                      slices: {  
                        4: {offset: 0.2},
                        12: {offset: 0.3},
                        14: {offset: 0.4},
                        15: {offset: 0.5},
                      }
                    }"  
                  />
                </v-card-text>
                <v-card-actions >
                  <v-spacer/>
                  <v-chip-group v-if="!dasboardData.subscriptionPlan.free">
                    <v-chip class="ml-1" x-small :color="dasboardData.appFromOptions.colors[index]" rounded dark v-for="(a, index) in dasboardData.appFrom" :key="a.value" disabled>
                    {{a.clicks}}
                    <v-icon>mdi-circle-small</v-icon>
                    <!-- <v-icon v-if="isYoutube(a.value)">mdi-youtube</v-icon>
                    <v-icon v-else-if="isFacebook(a.value)">mdi-facebook</v-icon>
                    <v-icon v-else-if="isInstagram(a.value)">mdi-instagram</v-icon>
                    <v-icon v-else-if="isLinkedin(a.value)">mdi-linkedin</v-icon>
                    <v-icon v-else-if="isWhatsapp(a.value)">mdi-whatsapp</v-icon>
                    <v-icon v-else-if="isEmail(a.value)">mdi-email</v-icon>
                    <v-icon v-else-if="isEducation(a.value)">mdi-school</v-icon>
                    <v-icon v-else-if="isTwitter(a.value)">mdi-twitter</v-icon>
                    <v-icon v-else>mdi-web</v-icon> -->
                    <span class="ml-1">{{a.value}}</span>
                    </v-chip>
                  </v-chip-group>
                  <v-chip-group v-else class="free-plan-obfuscate">
                    <v-chip class="ml-1" x-small color="primary" rounded dark v-for="(a) in 3" :key="a.value">
                    <v-icon>mdi-cursor-pointer</v-icon> 
                    ???
                    </v-chip>
                  </v-chip-group>
                </v-card-actions>
                <v-card-actions v-if="dasboardData.subscriptionPlan.free">
                  <v-spacer/>
                  <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
                </v-card-actions>
              </v-card>
            </v-fade-transition>
          </v-col>
          <v-col cols="12">
            <v-skeleton-loader type="card-heading, article, actions" v-if="loading"/>
            <v-fade-transition leave-absolute hide-on-leave>
              <v-card v-if="!loading" dense class="card-corner-radius">
                <v-card-title class="primary--text">{{$t('accessByDay')}}</v-card-title>
                <v-card-subtitle v-if="dasboardData.dates[0] || (startAt && endAt)">
                  {{$t('startAt')}}
                  <v-chip x-small color="primary">{{startAt ? startAt : dasboardData.dates[0].date}} 00h00m</v-chip> 
                  {{$t('endAt')}}
                  <v-chip x-small color="primary">{{endAt ? endAt : dasboardData.dates[dasboardData.dates.length -1].date}} 23h59m</v-chip> 
                </v-card-subtitle>
                <div v-if="!dasboardData.subscriptionPlan.free">
                  <v-card-actions v-if="!dasboardData.dates.length">
                    <v-spacer/>
                    <v-chip x-small>{{$t(`periodWithoutResult`)}}</v-chip>
                    <v-spacer/>
                  </v-card-actions>
                  <v-card-text v-else class="mr-0 pr-0 ml-0 mr-0">
                    <v-tabs
                      center-active
                      show-arrows
                    >
                      <v-tab v-for="(m, index) in dasboardData.pageClicksByDate" :key="`tab-clicks-by-date-${index}`"><b>{{m.monthYear}}</b></v-tab>
                      <v-tab-item
                        v-for="(m, index) in dasboardData.pageClicksByDate" 
                        :key="`tab-item-clicks-by-date-${index}`"
                      >
                        <v-card-subtitle>
                          <b>{{m.monthYear}}</b>
                        </v-card-subtitle>
                        <GChart
                          width="700px"
                          type="ColumnChart"
                          :data="m.clicksByDate"
                          :options="dasboardData.clicksByDateOptions"
                        />
                        <v-card-actions>
                          <v-btn small v-if="startAt || endAt" dark icon color="red" @click="startAt = null, endAt = null, get()">
                            <v-icon small>mdi-close</v-icon>
                          </v-btn>
                          <v-spacer/>
                          <v-chip-group>
                            <v-chip class="ml-1" x-small color="primary" rounded dark v-for="a in m.clicksByDate" :key="`date-tag-${a[0]}`" readonly @click="startAt = a[0], endAt = a[0], get()" v-show="a[0] != 'date'">
                              <!-- <v-icon>mdi-cursor-pointer</v-icon> -->
                              {{a[1]}}
                              <v-icon>mdi-circle-small</v-icon>
                              <!-- <v-icon>mdi-calendar</v-icon> -->
                              <span class="ml-1">{{a[0]}}</span>
                            </v-chip>
                          </v-chip-group>
                        </v-card-actions>
    
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                </div>
                <div v-else >
                  <v-card-text >
                    <GChart
                      class="free-plan-obfuscate"
                      type="ColumnChart"
                      :data="obfuscateData"
                      :options="dasboardData.clicksByDateOptions"
                    />
                    <v-card-actions class="mr-0 pr-0 ml-0 pl-0">
                      <v-spacer/>
                      <v-chip-group class="free-plan-obfuscate">
                        <v-chip class="ml-1" x-small color="primary" rounded dark v-for="(a, index) in 2" :key="`date-tag-${index}`" readonly>
                          <!-- <v-icon>mdi-cursor-pointer</v-icon> -->
                          ?
                          <v-icon>mdi-circle-small</v-icon>
                          <!-- <v-icon>mdi-calendar</v-icon> -->
                          <span class="ml-1">{{a.date}}</span>
                        </v-chip>
                      </v-chip-group>
                    </v-card-actions>
                  </v-card-text>
                  <v-card-actions v-if="dasboardData.subscriptionPlan.free">
                    <v-spacer/>
                    <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
                  </v-card-actions>
                </div>
              </v-card>
            </v-fade-transition>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader type="card-heading, article, actions" v-if="loading"/>
            <v-fade-transition leave-absolute hide-on-leave>
              <v-card v-if="!loading" dense class="card-corner-radius">
                <v-card-title class="primary--text">{{$t('displayToWindowSizeTitle')}}</v-card-title>
                <v-card-subtitle>
                  {{$t('displayToWindowSizeDescrition')}}
                </v-card-subtitle>
                <v-card-text :class="dasboardData.subscriptionPlan.id >= 3 ? '' : 'free-plan-obfuscate'">
                  <v-row v-for="i in $t('displays')" :key="`break-point-${i.id}`">
                    <v-col cols="2">
                      <v-icon :color="i.color">{{i.icon}}</v-icon>
                    </v-col>
                    <v-col>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-progress-linear
                            v-if="dasboardData.screenData.data.filter(s => s.value == i.id)[0]"
                            :value="dasboardData.screenData.data.filter(s => s.value == i.id)[0].clicks / dasboardData.screenData.clicks * 100"
                            :color="i.color"
                            height="10"
                          >
                            <template v-slot:default="{ value }">
                              <small class="white--text">{{Math.ceil(value)}}%</small>
                            </template>
                          </v-progress-linear>
                          <v-progress-linear
                            v-else
                            :color="i.color"
                            height="10"
                          >
                            <template v-slot:default="{ value }">
                              <small class="white--text">{{value}}%</small>
                            </template>
                          </v-progress-linear>
                        </v-col>
                        <v-col cols="12">
                          <v-card-actions class="mr-0 pr-0">
                            <v-spacer/>
                            <v-chip x-small :color="i.color" dark>
                              {{dasboardData.screenData.data.filter(s => s.value == i.id)[0] ? dasboardData.screenData.data.filter(s => s.value == i.id)[0].clicks : 0}} {{ i.description }}
                            </v-chip>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions v-if="dasboardData.subscriptionPlan.id < 3">
                  <v-spacer/>
                  <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
                </v-card-actions>
              </v-card>
            </v-fade-transition>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader type="card-heading, article, actions" v-if="loading"/>
            <v-fade-transition leave-absolute hide-on-leave>
              <v-card v-if="!loading" dense class="card-corner-radius">
                <v-card-title class="primary--text">{{$t('geoTitle')}}</v-card-title>
                <v-card-subtitle>{{$t('geoDescription')}}</v-card-subtitle>
                <div v-if="dasboardData.subscriptionPlan.id >= 3">
                  <GChart 
                    type="GeoChart"
                    :data="dasboardData.regionData.countriesData"
                    :options="dasboardData.regionData.countryOptions"
                    :settings="{
                      packages: ['geochart'],
                    }"
                  />
                  <v-tabs grow v-model="chartRegion" center-active v-if="false">
                    <v-tab>{{$t('byCountries')}}</v-tab>
                    <v-tab disabled>{{$t('byStates')}} <v-icon>mdi-clock-time-eleven-outline</v-icon></v-tab>
                    <v-tab disabled>{{$t('byCities')}} <v-icon>mdi-clock-time-eleven-outline</v-icon> </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="chartRegion">
                    <v-tab-item>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card-actions>
                        <v-select
                          :items="dasboardData.regionData.countries"
                          :label="$t('region')"
                          :item-value="v => v.code"
                          :item-text="v => `${v.clicks} ${v.code} - ${v.value}`"
                          v-model="dasboardData.regionData.stateOptions.region"
                          outlined
                          dense
                          clearable 
                        ></v-select>
                      </v-card-actions>
                      <GChart 
                        type="GeoChart"
                        :data="dasboardData.regionData.statesData"
                        :options="dasboardData.regionData.stateOptions"
                        :settings="{
                          packages: ['geochart'],
                        }"
                      />
                    </v-tab-item>
                    <v-tab-item>
                      <v-card-actions>
                        <v-select
                          :items="dasboardData.regionData.countries"
                          :label="$t('region')"
                          :item-value="v => v.code"
                          :item-text="v => `${v.clicks} ${v.code} - ${v.value}`"
                          v-model="dasboardData.regionData.cityOptions.region"
                          outlined
                          dense
                          clearable 
                        ></v-select>
                      </v-card-actions>
                      <GChart 
                        type="GeoChart"
                        :data="dasboardData.regionData.citiesData"
                        :options="dasboardData.regionData.cityOptions"
                        :settings="{
                          packages: ['geochart'],
                          // mapsApiKey: 'AIzaSyAJNAGmdoK9hgavlk8fsQ46BWDeQSXuHM8'
                        }"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </div>
                <div v-else>
                  <v-tabs grow v-model="chartRegion" center-active>
                    <v-tab>{{$t('byCountries')}}</v-tab>
                    <v-tab>{{$t('byStates')}}</v-tab>
                    <v-tab>{{$t('byCities')}}</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="chartRegion" class="free-plan-obfuscate">
                    <v-tab-item v-for="i in 3" :key="`chart-map-obfuscate-${i}`">
                      <GChart 
                        type="GeoChart"
                        :data="dasboardData.regionData.countriesData"
                        :options="dasboardData.regionData.countryOptions"
                        :settings="{
                          packages: ['geochart'],
                        }"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions>
                    <v-spacer/>
                    <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
                  </v-card-actions>
                </div>
              </v-card>
            </v-fade-transition>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader type="card-heading, article, actions" v-if="loading"/>
            <v-fade-transition leave-absolute hide-on-leave>
              <v-expansion-panels v-if="!loading" elevation-0>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-card-title class="mr-0 pr-0 ml-0 pl-0 primary--text">{{$t('platformTitle')}}</v-card-title>
                      <template v-slot:actions>
                        <v-chip dark color="primary" small>{{$t('advanced')}}</v-chip>
                        <v-icon color="primary">
                          $expand
                        </v-icon>
                      </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{$t('platformDescription')}}
                    <v-card-text v-if="dasboardData.subscriptionPlan.id >= 3" :class="'mr-0 pr-0 ml-0 pl-0'">
                      <v-row v-for="(i, index) in dasboardData.platformData.data" :key="`´platform-row-${index}`">
                        <v-col cols="2">
                          <v-icon :color="$t('platforms').filter(v => v.id == i.plataform)[0].color">
                            {{$t('platforms').filter(v => v.id == i.plataform)[0].icon}}
                          </v-icon>
                        </v-col>
                        <v-col>
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-progress-linear
                                :value="i.clicks / dasboardData.platformData.clicks * 100"
                                :color="$t('platforms').filter(v => v.id == i.plataform)[0].color"
                                height="10"
                              >
                                <template v-slot:default="{ value }">
                                  <small class="white--text">{{Math.ceil(value)}}%</small>
                                </template>
                              </v-progress-linear>
                            </v-col>
                            <v-col cols="12">
                              <v-card-actions class="mr-0 pr-0">
                                <v-spacer/>
                                <v-chip dark :color="$t('platforms').filter(v => v.id == i.plataform)[0].color" x-small> 
                                  {{ i.clicks}}
                                  <v-icon>mdi-circle-small</v-icon> 
                                  {{$t('platforms').filter(v => v.id == i.plataform)[0].name}}
                                </v-chip>
                              </v-card-actions>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <div v-else>
                      <v-card-text class="free-plan-obfuscate">
                        <v-row v-for="(i, index) in $t('platforms')" :key="`´platform-row-${index}`">
                          <v-col cols="2">
                            <v-icon :color="i.color">
                              {{i.icon}}
                            </v-icon>
                          </v-col>
                          <v-col>
                            <v-row no-gutters>
                              <v-col cols="12">
                                <v-progress-linear
                                  :value="0"
                                  :color="i.color"
                                  height="10"
                                >
                                  <template v-slot:default="{ value }">
                                    <small class="white--text">{{Math.ceil(value)}}%</small>
                                  </template>
                                </v-progress-linear>
                              </v-col>
                              <v-col cols="12">
                                <v-card-actions class="mr-0 pr-0">
                                  <v-spacer/>
                                  <v-chip dark :color="i.color" x-small> 
                                    <v-icon>mdi-cursor-pointer</v-icon>
                                    ?
                                    <v-icon>mdi-circle-small</v-icon> 
                                    ?
                                  </v-chip>
                                </v-card-actions>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
                      </v-card-actions>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
    <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { apiV1ClickDashboard } from "../../../service/resources/api-v1-click-dashboard";
import SubscriptionAndPlans from "./SubscriptionAndPlans.vue";
import IconInfoSubscriblePlan from "./IconInfoSubscriblePlan.vue";

  export default {
    name: 'UrlShortenerDashboard',
    props: ['screenData'],
    components: {
    SubscriptionAndPlans,
    IconInfoSubscriblePlan
},
    data: () => ({
      chartRegion: null,
      planData: {
        dialogPlansAndPrices: false,
      },
      requestNumber: 0,
      dasboardData: null,
      dialogFilter: false,
      activePickerStart: null,
      activePickerEnd: null,
      menuStartAt: false,
      menuEndAt: false,
      startAt: null,
      endAt: null,
      clicks: 0,
      interval: {},
      loading: true,
      chartOptions: {
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        }
      },
      obfuscateData: [
          ['?', '?'],
          ['?',     2],
          ['?',      3],
          ['?',  5],
          ['?', 6],
          ['?', 9]
        ],
      obfuscateDataBiograpgy: [],
      host: null,
    }),
    watch: {
      '$route': function(to) {
        this.get(to.params.id)
      }
    },
    mounted: function() {
      // var startAt = new Date();
      // startAt.setDate(startAt.getDate() -30);
      // this.startAt = startAt.toISOString().split('T')[0]
      // this.endAt = new Date().toISOString().split('T')[0]
      this.host = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;
      this.get()

    },
    computed: {

    },
    methods: {
      isFacebook: function(name) {
        return name.toLowerCase().includes('facebook')
      },
      isYoutube: function(name) {
        return name.toLowerCase().includes('youtube')
      },
      isInstagram: function(name) {
        return name.toLowerCase().includes('instagram')
      },
      isLinkedin: function(name) {
        return name.toLowerCase().includes('linkedin')
      },
      isWhatsapp: function(name) {
        return name.toLowerCase().includes('whatsapp')
      },
      isEmail: function(name) {
        return name.toLowerCase().includes('@')
      },
      isEducation: function(name) {
        return name.toLowerCase().includes('lattes') || name.toLowerCase().includes('buscatextual.cnpq')
      },
      isTwitter: function(name) {
        return name.toLowerCase().includes('twitter')
      },
      isYoutubePreview: function(name) {
        return name.toLowerCase().includes('watch')
      },
      get: function(id) {
        let request = this.requestNumber
        let self = this
        self.loading = true
        self.clicks = 0
        apiV1ClickDashboard.get({
          onlineAddressId: id ? id : this.$route.params.id,
          startAt: this.startAt,
          endAt: this.endAt
        }).then(result => {
          if(request == this.requestNumber) {
            this.obfuscateDataBiograpgy = [['link', 'clicks']]
            if(result.data.biographyDashboard) {
              result.data.biographyDashboard.onlineAddresses.forEach((element, index) => {
                this.obfuscateDataBiograpgy.push([element.tags, index+ 1])
              });
            }
            self.dasboardData = result.data
            self.loading = false
          }
          this.requestNumber++
          // setInterval(() => {
          // }, 2000)
          // let interval = 2000 / self.dasboardData.clicks
          // setInterval(() => {
          //   if(self.clicks < self.dasboardData.clicks)
          //     self.clicks += 1
          // }, interval)
        })
      }
    },
  }
</script>
<style scoped>

.vertical-text {
  writing-mode: vertical-rl !important;
}
</style>