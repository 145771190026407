<template>
  <div>
    <v-dialog v-model="data.dialog" width="500" persistent>
      <v-card elevation="0" v-if="data.img">
        <v-card-title class="primary--text ml-0 pl-2">
          <v-btn icon color="primary" @click="close">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <b>{{$t(`cropImageTitle`)}}</b>
          <v-spacer/>
          <v-btn outlined icon color="primary" @click="edit = true" small>
            <v-icon small>mdi-image-edit</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
        </v-card-actions>
        <div v-if="edit">
          <v-card-text>
            <v-card elevation="0" outlined class="card-corner-radius">
              <cropper
                :src="data.img.src"
                @change="change"
                ref="cropper"
                >
                <!-- :stencil-size="stencilSize" -->
            </cropper>
            </v-card>
          </v-card-text>
        </div>
        <div v-else>
          <v-card-text>
            <v-card elevation="0" outlined>
              <v-img :src="data.img.src"/>
            </v-card>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="edit ? confirm(result): confirm(data.img)" rounded> 
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { Cropper } from 'vue-advanced-cropper'
  import 'vue-advanced-cropper/dist/style.css';
  export default {
    name: 'CropImage',
    props: ['data', 'actionSave', 'stencilSize'],
    components: {
      Cropper
    },
    watch:{
      'data.dialog' : function(dialog) {
        this.edit = false
        if(dialog){
          this.result = {src: this.data.img}
          setTimeout(() => {
            this.zoom(false)
          }, 500)
        }
      }
    },
    data: () => ({
      imageRestriction: 'none',
      result: null,
      edit: false,
    }),
    mounted: function() {
    },
    computed: {
    },
    methods: {
      zoom(value) {
        this.$refs.cropper.zoom(value);
      },
      change({ coordinates, canvas, image }) {
        console.log(coordinates, canvas, image)
        if(!this.result)
          this.result = {src: canvas.toDataURL()}
        else
          this.result.src = canvas.toDataURL()
      },
      close: function(){
        this.data.dialog = false
      },
      confirm(img) {
        this.result = img
        this.actionSave(this.result)
        this.data.dialog = false
      }
    },
  }
</script>
<style scoped>
.cropper {
	background: #FFF;
}
.cropper-foreground-class{
  background: #FFF !important;
}

.vue-advanced-cropper__background, .vue-advanced-cropper__foreground{
  background: #FFF !important;
}
</style>