import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'pt-br': {
        projectDescriptionShortenUrl : `Encurtador de URL migly é uma ferramenta poderosa para encurtar links. Crie sua conta agora no link abaixo e acesse nosso painel. 
        Use o nosso encurtador de link para diminuir uma URL longa e gerar um link curto.`,
        shortenUrlBenefit1: 'Encurte links do Instagram, Youtube e Whatsapp. Encurte o link que você quiser.',
        shortenUrlBenefit2: 'Crie um link e direcione para todas suas redes sociais e projetos.',
        shortenUrlBenefit3: 'Você pode acompanhar a análise e ter controle total sobre seus links.',
        projectDescription : `Encurtador de URL migly é uma ferramenta poderosa para encurtar link. 
        Use o nosso Encurtador de Link para diminuir uma URL longa e gerar um link curto. 
        Se precisar de encurtador de url personalizado, acompanhar a análise e ter controle total sobre seus links. 
        Crie sua conta agora no link abaixo e acesse nosso painel. 
        Encurte links do Facebook, Instagram, Youtube & Whatsapp. Encurte o link que você quiser.`,
        shortenToURL: 'Encurte seu link',
        shortenToURLExample: 'Exemplo https://www.youtube.com/watch?v=pmnLca3FneU&t=1013s',
        shortenedUrl: `URL encurtada`,
        originalLink: 'Link original',
        shortenAddress: `Encurtar links`,
        uniqueLink: 'Conecte tudo com um único link',
        urlCopiedToClipboard: 'Link copiado',
        copyUrl: 'Copiar link',
        viewUrl: 'Visitar link',
        toClean: 'Limpar',
        invalidUrl : `URL inválida`,
        login: `Entrar`,
        signUp: `Criar Conta`,
        anchorLink1: `redução de urls para economizar espaço`,
        areYouSure: 'Você tem certeza?',
        credit: 'Crédito',
        submissions: 'Envios',
        of: 'de',
        smsConfig: 'Configuração dos disparos',
        businessName: 'Nome do negócio, serviço ou programa',
        exemple: 'Exemplo',
        exempleSmsOptIn: 'SMS para solicitação de permissão',
        exempleSmsOptInDesc: 'Gostaria de receber SMS de _? Acesse migly.in/i/xXx p/ receber. Ate aceitar, nao enviaremos mais. Taxas de dados podem ser aplicadas ao responder',
        exempleSmsMarketing: 'SMS exemplo de marketing',
        exempleSmsMarketingDesc: '_: Economize de 25 a 50% em itens em promocao em loja.com. Clique aqui para navegar pela venda: https://loja.com. Click migly.in/o/xXx p/ sair.',
        
        smsTryInfo: `Estamos entusiasmados em oferecer a você a oportunidade de testar nossa poderosa API de envio de SMS! Com esta API, você poderá integrar facilmente a capacidade de enviar mensagens de texto para seus clientes ou usuários em seu próprio aplicativo ou plataforma. Para começar o teste gratuito, envie um email solicitando o teste da ferramenta para <b>contato@exatamente-solucoes.com</b>.`,

        businerRequiredInfo: 'Configure os disparos informando o nome do negócio, programa ou serviço para salvar contatos e enviar mensagens.',

        createEbook: 'Crie um e-book',
        createEbookSubtitle: 'Ofereça uma oferta de valor',
        createEbookText: 'Para incentivar os visitantes a compartilharem suas informações de contato, ofereça uma oferta de valor em troca, como um e-book gratuito.',

        operationCannotBeUndone: 'Essa operação não poderá ser desfeita',
        deleteConfirm: 'Confirmar Exclusão',
        searchShortURL: 'Buscar Links',
        save: 'Salvar',
        invalid: 'inválido',
        provideYourEmail: 'Informe seu e-mail',
        'invalid.sms.text.size' : 'A mensagem não pode ultrapassar 160 caracters',
        editAddress: 'Editar link migly',
        createBio: 'Crie sua página biográfica',
        pageBio: 'Link para bio',
        followMetrics: 'Acompanhar métricas',
        generateWhatsAppLink: 'Gerar link para WhatsApp',
        whatsAppNumber: 'Número',
        loggout: 'Sair',
        whatsAppText: 'Mensagem Padrão',
        deleteAddressInfoPlan: 'O endereço dos links so podem ser alterados a partir do plano básico',
        toChart: `Analizar clicks nesse link`,
        yourPageAvailibleIn: 'Sua página estará disponível em ',
        panel: 'Painel',
        infoProduct: 'Infoproduto',
        infoProducts: 'Infoprodutos',
        productImage: 'Imagem do produto',
        topicsAndThemes: 'Tópicos, itens e benefícios',
        topicsAndThemesTitle: 'Título dos itens do seu produto',
        topicAndThemesTitle: 'Assunto, tópico, benefício ou item',
        infoproductMoreTitle: 'Mais sobre o seu infoproduto',
        infoproductMoreSubtitle: 'Fale mais sobre o conteúdo do infoproduto',
        fillInFormForReceiveEbook: 'Preencha o formulário para receber o e-book',
        fillInFormForReceiveLink: 'Preencha o formulário para receber o link',
        fillInYourEmailPolicyPrivacy: 'A Exatamente Soluções Educacionais precisa das informações de contato que você nos fornece para contatá-lo e enviar o link do produto. Você pode cancelar a assinatura dessas comunicações a qualquer momento. Para obter informações sobre como cancelar a assinatura, bem como nossas práticas de privacidade e compromisso com a proteção de sua privacidade, consulte nossa Política de Privacidade no final da página.',
        topicsAndThemesSubtitle: 'Descreva os principais tópicos ou áreas de interesse relacionadas ao produto ou serviço e identifique os benefícios ou resultados desejados que o cliente espera obter.',
        product: 'Produto',
        editLinkSubdomain: 'Subdomínio',
        editLinkSubdomainSubtitle: 'O acesso a sua página depende desse subdomínio.',
        productSubtitle: 'Defina qual tipo do infoproduto enviará para seus clientes',
        pageAparence: 'Aparência da página',
        pageAparenceSubtitle: 'Defina a aparência do site do seu infoproduto',
        linkAparencePreview: 'Defina a aparência do link quando compartilhar no whattsapp e redes sociais',
        logoOrlogotipo: 'Logotipo do site ou produto',
        logoOrlogotipoSizeInfo: 'Escolha uma imagem que tenha 300 x 80 pixels ou que respeite essa proporção e tenha o fundo transparente',
        cropImageTitle: 'Cortar e redimensionar imagem',
        subtitle: 'Subtitulo',
        shortDescription: 'Uma breve descrição',
        actionName: 'Nome da ação',
        infoproductType: 'Tipo de infoproduto',
        downloadNow: 'Baixe agora',
        accessNow: 'Tenha acesso agora',
        eBook: 'E-Book',
        file: 'Arquivo',
        editInfoProduct: 'Editar Infoproduto',
        createInfoProduct: 'Criar Infoproduto',
        eBookSendDescription: 'Assim que seus clientes preencherem o formulário de interesse em seu produto, seu arquivo em PDF será prontamente enviado a eles.',
        linkSendDescription: 'Assim que seus clientes preencherem o formulário de interesse em seu produto, seu link será prontamente enviado a eles.',
        createNewInfoproduct: 'Criar novo infoproduto',
        messageToUserNotLoggedInURLShortener: 'Seu link foi gerado com sucesso, no entanto, ele ainda não está associado à sua conta. Como resultado, você não poderá acessar métricas detalhadas nem personalizar a prévia do link nas redes sociais.',
        plansAndPrices: `Planos e preços`,
        plansAndPricesDescription: 'Obtenha uma solução essencial para gerar, compartilhar e rastrear links para todas as formas de comunicação. Escolha seu plano abaixo. Você terá 7 dias para testar.',
        plan_FREE: 'Gratuito',

        trialPeriod: 'Se esta for sua primeira ativação, você terá sete dias para testar.',

        plan_BASIC: 'Básico',
        plan_PRO: 'Profissional',
        UNLIMITED_LINKS: 'Links ilimitados',
        desc_UNLIMITED_LINKS: {icons: ['mdi-link', 'mdi-all-inclusive'], description: "Não existe limites para a quantidade de links encurtados, você pode criar e encurtar quantos endereços desejar."},
        BIO: 'Página biográfica unificadora de links',
        desc_BIO: {icons: ['mdi-bio', 'mdi-vector-union', 'mdi-text-account'], description: "Facilite o acesso a todas suas redes sociais, formas de contato e projetos online com sua página biográfica unificadora de links."},
        QR_CODE: 'QRCodes',
        desc_QR_CODE: {icons: ['mdi-data-matrix-scan'], description: "Facilite ainda mais o acesso a seus links com QRCodes. Com esse recurso seus clientes nem precisa digitar os endereços para suas páginas."},
        UNLIMITED_CLICKS: 'Links sem limite de clicks',
        desc_UNLIMITED_CLICKS: {icons: ['mdi-cursor-default-click', 'mdi-all-inclusive'], description: "Os endereços criados/encurtados sempre estarão disponíveis e nunca expiram, eles também não tem limite para clicks, ou seja, seus clientes ou seguidores sempre terão acesso aos seus links encurtados."},
        COUNT_CLICKS: 'Contagem de clicks',
        desc_COUNT_CLICKS: {icons: ['mdi-cursor-default-click', 'mdi-counter'], description: "Saiba quantas pessoas clicaram em seus links."},
        REPORT_COMPLETE: 'Relatório completo',
        desc_REPORT_COMPLETE: {icons: ['mdi-chart-arc', 'mdi-chart-donut-variant', 'mdi-chart-histogram'], description: "Relatório completo de acessos aos seus links por dispositivo, região e plataforma."},
        LOGO_LOADING: 'Personalização completa',
        desc_LOGO_LOADING: {icons: ['mdi-link', 'mdi-office-building', 'mdi-cog'], description: "Exiba o nome da sua empresa em telas de carregamento e direcionamento para seus links gerados, personalização completa da url e customização da pré-visualização para redes sociais."},
        VERIFY_LINK: 'Verificar links que não funcionam',
        desc_VERIFY_LINK: {icons: ['mdi-link' , 'mdi-cube-scan'], description: "Verificamos links encurtados periodicamente e te avisamos quando um link não está funcinando, assim saberá quando está perdendo acessos."},
        UPDATE_LINK: 'Alterar o direcionamento dos links gerados',
        desc_UPDATE_LINK: {icons: ['mdi-link', 'mdi-circle-edit-outline'], description: "Você poderá editar todos os links encurtados após a criação deles, mudando direcionamento para outro site e até mesmo alterar o nome encurtado do link."},
        SEND_SMS: 'Envio de SMS',
        desc_SEND_SMS: {icons: ['mdi-message-processing' , 'mdi-clock-outline', 'mdi-message-badge-outline'], description: "Enviamos e agendamos envio de SMS para seus clientes. <br> É preciso comprar créditos para enviar mensagens.<br> O custo de cada SMS é de <i>R$ 0,30</i> e você ganha R$ 4,00 todos os meses ao assinar o plano profissional"},
        smsInfo: 'O custo de cada SMS é de <i>R$ 0,30</i>',
        API_ACCESS: 'Acesso a API para integração',
        API_ACCESS_LINK: 'Acesso a API de integração',
        desc_API_ACCESS: {icons: ['mdi-dev-to', 'mdi-api'], description: "Você poderá encurtar seus links programaticamente utilizando nossa <a href='/api-doc' target='_blank'>API</a>"},
        previewLink: 'Pré-visualização do link',
        getPlan: `Selecionar`,
        'url.rejected' : 'URL rejeitada',
        updateAddressInfo: 'Altere o código do link encurtado ou o direcionamento',
        assignPlan: `Assinar Plano {plan}`,
        assignPlanAuth: `Para assinar esse plano é preciso realizar o login`,
        saved: 'Salvo',
        default: 'Padrão',
        shootings: 'Disparos',
        payPerYearInfo: 'Economize até 30% ao pagar anualmente',
        payPerMonth: 'Pague por mês',
        month: 'mês',
        year: 'ano',
        montly: 'mensal',
        yearly: 'anual',
        payPerYear: 'Pague por ano',
        totalClicks: 'Total de clicks',
        totalClicksInBioPage: 'clicks nos links da sua página',

        optOut: 'opt-out',
        moreActions: 'Mais ações',
        filter: 'Filtrar',
        messageSendoptInRequired: 'Você paga o equivalente ao envio de 1 SMS para solicitar a permissão.',
        optIn: 'opt-in',
        optInRequired: 'Solicite permissão para envios de SMSs',
        optInRequiredSubtitle: 'Este contato atualmente faz parte da nossa lista de exclusão.',
        optInRequiredText: `Para que possamos enviar mensagens para ele, é necessário obter sua permissão. Você pode iniciar o processo enviando uma solicitação de permissão, e só poderemos enviar novas mensagens depois que o contato aceitar receber atualizações.
        `,


        optInRequiredSend: 'Permissão para envios de SMSs solicitada',
        optInRequiredSendSubtitle: 'Este contato atualmente ainda faz parte da nossa lista de exclusão até que ele aceite receber suas comunicações.',

        optInRequiredSendAccept: 'Permissão para envios de SMSs aceita',
        optInRequiredSendAcceptSubtitle: 'Este contato atualmente faz parte da nossa lista de inclusão.',
        requireOptIn: 'Solicitar opt-in',
        optInAcceptTitle: 'Aceitar receber mensagens do(a) ',
        biopage: 'Página biográfica',
        report: 'Relatório',
        views: 'Visualizações',
        countAllLinks: 'Contagem de acesso para todos os links',
        countLinksTo: 'Contagem para o link ',
        browserDefault: 'Navegador Padrão',

        clicks: 'Clicks',
        appView: 'Outros Apps',
        appViewDesc: 'Visualização dentro de outros aplicativos',

        startAt: 'a partir de',
        endAt: 'até',
        accessByDay: 'Clicks por dia',

        periodWithoutResult: 'Período sem resultado',

        clean: "Limpar",
        plan: "Plano",
        assignPlanCardInfo: "Não mantemos dados do cartão de crédito. Essa informação é utilizada somente na assinatura do plano e depois é excluída.",


        assignPlanSuccess: "Recebemos os dados do pagamento. Aguarde até que a cobrança seja realizada.",
        youAssignPlan: "Você assina o Plano ",
        'og:title' : 'Título',
        'og:type' : 'Tipo',
        'og:image' : 'Imagem',
        'og:url' : 'Endereço online (URL)',
        'og:site_name' : 'Nome do Site',
        'og:description' : 'Descrição',
        'og:video:url' : 'Vídeo (URL)',
        'contact:already:opt:in:or:number:invalid' : 'Contato já optou por receber mensagens ou o número é inválido',
        hostinger_ads: 'Solução completa para hospedar seu site',
        maxFileSizeOG: 'O tamanho da imagem deve ser inferior a 2 MB!',
        apiRefSwagger: 'Referência da API (Swagger)',
        website: 'Website',
        application: 'Aplicativo',
        audio: 'Audio', 
        image: 'Imagem', 
        delete: 'Excluir',
        deleteLink: 'Excluir Link',
        doUpgrade: 'Fazer upgrade',
        toSendInfoproduct: 'Confira seu email',
        message: 'mensagem', 
        multipart: 'Arquivo', 
        text: 'Texto',
        video: 'Vídeo',
        domains: 'Domínio',
        sitesShortened: 'Sites encurtados',
        videoOther: 'Vídeos (Outros)',
        regular: 'Regular',
        irregular: 'Irregular',
        deleted: 'Excluído',
        updateTagsInfo: 'Definir tags para links encurtados pode te ajudar a encontrá-lo na busca com facilidade.',
        toCheck: 'Verificar',
        editLinkTags: 'Editar tags do Link',
        copyYoutubeUrl: 'Use uma url de video do youtube. Exemplo https://www.youtube.com/watch?v=dxVaP0-aFIE ou https://www.youtube.com/embed/dxVaP0-aFIE',
        linksErrorVerify: 'Você tem links encurtados cujo o endereço original podem ter deixado de funcionar',
        thisLinkError: 'Algumas pessoas podem ter dificuldade para acessar esse link. Última verificação em ',
        linksErrorTitle: 'Um ou mais links podem ter deixado de funcionar',
        linkErrorTitle: 'Este link pode ter deixado de funcionar. Verifique as possíveis causas.',
        linkErrorCauses: [
            "O usuário com o link precisa estar logado no site do link original. Obs. LinkedIn, Instagram e outras redes sociais necessitam que esteja logado na plataforma para visualizar certos perfis.",
            "Nem todos os usuários podem ter acesso ao link original.",
            "Link copiado em área logada dentro do site original.",
            "O endereço original pode ter sido alterado.", 
            "Link ou URL não existe mais.",
            "Serviço indisponível.",
        ],
        subdomainAlreadyInUSe: 'Esse suddomínio já está em uso',
        xs: 'Smartphone',
        sm: 'Tablet',
        md: 'Notebook',
        lg: 'Computador',
        xl: 'Televisor',
        
        possibleCauses: 'Possíveis causas:',
        next_billing_at: "Próxima cobrança em",
        start_at: "Iniciou em",
        interval: "Frequência",
        current_cycle_start_at: "Ciclo de cobrança de ",
        current_cycle_billing_at: "Pagamento em ",
        current_cycle_status: "Situação",
        current_cycle_cycle: "Ciclo",
        clearFilter: 'Limpar filtro',
        shortenAddressTitle: `encurte seus links`,
        shortenAddressMoreTitle: `seu migly pode ser + que 1 encurtador de links`,
        shortenAddressBenefits: [
            {icon: 'md-'}
        ],
        shortenAddressBenefit1: `URL personalizável`,
        shortenAddressBenefit2: 'contagem de clicks',
        shortenAddressBenefit3: 'personalização do link para redes sociais',
        shortenAddressBenefit4: 'integração com google analytics',
        billed: 'Faturado',
        unbilled: 'Não faturado',
        active: 'Ativo',
        cancel: 'Cancelar',
        canceled: 'Cancelado',
        updateAddress: 'Alterar link',
        failed: 'Falhou',
        future: 'Teste',
        start_in: 'Inicia em',
        metrics: 'Métricas',
        cancelSubscrible: 'Cancelar assinatura',
        confirmCancelSubscrible: 'Confirmar',
        toConfirm: 'Confirmar',
        shorten: 'encurtar',
        status: 'Status',
        originalAddress: 'Endereço original',
        shortenAddressInfo: 'obtenha uma solução essencial para gerar, compartilhar e rastrear links para todas as formas de comunicação.',
        titleCancel: 'Cancelamento de assinatura',
        textCancel: 'Ao cancelar sua assinatura nós não renovamos no mês subsequente. Os benefícios associados ao tipo de plano estarão disponíveis até o final do ciclo.',
        
        SAVED: 'Salvo',
        segments: 'Segmentos',
        name: 'Nome',
        age: 'Idade',
        properties: 'Propriedades',
        OPT_IN_REQUIRED: 'Opt-In Solicitado',
        OPT_IN_SENDED: 'Opt-In Enviado',
        OPT_IN_REQUIRED_ERROR: 'Opt-In com falha',
        OPT_IN: 'Opt-In (Aceitaram suas comunicações)',
        OPT_OUT: 'Opt-Out (Recusaram suas comunicações)',
        displayToWindowSizeTitle: 'Telas',
        textOptOut: '. Click migly.in/o/xXx p/ sair.',
        displayToWindowSizeDescrition: 'Acesso por dispositivos com base no tamanho da tela',
        optOutTitle: 'Não deseja mais receber mensagens do(a) ',
        displays: [
            {id: 'xs', description: 'clicks em telefone pequeno a grande', icon: 'mdi-cellphone', color: '#304FFE'},
            {id: 'sm', description: 'clicks em tablet pequeno a médio', icon: 'mdi-tablet', color: '#3D5AFE'},
            {id: 'md', description: 'clicks em tablet grande para notebook', icon: 'mdi-laptop', color: '#536DFE'},
            {id: 'lg', description: 'clicks em monitor a TVs grandes', icon: 'mdi-monitor', color: '#2962FF'},
            {id: 'xl', description: 'clicks em 4k e ultra-wide', icon: 'mdi-television', color: '#2979FF'}
        ],
        configPageLoadInfo: 'Customize sua página de carregamento',
        'contact:already:opt:out:or:number:invalid' : 'Contato já está na lista de exclusão de comunicação ou número inválido',
        platformTitle: 'Plataforma',
        platformDescription: 'Acesso por plataforma',
        advanced: 'Avançado',
        noData: 'Não encontrado',
        toSendFor: 'Enviar para ',
        SCHEDULED: 'Agendado',
        SENT : 'Enviado',
        ProfitWithUs: 'Lucre conosco',
        PROCESSING: 'Processando',
        processingSmsSend: 'O disparo foi feito, aguarde até enviarmos todas as mensagens.',
        noDataDescription: 'Pesquise por tags, partes da URL ou opções do painel',
        textLimitSms: 'As mensagens tem o limite de 160 caracteres. Alguns mensagens estão superando esse limite. Revise seu template.',
        smsEncodeMessageDescInfo: 'Ao usar palavras acentuadas em suas mensagens o tamanho máximo dela poderá ser reduzido de 160 letras para 70, portanto, tente não utilizar palavras acentuadas.',

        platforms: [
            {id: 'android', color: 'deep-purple accent-4', name: 'Android', icon: 'mdi-android'},
            {id: 'macintosh', color: 'indigo darken-2', name: 'Macintosh (macOS)', icon: 'mdi-apple'},
            {id: 'windows', color: 'blue', name: 'Windows', icon: 'mdi-microsoft-windows'},
            {id: 'iphone', color: 'indigo darken-2', name: 'iPhone (iOS)', icon: 'mdi-apple'},
            {id: 'linux', color: 'primary', name: 'Linux', icon: 'mdi-linux'},
            {id: 'ipad', color: 'indigo darken-2', name: 'iPad (iOS)', icon: 'mdi-apple'},
            {id: 'others', color: 'indigo darken-2', name: 'Outros', icon: 'mdi-crosshairs-question'},



            {id: 'Linux aarch64', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'Win32', color: 'blue', name: '', icon: 'mdi-microsoft-windows'},
            {id: 'Linux armv8l', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'Linux armv7l', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'MacIntel', color: 'indigo darken-2', name: '', icon: 'mdi-apple'},
            {id: 'Linux x86_64', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'Linux i686', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'Linux armv81', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'X11; Linux x86_64', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'Win64', color: 'blue', name: '', icon: 'mdi-microsoft-windows'},
            {id: 'PlayStation 4', color: 'red', name: 'PlayStation 4', icon: 'mdi-sony-playstation'},
            {id: 'Windows Phone', color: 'red', name: 'Windows Phone', icon: 'mdi-microsoft-windows'},
            

        ],
        region: 'Região',

        geoTitle: 'Região',
        geoDescription: 'Acesso por região',

        byCountries: 'Paises',
        byStates: 'Estados',
        byCities: 'Cidades',

        onlyIcon: 'apenas ícone',

        shareOn: 'Compartilhar no',

        shareOptions: [
            {icon: 'mdi-whatsapp', description : 'WhatsApp', url: 'https://api.whatsapp.com/send/?text=', color: 'green', value: 'w'},
            {icon: 'mdi-facebook', description : 'Facebook', url: 'https://www.facebook.com/sharer.php?u=' , color: 'blue', value: 'f'},
            {icon: 'mdi-twitter', description : 'Twitter', url: 'https://twitter.com/intent/tweet?text=', color: 'primary', value: 't'},
            {icon: 'mdi-linkedin', description : 'LinkedIn', url: 'https://www.linkedin.com/sharing/share-offsite/?url=', color: 'blue', value: 'l'},
            {icon: 'mdi-facebook-messenger', description : 'Messenger', url: 'https://www.messenger.com/login.php?next=', color: 'primary', value: 'm'},
            {icon: 'mdi-email', description : 'Email', url: 'mailto:?subject=&body=', color: 'dark', value: 'e'},
        ],

        config : "Configurações",
        others: 'Outros',
        yourCompanyOrProductHere: 'Descontos de até 30% na Shopee! 🐰 ',
        yourCompanyOrProductHereText: `De acessórios a presentes especiais, encontre tudo que precisa!`,
        callToAction: `🛒 Explore agora.`,
        screenLoading: "Tela de Carregamento",
        screenLoadingDescription: "Customize a tela de carregamento definindo a marca e nome da sua empresa ou do seu produto.",
        logoPicture: "Logo ou imagem do site",
        siteName: "Nome do site",
        loaderTime: "Tempo de exibição até redirecionamento será de ",
        resourceOnlyPlan: "Recurso disponível somente no plano",
        previewSCreenLoader: "Pré-visualização",
        previewOpenGraph: "Pré-visualização",
        preview: "Pré-visualização",
        editLink: 'Editar link',
        editAparenceLink: 'Editar pré-visualização do link',
        editAparenceLinkInfo: 'Esse recurso permite exibir uma pequena pré-visualização para mostrar o conteúdo do link. Aplicativos como facebook, whatsapp e telegram tiram proveito desse recurso.',
        editCodeAndDirectionLink: 'Editar código e direcionamento',
        openGraph: "Personalizar uma visualização de link (Open Graph)",
        openGraphDescription: "Open Graph é uma tecnologia que te permite ter controle sobre como sua página web será compartilhada nas redes sociais.",
        openGraphDescriptionInfo: `Quando o usuário do Facebook curte uma URL com o Open Graph implementado, é criado um vínculo entre o usuário e essa URL.

        Através desse vínculo, será possível interagir com os usuários que a curtiram, da mesma forma que ocorrem as interações com uma página do Facebook.
        
        Por exemplo: publicar atualizações ao usuário, direcionar anúncios e aparecer na seção “Atividades e Interesses” no perfil de quem a curtiu.
        
        Páginas com a implementação enriquecem a experiência do usuário em suas ações de curtir e compartilhar informações.
        
        Além de definir como a página web será representada no feed das redes sociais.`,

        googleAnalitcs: 'Google Analytics',
        googleAnalitcsDescription: 'Você pode integrar seus links encurtados e página biográfica ao google analytics. Veja como obter seu id de medição',
        googleAnalitcsId: 'ID DE MEDIÇÃO',
        googleAnalitcsExample: 'UA-XXXXXX-X ou G-XXXXXXX',
        search: 'Procurar',
        upgradeToPlus: 'Upgrade',
        help: 'Ajuda',
        myAccount: 'Minha Conta',
        settings: 'Configurações',
        links: 'Links',
        link: 'Link',
        newLink: 'Adicionar link',
        newLinkWhatsapp: 'Gerar link para whatsapp',
        page: 'página',
        tags: 'Tags',
        sellInfoproducts: 'Venda infoprodutos',
        generateAuthorization: 'Gerar token de acesso',
        authorization : 'Autorização',
        templateSMS: 'Modelo da mensagem (Texto do SMS)',
        clickToCopy: 'Clique para copiar',
        swaggerUi: 'Veja nossa api doc',
        expiredAt: 'Expira em',
        advancedConfiguration: 'Configurações avançadas',
        sms: 'SMS',
        sendSms: 'disparo de SMS',
        sendSmsTextInfo: 'Alcance seu público com facilidade e segurança. Personalize mensagens, agende disparos e não precisa de assinatura. Assinantes Pro ganham R$4 em créditos para testar. Potencialize seu marketing agora!',
        sendSmsBtn: 'Envie Mensagens',
        comingSoon: 'Em breve',
        sendSMSLeedBtn: 'Entre em contato',
        tryPlan: 'teste 7 dias grátis',
        register: 'cadastrar-se',
        analytcsTitle: `desempenho do seu migly`,
        analytcsSubTitle: `acompanhe as estastisticas do seus links`,
        analytcsDescription: `Analytics em encurtadores de links oferecem insights vitais para otimizar estratégias. Medindo cliques, origens e comportamento dos usuários, você maximiza engajamento e ROI. Essencial para tomadas de decisão informadas e sucesso online.`,
        smsCarrosel: [
            {title: 'Saiba quem clicou nos links enviados', icon: 'mdi-link'},
            {title: 'Envie boletos e lembretes de pagamento', icon: 'mdi-cash-sync'},
            {title: 'Divulgue promoções, ofertas e produtos', icon: 'mdi-storefront'},
            {title: 'Compartilhe notícias e informações', icon: 'mdi-share-variant'},
            {title: 'Comunique agendamentos e lembretes', icon: 'mdi-clock-outline'},
            {title: 'Faça pesquisas de mercado', icon: 'mdi-tag-search'},
            {title: 'API de desenvolvedor disponível', icon: 'mdi-api', comingSoon: true}
        ],
        shootingSendMessages: '',
        sendNew: 'Novo disparo',
        buyCreditsAutomatic: 'Comprar créditos automaticamente quando esgotar.',
        alertChangeBusinessName: 'Ao alterar o nome do negócio, serviço ou programa toda sua base de contatos vai automaticamente para o OPT-OUT e só receberão suas mensagens após concordarem em receber atualizações novamente.',
        cashRefund: 'Adicionar créditos',
        simple: 'Simples',
        dynamics: 'Dinâmica',
        dynamicsYourVariables: 'Com as variáveis que você definiu',
        typeOfMessage_simple_desc:'A Mensagem será idêntica para todos os remetentes',
        typeOfMessage_dynamics_desc: 'A Dinâmica apresenta mudança de valor em parte do texto para cada destinatário.',
        typeOfMessage: 'Tipo de mensagem',
        addVariable: 'Adicionar variável',
        addRecipients: 'Adicionar Destinatários',
        addAll: 'Adicionar todos',
        inclusiveAndExclusiveFilter: 'Use o switch a esquerda dos filtros para alternar entre um filtro inclusivo ou exclusivo.',
        inclusiveFilter: 'Contém ao menos uma das opções selecionadas',
        exclusiveFilter: 'Contém todas as opções selecionadas',
        segmentsTypeFilter_false: 'Filtrando por contatos que tenha pelo menos um dos segmentos selecionados',
        segmentsTypeFilter_true: 'Filtrando por contatos que tenha todos segmentos selecionados',
        findNumber: 'Encontrar número',
        required: 'Obrigatório',
        recipients: 'Destinatários',
        recipients_a: 'Destinatário(s)',
        shortenLinks: 'Encurtar links',
        shortenALink: 'Encurtar links',
        toSend: 'Enviar',
        shootingEdit: 'Editar Disparo',
        variableDefinedUnsed: 'Alguma Variável foi definida, mas não é utilizada',
        scheduledShooting: 'Disparo agendado',
        variables: 'Variáveis',
        unusedLinkInMessage: 'Link não utilizado na mensagem',
        campaign: 'Campanha',
        repeatNumber: 'Número repetido',
        exitsRepeatNumber: 'Existem números repetidos em seus destinátarios.',
        excludeRepeatNumber: 'Excluir números repetidos',
        exitsIvalidNumber: 'Existem números inválidos em seus destinátarios.',
        campaignDesc: 'Descreva a sua campanha com um título e uma breve descrição. Essa informação só é visível nesse painel.',
        shooting: 'Disparo de SMS',
        shootingDesc: 'Defina um texto para o SMS, destinatários e quando as mensagens serão enviadas.',
        toScheduledShootingForSend: 'Disparo agendado para ',
        timeZone: 'Fuso horário',
        close: 'Fechar',
        invalidSize: 'Tamanho inválido',
        number: 'Número',
        invalidNumber: 'Número inválido',
        addRecipientByFile: 'Usar CSV',
        problens: 'Problemas',
        loadCsv: 'Carregar CSV',
        FAILURE: 'Falhou no envio',
        accept: 'Aceitar',
        refused: 'Recusar',
        termsOfUse: 'Termos de uso',
        privacyPolicy: 'Política de privacidade',
        sendFailedShooting: 'Sua conta precisa ter créditos os suficiente no momento do disparo das mensagens. Verifique seu saldo.',
        smsTemplateExemples: [
            {title: 'Exemplo de arquivo simples preenchido somente com número do contato', img: '/sms/templates/template-1.png', description: 'obs.: Os campos segmentos e tags não obrigatórios.'},
            {title: 'Exemplo de arquivo preenchido com número, segmentos e tags', img: '/sms/templates/template-2.png', description: 'obs.: Os segmentos e tags são separados por vírgulas.'},
            {title: 'Exemplo de arquivo preenchido com número, segmentos, tags e propriedades do contato', img: '/sms/templates/template-3.png', description: 'obs.: Você pode definir qualquer nome para as respectivas colunas após a coluna Tags, pois essas são colunas referentes as propriedades do contato. nome e idade são somente exemplos, você pode definir quantas propriedades quiser, só tenha em mente que o texto do SMS tem limitação de caracters no caso de usar alguma propriedade na mensagem dinâmica.'}
        ],
        userSignTermsAndPrivacyPolicy: 'Termos de uso e política de privacidade',
        optInAndOptOutInfo: 'Seus contatos cadastrados devem optar por receber suas mensagens.',
        infoSegmentsAndTags: 'Definir Segmentos, tags e propriedades pode te ajudar a agrupar seus contatos para configurar seus disparos de SMS.',
        contacts: 'Contatos',
        downloadFileTemplate: 'Baixar arquivo de template',
        addRecipientByFileDesc: 'Carregue uma lista de contatos a partir de um arquivo CSV. Caso precise, use o modelo abaixo como exemplo.',
        timeZoneDiferneteForThisClient: 'Fuso horário diferente para esse contato',


        youWillBeRedirectedTo: 'Você será redirecionado para',
        cencelRedirected: 'Cancelar direcionamento',
        infoprodutosAndLeeds: 'infoprodutos e leads',
        infoprodutosAndLeedsTextInfo: `Acelere o processo de criação de páginas de coleta de leads, mesmo sem experiência técnica. Utilize modelos otimizados para aumentar suas conversões e expandir sua base de leads. Automatize a geração de leads para impulsionar o marketing. Experimente nosso sistema e transforme visitantes em leads qualificados instantaneamente.`,
        pageType: 'Tipo de Página',
        leedsPage: 'Página para coleta de leads',
        orIn: 'ou em',
        infoproductResourceResponsability: 'Responsabilidade de Uso de PDFs e Imagens',
        infoproductResourceResponsabilityDesc: `<i><p>Ao utilizar PDFs e imagens em nosso sistema, você concorda em assumir total responsabilidade pelo conteúdo e direitos autorais associados a esses arquivos. Certifique-se de possuir os direitos autorais necessários para compartilhá-los e de que seu conteúdo seja adequado e respeite nossos termos de serviço.</p>
        <p>Você é responsável por garantir que nenhum material ilegal, difamatório, obsceno ou ofensivo seja compartilhado. Além disso, certifique-se de não incluir informações confidenciais ou pessoais sem a devida autorização.</p>
        <p>Por favor, esteja ciente de que qualquer violação de direitos autorais, privacidade ou outros direitos legais decorrentes do uso inadequado de PDFs e imagens em nosso sistema será de sua responsabilidade.</p></i>`,
        createYourLeedPage: 'Crie sua página para coleta de leads',
        leedsDescription: 'Uma página de coleta de leads visa capturar informações de visitantes, oferecendo incentivos em troca. Esses dados podem ajudar a converter visitantes em clientes qualificados.',
        title: 'Titulo',
        description: 'Descrição',
        disclosure: 'Divulgação',
        disclosureDescription: 'Aproveite a tela de carregamento para promover seus sites, produtos ou serviços de forma eficaz. Maximize a visibilidade e alcance do seu negócio enquanto seus usuários aguardam o direcionamento!',
        smsInfoLinks: 'Ao incluir uma url no texto da mensagem a ferramenta vai encurta-ló automáticamente e vinculará ao destinatário do SMS, assim você saberá quais clientes acessaram os links',
        leeds: 'Leads',
        imageBanner: 'Banner',
        siteHeader: 'Topo do Site',
        colorSiteHeader: 'Cor do topo do site',
        titleColor: 'Cor do título',
        subtitleColor: 'Cor do subtitulo',
        shortDescriptionColor: 'Cor da breve descrição',
        btnActionColor: 'Cor do botão de ação',
        btnActionTextColor: 'Cor do texto dp botao de ação',
        itensTitleColor: 'Cor do texto to título dos itens',
        itensColor: 'Cor dos itens',
        descriptionBackgroundColor: 'Cor de fundo da descrição',
        descriptionColor: 'Cor da descrição',
        formColor: 'Cor do formulário',
        footerBackgroundColor: 'Cor do fundo do rodapé',
        footerTextColor: 'Cor do texto do rodapé',

        infoproductCreateBy: 'Página criada por',
        infoproductContactTitle: 'Formulário de contato',
        colorInfoproductMore: 'Cor de fundo da descrição',
        novelty: 'Novidade',

        profile: 'Perfil',
        music: 'Música',
        article: 'Artigo',
        game: 'Game',
        siteType: 'Página web',
        siteTypeSubtitle: 'Defina qual o tipo da página você vai criar/editar.',
        infoproductContactEmailInfo: 'O email sempre fará parte do formulário de contato',
        textField: 'Campo de Texto',
        selecField: 'Seletor',
        textFieldDescription: 'Os componentes dos campos de texto são usados ​​para coletar informações fornecidas pelo usuário.',
        selectFieldDescription: 'Os componentes de campos selecionados são usados ​​para coletar informações fornecidas pelo usuário em uma lista de opções.',
        infoproductFormFieldType: 'Tipo',
        infoproductFormFieldName: 'Nome do campo',
        infoproductFormSelectFildValues: 'Opções disponíveis no seletor',
        infoproductFormFieldValue: 'Valor',
        infoproductFormInputFieldType: `Tipo de entrada`,
        colorfooterBackgroundColor: 'Cor de fundo do radapé',
        alphanumeric: 'Alfanumérico',
        numeric: 'Numérico',
        onlineAddress: 'Endereço online',
        textFieldAlphanumericDescription: 'Uma combinação de caracteres alfabéticos e numéricos',
        selectFieldNumericDescription: 'Uma combinação de caracteres numéricos',
        formField: 'Campo do formulário',
        footerTitle: 'Rodapé do site',
        footerSubtitle: 'Inclua informações da sua empresa, contato, links e mais.',
        infoproductContactSubtitle: 'Desenvolva um formulário personalizado para facilitar o contato direto com seus potenciais clientes. Tenha controle total sobre os campos que compõem o formulário, permitindo que você determine as informações específicas que deseja coletar.',
        everyoneThat: 'Todos que',
        allInFilter: 'Correspondam ao seguinte filtro',
        document: 'Documento',
        contact: 'Contato',
        cpfCnpj: 'CPF/CNPJ',
        purchase: 'Comprar',
        newContacts: 'Pendente inclusão',
        maxSize: 'Tamanho máximo do arquivo deve ser de {0} e o arquivo atual tem {1}.',

        creditCardNumber: 'Número do cartão de crédito',
        cvv: 'CVV',
        creditCardNamePrinted: 'Nome (como escrito no cartão)',
        expiration: 'Expiração',
        billingAddress: 'Endereço de cobrança',
        publicStreet: 'Rua/Logradouro',
        complement: 'Complemento',
        cep: 'CEP',
        city: 'Cidade',
        state: 'Estado',
        creditCard: 'Cartão de crédito',
        ddi: 'DDI',
        ddd: 'DDD',
        advance: 'Avançar',
        toGoBack: 'Voltar',
        invalidCard: 'Cartão inválido',
        all: 'Todos',
        totalCostWillBe: 'O custo total será de <b>_</b>',
        totalCost: 'O custo total: ',
        cards: 'Cartões',
        cardSelection: 'Selecione o cartão',
        addNewCard: 'Adcionar um novo cartão',
        'invalid.dateTimeScheduled' : 'Data e hora do agendamento inválido',

        'brazil.number.only' : 'Apenas números brasileiros são permitidos',
        'number.invalid.value': 'Número inválido',
        cleanInvalidContacts: 'Remover inválidos',
        'insufient.credits' : 'Crédito insuficiente',
        example: 'Exemplo',
        metricsAndTimeLine: 'Métricas e linha do tempo',
        'large.sms.text' : 'Algumas mensagens ultrapassam o limite de 160 caracters. Revise seu texto.',
        sendRequireOptInAllSavedInfo: 'Existem _ contatos na lista de exclusão. Para podermos enviar mensagens para eles, é necessário obter permissão. Você pode iniciar o processo enviando uma solicitação de permissão para todos eles, e só poderemos enviar novas mensagens após o contato aceitar receber atualizações. Para solicitar a permissão, é necessário pagar o valor equivalente ao envio de 1 SMS por contato.',
        sendRequireOptInAllSaved: 'Enviar OPT-IN para todos os ',
        last4NumberDigits: 'Informe os 4 últimos dígitos do seu celular',
        smsHelp: 'Dificuldade em utilizar o recurso de envio de SMS em massa? Entre em contato <a class="link" href="emailto:contato@exatamente-solucoes.com">contato@exatamente-solucoes.com</a>',
        confirmOptInMessage: '<p>Você optou por receber as mensagens do(a) _.</p><p>A cada mensagem recebida você pode optar por não receber mais simplesmente clicando no link dos SMSs recebidos.</p>',
        confirmOptOutMessage: '<p>Você optou por não receber as mensagens do(a) _.</p>',
        dynamicMessageAlert: 'O SMS não pode ultrapassar o limite de 160 caracters. Ao definir a mensagem como dinâmica a variável no corpo do texto não pode fazer com que o SMS ultrapasse esse limite.',
        scheduleShootingFor: 'Agendar disparo para',
        contactClickedLinks: 'Contatos clicaram neste link',
        googleChromeWebStoreMiglyTitleXS: 'Encurte links',
        googleChromeWebStoreMiglyTitle: 'melhore sua experiência online com nossa extensão!',
        googleChromeWebStoreMiglySubTitle: 'descubra recursos incríveis e navegue de forma mais eficiente. nossa extensão é rápida, segura e fácil de usar!',
        googleChromeWebStoreMiglyAction: 'instale agora!',
        termsOfUseHtml : {
            '1.0.0' : `
                <h1>TERMOS DE USO</h1>
                <p style="margin-top: 15px"><i><strong>Última atualização: DATEUPDATE</strong></i></p>
                <p>Bem-vindo ao <strong>Migly</strong>. Estes Termos de Uso regem o uso do Sistema e constituem um acordo legalmente vinculativo entre você e a <strong>Exatamente Soluções Educacionais</strong>.</p>
               
               
                <h2><p>1. Aceitação dos Termos</p></h2>
                <p>Ao acessar ou utilizar o Sistema, você concorda com estes Termos e concorda em cumprir todas as leis e regulamentos aplicáveis. Se você não concorda com estes Termos, não utilize o Sistema.</p>
            
                <h2><p>2. Uso do Sistema</p></h2>
                <h3>2.1. Encurtamento de URLs</h3>
                <p>O Sistema permite que você encurte URLs. Você concorda em usar essa funcionalidade de maneira ética e legal, sem disseminar links maliciosos ou conteúdo ilegal.</p>
            
                <h3>2.2. Disparo de SMS em Massa</h3>
                <p>Se Você utilizar a funcionalidade de disparo de SMS em massa, é responsável por cumprir todas as leis de proteção de dados e regulamentos aplicáveis, incluindo obter o consentimento adequado dos destinatários.</p>
            
                <h2><p>3. Conta do Usuário</p></h2>
                <h3>3.1. Criação de Conta</h3>
                <p>Você pode ser solicitado a criar uma conta para acessar determinadas funcionalidades do Sistema. Você é responsável por manter a confidencialidade de suas informações de conta e por todas as atividades que ocorram sob sua conta.</p>
            
                <h3>3.2. Exclusão de Conta</h3>
                <p>Reservamo-nos o direito de encerrar ou suspender sua conta a nosso critério, se suspeitarmos de atividades fraudulentas, ilegais ou violações destes Termos.</p>
            
                <h2><p>4. Privacidade</p></h2>
                <p>Suas informações pessoais estão sujeitas à nossa Política de Privacidade.</p>
            
                <h2><p>5. Propriedade Intelectual</p></h2>
                <h3>5.1. Propriedade</h3>
                <p>A Exatamente Soluções Educacionais detém todos os direitos de propriedade intelectual relacionados ao Sistema.</p>
            
                <h3>5.2. Abstenção</h3>
                <p>Você concorda em não copiar, reproduzir, distribuir ou criar trabalhos derivados com base no Sistema sem permissão expressa da Exatamente Soluções Educacionais.</p>
            
                <h2><p>6. Limitação de Responsabilidade</p></h2>
                <h3>6.1. Garantias</h3>
                <p>O Sistema é fornecido "como está", sem garantias de qualquer tipo, expressas ou implícitas.</p>
            
                <h3>6.2. Responsabilidade</h3>
                <p>A Exatamente Soluções Educacionais não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, especiais, consequentes ou punitivos decorrentes do uso do Sistema.</p>
            
                <h2><p>7. Disposições Gerais</p></h2>
                <h3>7.1. Termos de uso anteriores</h3>
                <p>Estes Termos representam o acordo integral entre Você e a Exatamente Soluções Educacionais e substituem todos os acordos anteriores.</p>
            
                <h3>7.2. Atualizações</h3>
                <p>Estes Termos podem ser alterados pela Exatamente Soluções Educacionais a qualquer momento, mediante aviso prévio.</p>
            
                <h3>7.3. Jurisdição</h3>
                <p>Estes Termos serão regidos pelas leis do Brasil, Guarapuava - PR e qualquer litígio será submetido à jurisdição exclusiva dos tribunais desta jurisdição.</p>
            
                <p>Ao utilizar o Sistema, Você concorda com estes Termos e está ciente de que o não cumprimento deles pode resultar na suspensão ou encerramento de sua conta. Se tiver alguma dúvida ou preocupação, entre em contato conosco em contato@exatamente-solucoes.com.</p>
            `
        },
        privacyPolicyHtml : {
            '1.0.0' : `
                <h1>POLÍTICA DE PRIVACIDADE</h1>
                <p style="margin-top: 15px"><strong>Última atualização: DATEUPDATE</strong></p>
                
                <p>A sua privacidade é importante para nós. Esta Política de Privacidade descreve como a Exatamente Soluções Educacionais coleta, utiliza, compartilha e protege as informações pessoais dos usuários ao utilizar o sistema Migly.</p>
            
                <h2><p>1. Informações Coletadas</p></h2>
                <h3>1.1. Informações de Registro</h3>
                <p>Quando você cria uma conta no Sistema, podemos coletar informações pessoais, como nome, endereço de e-mail e senha.</p>
            
                <h3>1.2. Informações de Uso</h3>
                <p>Podemos coletar informações sobre como você utiliza o Sistema, incluindo os URLs encurtados e as mensagens SMS enviadas.</p>
            
                <h3>1.3. Informações de Pagamento</h3>
                <p>Podemos coletar informações de pagamento, como dados de cartão de crédito, endereço de cobrança e CPF.</p>
            
                <h2><p>2. Uso das Informações</p></h2>
                <h3>2.1. Fornecimento e Melhoria do Serviço</h3>
                <p>Utilizamos suas informações para fornecer e melhorar o Sistema, incluindo o encurtamento de URLs e o disparo de SMS.</p>
            
                <h3>2.2. Comunicações</h3>
                <p>Podemos usar seu endereço de e-mail para enviar informações sobre o Sistema, atualizações e notícias relevantes.</p>
            
                <h2><p>3. Compartilhamento de Informações</p></h2>
                <p>Não compartilhamos suas informações pessoais com terceiros, a menos que seja necessário para fornecer os serviços solicitados por você ou conforme exigido por lei.</p>
            
                <h2><p>4. Segurança das Informações</p></h2>
                <p>Tomamos medidas razoáveis para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição.</p>
            
                <h2><p>5. Acesso e Controle das Suas Informações</p></h2>
                <p>Você pode acessar, corrigir ou excluir suas informações pessoais a qualquer momento, entrando em contato conosco através das informações fornecidas no final desta Política de Privacidade.</p>
            
                <h2><p>6. Cookies e Tecnologias Semelhantes</p></h2>
                <p>O Sistema pode usar cookies e tecnologias semelhantes para melhorar a experiência do usuário. Você pode controlar o uso de cookies nas configurações do seu navegador.</p>
            
                <h2><p>7. Alterações na Política de Privacidade</p></h2>
                <p>Reservamo-nos o direito de atualizar esta Política de Privacidade a qualquer momento. A data da última atualização será indicada no início deste documento.</p>
            
                <h2><p>8. Contato</p></h2>
                <p>Se tiver dúvidas sobre esta Política de Privacidade ou desejar exercer seus direitos de privacidade, entre em contato conosco em:</p>
                <address>
                    <p>AL. ELIO ANTONIO DALLA VECCHIA, 838 Sala 5, 85.040-167, CASCAVEL, GUARAPUAVA - PR
                    <br>
                    contato@exatamente-solucoes.com</p>
                </address>
            `,
        },
        creationDate: 'Criação do link',
        filters: 'Filtros',
        addressType: 'Método de encurtamentio',
        addressTypeDesc: 'É a fonte que gerou o link curto.',

        addressType_ALL: 'Todos os tipos',
        addressType_API: 'API',
        addressType_CHROME_EXTENSION: 'Extensão do Google Chrome',
        addressType_STANDARD: 'Este Painel (site)',
        addressType_BIO: 'Biolink (meu migly)',
        addressType_SMS: 'SMSs',
        addressType_INFOPRODUCT: 'Infoprodutos',
        addressTypeDesc_API: 'Links encurtados pela API de desenvolvedor',
        addressTypeDesc_CHROME_EXTENSION: 'Links encurtados a partir d extensão do Google Chrome',
        addressTypeDesc_STANDARD: 'Links ecurtados por este Painel',
        addressTypeDesc_BIO: 'Links associados ao seu biolink (meu migly)',
        addressTypeDesc_SMS: 'Links encurtados associados ao disparo e agendamento de SMSs',
        addressTypeDesc_INFOPRODUCT: 'Links para seus Infoprodutos',
        orderBy: 'Ordenar',
        dateCreateOrderByASC: 'Mais antigos',
        dateCreateOrderByDESC: 'Mais recentes',
        clicksOrderByASC: 'Menos acessados',
        clicksOrderByDESC: 'Mais acessados',
        share: 'Compartilhar',
        situation: 'Situação',
        situationDesc: 'Todos os dias é feita uma verificação dos endereços dos links encurtados, quando um link falha na verificação a situação passa a ser irregular.',
        clear: 'Limpar',
        apply: 'Aplicar',
        creationDateDesc: 'Datas de quando o link foi gerado. Você pode limitar por datas início e fim.',
        results: 'resultados',
        notVerified: 'Não verificado',
        createYoutMiglyPage: `Crie sua página migly`,
        
    },
    'en': {
        projectDescription : 'Shorten links and urls for free with migly.in',
        shortenToURL: 'Shorten to URL',
        
        shortenAddress: `Shorten address`,
        urlCopiedToClipboard: 'url copied to clipboard',
        copyUrl: 'Copy link',
        viewUrl: 'Visit link',
        toClean: 'To Clean',
        invalidUrl : `Invalid URL`,
        login: `Login`,
        signUp: `Sign Up`,
        anchorLink1: '',
        all: 'Todos',

        plansAndPrices: `Plans and Prices`,
        plansAndPricesDescription: 'Get an essential solution to generate, share and track links for all forms of communication. Choose your plan below.',
        plan_FREE: 'Free',
        plan_BASIC: 'Basic',
        plan_PRO: 'Pro',
        
        
    },
};

const i18n = new VueI18n({
    locale: 'pt-br', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;