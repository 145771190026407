<template>
  <div v-if="data.infoproduct">
    <v-dialog v-model="data.dialog" width="1000" persistent :fullscreen="['xs', 'sm'].includes($vuetify.breakpoint.name)">
      <v-card class="card-corner-radius" color="grey lighten-3">
        <v-card-title class="primary--text pl-4">
          <v-btn icon color="primary" @click="JSON.stringify(data.infoproduct) == JSON.stringify(aux) ? data.dialog = false : confimCancel.dialog = true" :disabled="loadingSave">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <b v-if="data.infoproduct.id">{{$t(`editInfoProduct`)}}</b>
          <b v-else>{{$t(`createInfoProduct`)}}</b>
          <v-spacer/>
          <v-btn v-if="data.infoproduct.id" text color="primary" @click="confimDelete.dialog = true" :loading="loadingSave">
            <v-icon>mdi-delete</v-icon>
            <span>{{$t(`delete`)}}</span>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="7" class="full-height">
              <v-expansion-panels v-model="stage" @change="changePanel" :readonly="!panels[0].valid">
                <v-expansion-panel content-class="pa-0 ma-0 px-0 mx-0" v-for="(panel, index) in panels" :key="index">
                  <v-expansion-panel-header disable-icon-rotate dark>
                    <v-card-title class="primary--text pa-0">
                      {{ $t(panel.title) }}
                      <v-spacer/>
                      
                    </v-card-title>
                    <template v-slot:actions>
                      <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData" v-if="panel.premium && screenData.auth && screenData.auth.user.subscriptionPlan.id < 3"/>
                      <v-icon color="error" v-else-if="!panel.valid">
                        mdi-alert-circle
                      </v-icon>
                      <v-icon v-else color="primary">
                        mdi-check-circle
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card-subtitle class="primary--text mt-0 pl-0 pr-0"><b>{{ $t(panel.subtitle) }}</b></v-card-subtitle>
                    <v-form v-model="panel.valid" :ref="`form_${index}`" :disabled="loadingSave">
                      <div v-if="index == 0">
                        <v-card-text class="pl-0 pr-0">
                          <v-select
                            :items="[
                              {value: 'LEEDS', name: $t('leeds'), icon: 'mdi-account-multiple-plus-outline', description: $t('leedsDescription'), disabled: false},
                              ]"
                            rounded
                            :label="$t('pageType')"
                            outlined
                            hide-details
                            hide-actions
                            dense
                            value="LEEDS"
                            item-text="name"
                            item-value="value"
                            item-disabled="disabled"
                            color="primary"
                            :rules="[
                              v => !!v || $t('required')
                            ]"
                            disabled
                          >
                            <template v-slot:selection="{item}">
                              <v-icon class="mr-2" color="primary">
                                {{ item.icon }}
                              </v-icon>
                              <span>{{ item.name }}</span>
                            </template >
                            <template v-slot:item="{item, on, attrs}">
                              <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-icon>
                                  <v-icon :color="item.disabled ? 'grey' : 'primary'">
                                    {{ item.icon }}
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title :class="item.disabled ? 'grey--text' : 'primary--text'">
                                    {{ item.name }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle :class="item.disabled ? 'grey--text' : 'primary--text'">
                                    <i>{{ item.description }}</i>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-card-text>
                        <v-card-text class="pl-0 pr-0">
                          <v-card elevation="0" outlined>
                            <v-card-title class="primary--text">
                              {{ $t('leedsPage') }}
                            </v-card-title>
                            <v-card-text class="primary--text">
                              {{ $t('leedsDescription') }}
                            </v-card-text>
                          </v-card>
                          <v-card elevation="0" outlined class="mt-4">
                            <v-card-title class="primary--text">
                              {{ $t('infoproductResourceResponsability') }}
                            </v-card-title>
                            <v-card-text v-html="$t('infoproductResourceResponsabilityDesc')"/>
                            <v-card-actions class="ml-4">
                              <v-switch
                                v-model="panel.valid"
                                :disabled="!!data.infoproduct.id"
                                :input-value="!!data.infoproduct.id"
                                :rules="[
                                  v => !!v || ''
                                ]"
                                inset
                                :label="$t('accept')"
                                ></v-switch>
                                <v-spacer/>
                            </v-card-actions>
                          </v-card>
                        </v-card-text>
                      </div>
                      <div v-if="index == 1">
                        <v-card-text class="pl-0 pr-0">
                          <v-select
                            v-model="data.infoproduct.type"
                            :items="[
                              {value: 'E_BOOK', name: $t('eBook'), icon: 'mdi-book-open-page-variant', description: $t('eBookSendDescription'), disabled: false},
                              {value: 'LINK', name: $t('link'), icon: 'mdi-link-circle', description: $t('linkSendDescription'), disabled: true}
                              ]"
                            rounded
                            :label="$t('infoproductType')"
                            outlined
                            hide-details
                            hide-actions
                            dense
                            item-text="name"
                            item-value="value"
                            item-disabled="disabled"
                            color="primary"
                            :rules="[
                              v => !!v || $t('required')
                            ]"
                          >
                            <template v-slot:selection="{item}">
                              <v-icon class="mr-2" color="primary">
                                {{ item.icon }}
                              </v-icon>
                              <span>{{ item.name }}</span>
                            </template >
                            <template v-slot:item="{item, on, attrs}">
                              <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-icon>
                                  <v-icon :color="item.disabled ? 'grey' : 'primary'">
                                    {{ item.icon }}
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title :class="item.disabled ? 'grey--text' : 'primary--text'">
                                    {{ item.name }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle :class="item.disabled ? 'grey--text' : 'primary--text'">
                                    <i>{{ item.description }}</i>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-card-text>
                        <v-scale-transition>
                          <div v-if="data.infoproduct.type == 'E_BOOK'">
                            <v-card-actions class="pl-0 pr-0 mb-0 primary--text" >
                              {{ $t('file') }}
                              <v-spacer/>
                              <v-text-field :value="data.infoproduct.resource" v-show="false"  :rules="[v => !!v || $t('required')]"/>
                              <v-text-field v-if="data.infoproduct.resource" :value="data.infoproduct.resource.src" v-show="false"  :rules="[v => !!v || $t('required')]"/>
                            </v-card-actions>
                            <v-card elevation="0" outlined height="200" class="mb-4">
                              <v-card-actions class="pl-4 mb-0 pb-0 primary--text" style="position: absolute; width: 100%;">
                                <v-spacer/>
                                <v-btn fab x-small color="primary" @click="$refs.fileEbook[0].$refs.input.click()">
                                  <v-icon color="white">mdi-file-upload</v-icon>
                                  <v-file-input
                                    id="fileEbook"
                                    v-show="false"
                                    x-small
                                    ref="fileEbook"
                                    accept="application/pdf"
                                    dark
                                    color="primary"
                                    hide-input
                                    hide-details
                                    hide-actions
                                    prepend-icon="mdi-file-upload"
                                    icon
                                    density="density"
                                    rounded
                                    flat
                                    dense
                                    @change="handleEbook($event)"
                                    clearable
                                  />
                                </v-btn>
                                <v-btn class="ml-2" fab x-small color="primary" v-if="data.infoproduct.resource && data.infoproduct.resource.src && !data.infoproduct.resource.deleted" @click="data.infoproduct.resource.id ? data.infoproduct.resource.deleted = true : data.infoproduct.resource = {id: null, src: null}">
                                  <v-icon color="white">mdi-delete</v-icon>
                                </v-btn>
                              </v-card-actions>
                              <embed v-if="data.infoproduct.resource && data.infoproduct.resource.src && !data.infoproduct.resource.deleted" width="100%" height="200" type="application/pdf" :src="`${data.infoproduct.resource.src}`"/>
                            </v-card>
                          </div>
                        </v-scale-transition>
                      </div>
                      <div v-else-if="index == 2">
                        <v-text-field 
                          v-model="data.infoproduct.onlineAddress.code"
                          outlined
                          rounded
                          persistent-hint
                          dense
                          color="primary"
                          class="text-end-input"
                          :hint="`${$t('yourPageAvailibleIn')}${protocol}//${data.infoproduct.onlineAddress.code}.${host} ${$t('orIn')} ${protocol}//${host}/${data.infoproduct.onlineAddress.code}`"
                          :rules="[
                              () => canUseSubdomain || $t('subdomainAlreadyInUSe'),
                              v => !!v || $t('required'),
                              v => !!v && !v.match(/[^a-zA-Z-]/g)  || $t('invalid'),
                            ]"
                          required
                          @keyup="verifySubDomain"
                          >
  
                          <template v-slot:prepend-inner>
                            <v-icon class="mr-1" color="primary">
                              mdi-link
                            </v-icon>
                            <span class="mt-1">
                              <!-- {{protocol}}// -->
                            </span>
                          </template>
                          <template v-slot:append>
                            <span class="mt-1">
                              {{ host }}
                            </span>
                          </template>
                        </v-text-field>
                      </div>
                      <div v-else-if="index == 3">
                        <v-card-subtitle class="primary--text pl-0 pr-0">{{ $t('colorSiteHeader') }}</v-card-subtitle>
                        <v-card-text class="mt-0  pl-0 pr-0">
                          <v-card elevation="0" outlined :color="data.infoproduct.style.headerColor">
                            <v-card-actions>
                              <v-spacer/>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`headerColor`" :title="$t('colorSiteHeader')" :fab="true"/>
                              <v-spacer/>
                            </v-card-actions>
                          </v-card>
                        </v-card-text>
                        <v-card-actions class="pl-4 mb-0 pb-0 primary--text  pl-0 pr-0">
                          {{ $t('logoOrlogotipo') }}
                          <v-spacer/>
                          <v-file-input
                            v-show="false"
                            x-small
                            class="ml-3"
                            ref="fileInputBrandImage"
                            accept="image/*"
                            dark
                            color="primary"
                            hide-input
                            hide-details
                            hide-actions
                            prepend-icon="mdi-file-upload"
                            icon
                            density="density"
                            rounded
                            flat
                            dense
                            @change="handleCrop($event, cropImageLogoData)"
                            />
                        </v-card-actions>
                        <v-card-text class="pt-0  pl-0 pr-0">
                          <v-card elevation="0" outlined>
                            <v-img :src="data.infoproduct.brandImage && !data.infoproduct.brandImage.deleted ? data.infoproduct.brandImage.src : ''" height="100">
                              <v-card-actions>
                                <v-spacer/>
                                <v-btn fab color="primary" @click="$refs.fileInputBrandImage[0].$refs.input.click()" x-small>
                                  <v-icon color="white">mdi-upload-circle</v-icon>
                                </v-btn>
                                <v-spacer/>
                                <v-btn v-if="data.infoproduct.brandImage && !data.infoproduct.brandImage.deleted && data.infoproduct.brandImage.src" fab color="primary" @click="data.infoproduct.brandImage.id ? data.infoproduct.brandImage.deleted = true : data.infoproduct.brandImage = null" x-small>
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-card-actions>
                            </v-img>
                          </v-card>
                          <v-card-actions class="mx-0 ma-0 px-0 pa-0">
                            <v-spacer/>
                            <small><i>{{ $t('logoOrlogotipoSizeInfo') }}</i></small>
                          </v-card-actions>
                        </v-card-text>
                        <v-card-text class="pl-0 pr-0">
                          <v-text-field 
                            v-model="data.infoproduct.title"
                            rounded
                            :label="$t('title')"
                            outlined
                            hide-actions
                            dense
                            :rules="[
                              v => !!v || $t('required'),
                              v => !!v && v.length <= 80 || $t('invalidSize'),
                            ]"
                            color="primary"
                            counter
                            maxlength="80"
                            >
                            <template v-slot:prepend-inner>
                              <v-icon class="mr-1" color="primary">
                                mdi-format-title
                              </v-icon>
                            </template>
                            <template v-slot:append>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`titleColor`" :title="$t('titleColor')" :fab="false" :icon="true"/>
                            </template>
                          </v-text-field>
                        </v-card-text>
                        <v-card-text  class="pl-0 pr-0">
                          <v-text-field 
                            v-model="data.infoproduct.subtitle"
                            rounded
                            :label="$t('subtitle')"
                            outlined
                            hide-actions
                            dense
                            :rules="[
                              v => !!v || $t('required'),
                              v => !!v && v.length <= 50 || $t('invalidSize'),
                            ]"
                            color="primary"
                            counter
                            maxlength="50"
                            >
                            <template v-slot:prepend-inner>
                              <v-icon class="mr-1" color="primary" small>
                                mdi-format-title
                              </v-icon>
                            </template>
                            <template v-slot:append>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`subtitleColor`" :title="$t('subtitleColor')" :fab="false" :icon="true"/>
                            </template>
                          </v-text-field>
                        </v-card-text>
                        <v-card-text  class="pl-0 pr-0">
                          <v-textarea
                            v-model="data.infoproduct.shortDescription"
                            rounded
                            :label="$t('shortDescription')"
                            outlined
                            hide-actions
                            dense
                            :rules="[
                              v => !!v || $t('required'),
                              v => !!v && v.length <= 300 || $t('invalidSize'),
                            ]"
                            color="primary"
                            counter
                            maxlength="300"
                            rows="3"
                            no-resize
                            >
                            <template v-slot:prepend-inner>
                              <v-icon class="mr-1" color="primary">
                                mdi-text-short
                              </v-icon>
                            </template>
                            <template v-slot:append>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`shortDescriptionColor`" :title="$t('shortDescriptionColor')" :fab="false" :icon="true"/>
                            </template>
                          </v-textarea>
                        </v-card-text>
                        <v-card-text class="pl-0 pr-0">
                          <v-select
                            v-model="data.infoproduct.action"
                            :items="[
                              {value: 'DOWNLOAD', name: $t('downloadNow'), icon: 'mdi-download-circle'},
                              {value: 'ACCESS', name: $t('accessNow'), icon: 'mdi-eye-circle-outline'},
                            ]"
                            rounded
                            :label="$t('actionName')"
                            outlined
                            hide-details
                            hide-actions
                            dense
                            item-text="name"
                            item-value="value"
                            color="primary"
                          >
                            <template v-slot:selection="{item}">
                              <v-icon class="mr-2" color="primary">
                                {{ item.icon }}
                              </v-icon>
                              <span>{{ item.name }}</span>
                            </template >
                            <template v-slot:item="{item, on, attrs}">
                              <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-icon>
                                  <v-icon color="primary">
                                    {{ item.icon }}
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title class="primary--text">
                                    {{ item.name }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle class="primary--text">
                                    <i>{{ item.description }}</i>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            <template v-slot:append>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`btnActionColor`" :title="$t('btnActionColor')" :fab="false" :icon="true"/>
                              <v-spacer class="mr-1"/>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`btnActionTextColor`" :title="$t('btnActionTextColor')" :fab="false" :icon="true"/>
                            </template>
                          </v-select>
                        </v-card-text>
                        <v-card-actions class="pl-4 mb-0 pb-0 primary--text pl-0 pr-0">
                          {{ $t('productImage') }}
                          <v-spacer/>
                          <v-file-input
                            v-show="false"
                            x-small
                            class="ml-3"
                            ref="fileInputProductImage"
                            accept="image/*"
                            dark
                            color="primary"
                            hide-input
                            hide-details
                            hide-actions
                            prepend-icon="mdi-file-upload"
                            icon
                            density="density"
                            rounded
                            flat
                            dense
                            @change="handleCrop($event, cropImageSiteData)"
                          />
                        </v-card-actions>
                        <v-card-text class="pt-0 pl-0 pr-0">
                          <v-card elevation="0" outlined>
                            <v-img :src="data.infoproduct.productImage && !data.infoproduct.productImage.deleted ? data.infoproduct.productImage.src : ''" max-height="100">
                              <v-card-actions>
                                <v-spacer/>
                                <v-btn fab color="primary" @click="$refs.fileInputProductImage[0].$refs.input.click()" x-small>
                                  <v-icon color="white">mdi-file-upload</v-icon>
                                </v-btn>
                                <v-spacer/>
                                <v-btn fab x-small v-if="data.infoproduct.productImage && data.infoproduct.productImage.src && !data.infoproduct.productImage.deleted" color="primary" @click="data.infoproduct.productImage.id ? data.infoproduct.productImage.deleted = true : data.infoproduct.productImage = null">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-card-actions>
                            </v-img>
                          </v-card>
                        </v-card-text>
                      </div>
                      <div v-else-if="index == 4">
                        <v-card-text class="pl-0 pr-0">
                          <v-text-field 
                            v-model="data.infoproduct.itensTitle"
                            rounded
                            :label="$t('topicsAndThemesTitle')"
                            outlined
                            hide-actions
                            dense
                            :rules="[
                              v => !!v || $t('required'),
                              v => !!v && v.length <= 200 || $t('invalidSize'),
                            ]"
                            color="primary"
                            counter
                            maxlength="200"
                            >
                            <template v-slot:prepend-inner>
                              <v-icon class="mr-1" color="primary">
                                mdi-format-list-group
                              </v-icon>
                            </template>
                            <template v-slot:append>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`itensTitleColor`" :title="$t('itensTitleColor')" :fab="false" :icon="true"/>
                              <v-spacer class="mr-1"/>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`itensColor`" :title="$t('itensColor')" :fab="false" :icon="true"/>
                            </template>
                          </v-text-field>
                          <v-text-field 
                            v-show="false"
                            :value="data.infoproduct.itens.filter(item => !item.deleted).length"
                            :rules="[
                              v => !!v || $t('required'),
                              v => !!v && v > 0 || $t('invalidSize'),
                            ]"
                            >
                          </v-text-field>
                        </v-card-text>
                        <v-card-text class="mb-0 pb-0 pl-0 pr-0" v-if="data.infoproduct.itens">
                          <div v-for="(item, index) in data.infoproduct.itens.filter(item => !item.deleted)" :key="index">
                            <v-text-field 
                              v-model="item.value"
                              rounded
                              :label="`${index + 1}°  ${$t('topicAndThemesTitle')}`"
                              outlined
                              hide-actions
                              dense
                              :rules="[
                                v => !!v || $t('required'),
                                v => !!v && v.length <= 100 || $t('invalidSize'),
                              ]"
                              color="primary"
                              counter
                              maxlength="100"
                              >
                              <template v-slot:prepend-inner>
                                <v-icon class="mr-1" color="primary">
                                  mdi-check
                                </v-icon>
                              </template>
                              <template v-slot:append>
                                <v-btn
                                  x-small
                                  color="primary"
                                  icon
                                  rounded
                                  @click="item.id ? item.deleted = true : data.infoproduct.itens = data.infoproduct.itens.filter(v => v != item)"
                                >
                                  <v-icon color="primary">mdi-delete</v-icon>
                                </v-btn>
                              </template>
                            </v-text-field>
                          </div>
                        </v-card-text>
                        <v-card-actions class="mt-0 pt-0 pl-0 pr-0">
                          <v-btn block outlined rounded small color="primary" @click="data.infoproduct.itens && data.infoproduct.itens.length ? data.infoproduct.itens.push({}) : data.infoproduct.itens = [{}, {}, {}, {}]">
                            <v-icon color="primary">mdi-plus</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </div>
                      <div v-else-if="index == 5">
                        <v-card-subtitle class="primary--text pl-0 pr-0">{{ $t('colorInfoproductMore') }}</v-card-subtitle>
                        <v-card-text class="mt-0 pl-0 pr-0">
                          <v-card elevation="0" outlined :color="data.infoproduct.style.descriptionBackgroundColor">
                            <v-card-actions>
                              <v-spacer/>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`descriptionBackgroundColor`" :title="$t('descriptionBackgroundColor')" :fab="true" :icon="false"/>
                              <v-spacer/>
                            </v-card-actions>
                          </v-card>
                        </v-card-text>
                        <v-card-text class="pl-0 pr-0">
                          <v-textarea
                            v-model="data.infoproduct.description"
                            rounded
                            :label="$t('description')"
                            outlined
                            hide-actions
                            dense
                            :rules="[
                              v => !!v || $t('required'),
                              v => !!v && v.length <= 1500 || $t('invalidSize'),
                            ]"
                            color="primary"
                            counter
                            maxlength="1500"
                            >
                            <template v-slot:prepend-inner>
                              <v-icon class="mr-1" color="primary">
                                mdi-text-box
                              </v-icon>
                            </template>
                            <template v-slot:append>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`descriptionColor`" :title="$t('descriptionColor')" :fab="false" :icon="true"/>
                            </template>
                          </v-textarea>
                        </v-card-text>
                      </div>
                      <div v-else-if="index == 6">
                        <v-card-text class="pl-0 pr-0">
                          <v-alert type="info" icon="mdi-at">
                            {{$t('infoproductContactEmailInfo')}}
                          </v-alert>
                        </v-card-text>
                        <v-card elevation="0" :color="`${screenData.auth && screenData.auth.user.subscriptionPlan.id < 3 ? 'grey lighten-2' : 'white'}`">
                          <v-card-subtitle v-if="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3">
                            <v-icon>mdi-crown-outline</v-icon>
                            {{$t('resourceOnlyPlan')}} <b>{{$t('plan_PRO')}}</b>            
                          </v-card-subtitle>
                          <div v-show="(screenData.auth && screenData.auth.user.subscriptionPlan.id >= 3)">  
                            <v-card-actions class="mt-0 pr-0 pl-0">
                              <v-spacer/>
                              <ColorMenu :data="data.infoproduct.style" :attribute="`formColor`" :title="$t('formColor')" :fab="true" :icon="false"/>
                              <v-spacer/>
                            </v-card-actions> 
                            <div v-if="data.infoproduct.formFields">
                              <v-card-text v-for="(field, index) in data.infoproduct.formFields.filter(field => !field.deleted)" :key="index" class="mb-1 pb-0 pr-0 pl-0">
                                <v-card outlined elevation="0">
                                  <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                      color="primary"
                                      icon
                                      rounded
                                      @click="field.id ? field.deleted = true : data.infoproduct.formFields = data.infoproduct.formFields.filter(v => v != field); $forceUpdate()"
                                    >
                                      <v-icon color="primary">mdi-delete</v-icon>
                                    </v-btn>
                                  </v-card-actions>
                                  <v-card-subtitle class="primary--text">
                                    {{index + 1}}° {{ $t('formField') }}
                                    <v-spacer/>
                                  </v-card-subtitle>
                                  <v-card-text class="mb-0 pb-0">
                                    <v-text-field 
                                      v-model="field.name"
                                      rounded
                                      :label="$t('infoproductFormFieldName')"
                                      outlined
                                      hide-actions
                                      dense
                                      :rules="[
                                        v => !!v || $t('required'),
                                        v => !!v && v.length <= 50 || $t('invalidSize'),
                                      ]"
                                      color="primary"
                                      counter
                                      maxlength="50"
                                      >
                                      <template v-slot:prepend-inner>
                                        <v-icon class="mr-1" color="primary">
                                          mdi-label
                                        </v-icon>
                                      </template>
                                      <template v-slot:append>
              
                                      </template>
                                    </v-text-field>
                                  </v-card-text>
                                  <v-card-text>
                                    <v-select
                                      v-model="field.type"
                                      :items="[
                                        {value: 'TEXT_FIELD', name: $t('textField'), icon: 'mdi-form-textbox', description: $t('textFieldDescription')},
                                        {value: 'SELECT_FIELD', name: $t('selecField'), icon: 'mdi-form-select', description: $t('selectFieldDescription')}
                                        ]"
                                      rounded
                                      :label="$t('infoproductFormFieldType')"
                                      outlined
                                      hide-details
                                      hide-actions
                                      dense
                                      item-text="name"
                                      item-value="value"
                                      color="primary"
                                      @change="field.type == 'SELECT_FIELD' && (!field.options || !field.options.length) ? field.options.push({}) : '';$forceUpdate()"
                                    >
                                      <template v-slot:selection="{item}">
                                        <v-icon class="mr-2" color="primary">
                                          {{ item.icon }}
                                        </v-icon>
                                        <span>{{ item.name }}</span>
                                      </template >
                                      <template v-slot:item="{item, on, attrs}">
                                        <v-list-item v-bind="attrs" v-on="on">
                                          <v-list-item-icon>
                                            <v-icon color="primary">
                                              {{ item.icon }}
                                            </v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title class="primary--text">
                                              {{ item.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="primary--text">
                                              <i>{{ item.description }}</i>
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </template>
                                    </v-select>
                                  </v-card-text>
                                  <div v-if="'SELECT_FIELD' == field.type">
                                    <v-card-actions class="primary--text ml-2 pb-0 mb-0">
                                      {{ $t('infoproductFormSelectFildValues') }}
                                      <v-spacer/>
                                    </v-card-actions>
                                    <v-card-text class="mb-0 pb-0">
                                      <v-chip-group column>
                                        <v-text-field 
                                          class="mb-2"
                                          v-for="(option, index) in field.options.filter(option => !option.deleted)" :key="index"
                                          v-model="option.value"
                                          rounded
                                          :label="(index + 1) + `° ${$t('infoproductFormFieldValue')}`"
                                          outlined
                                          hide-actions
                                          hide-details
                                          dense
                                          flat
                                          :rules="[
                                            v => !!v || $t('required'),
                                            v => !!v && v.length <= 50 || $t('invalidSize'),
                                          ]"
                                          color="primary"
                                          counter
                                          maxlength="50"
                                          >
                                          <template v-slot:prepend-inner>
                                            <v-icon class="mr-1" color="primary">
                                              mdi-label
                                            </v-icon>
                                          </template>
                                          <template v-slot:append>
                                            <v-btn
                                              x-small
                                              color="primary"
                                              icon
                                              rounded
                                              @click="option.id ? option.deleted = true : field.options = field.options.filter(v => v != option)"
                                            >
                                              <v-icon color="primary">mdi-delete</v-icon>
                                            </v-btn>
                                          </template>
                                        </v-text-field>
                                        <v-btn block rounded color="primary" icon @click="field.options.push({id: null, deleted: false, value: ''});$forceUpdate()" :disabled="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3">
                                          <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                      </v-chip-group>
                                    </v-card-text>
                                  </div>
                                  <div v-else-if="'TEXT_FIELD' == field.type">
                                    <v-card-text>
                                      <v-select
                                        v-model="field.inputType"
                                        :items="[
                                          {value: 'ALPHANUMERIC', name: $t('alphanumeric'), icon: 'mdi-alpha-a-box', description: $t('textFieldAlphanumericDescription')},
                                          {value: 'NUMERIC', name: $t('numeric'), icon: 'mdi-numeric-1-box', description: $t('selectFieldNumericDescription')}
                                          ]"
                                        rounded
                                        :label="$t('infoproductFormInputFieldType')"
                                        outlined
                                        hide-details
                                        hide-actions
                                        dense
                                        item-text="name"
                                        item-value="value"
                                        color="primary"
                                        @change="$forceUpdate()"
                                      >
                                        <template v-slot:selection="{item}">
                                          <v-icon class="mr-2" color="primary">
                                            {{ item.icon }}
                                          </v-icon>
                                          <span>{{ item.name }}</span>
                                        </template >
                                        <template v-slot:item="{item, on, attrs}">
                                          <v-list-item v-bind="attrs" v-on="on">
                                            <v-list-item-icon>
                                              <v-icon color="primary">
                                                {{ item.icon }}
                                              </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title class="primary--text">
                                                {{ item.name }}
                                              </v-list-item-title>
                                              <v-list-item-subtitle class="primary--text">
                                                <i>{{ item.description }}</i>
                                              </v-list-item-subtitle>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </template>
                                      </v-select>
                                    </v-card-text>
                                  </div>
                                  <v-card-text>
                                    <v-switch
                                        v-model="field.required"
                                        inset
                                        :label="$t('required')"
                                        @change="$forceUpdate()"
                                      />
                                  </v-card-text>
                                </v-card>
                              </v-card-text>
                            </div>                 
                            <v-card-text class="mt-0 pr-0 pl-0">
                              <v-btn rounded block outlined color="primary" small icon @click="data.infoproduct.formFields ? data.infoproduct.formFields.push({options: []}) : (data.infoproduct.formFields = [ { 'options': [], 'name': '', 'inputType': null, 'type': null, 'required': false } ]); $forceUpdate()" :disabled="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-card-text>
                          </div>
                        </v-card>
                      </div>
                      <div v-else-if="index == 7">
                        <v-card elevation="0" :color="`${screenData.auth && screenData.auth.user.subscriptionPlan.id < 3 ? 'grey lighten-2' : 'white'}`">
                          <v-card-subtitle v-if="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3">
                            <v-icon>mdi-crown-outline</v-icon>
                            {{$t('resourceOnlyPlan')}} <b>{{$t('plan_PRO')}}</b>            
                          </v-card-subtitle>
                          <div v-else-if="data.infoproduct.footer">
                            <v-card-subtitle class="primary--text pr-0 pl-0">{{ $t('colorfooterBackgroundColor') }}</v-card-subtitle>
                            <v-card-text class="mt-0 pr-0 pl-0">
                              <v-card elevation="0" outlined :color="data.infoproduct.style.footerBackgroundColor">
                                <v-card-actions>
                                  <v-spacer/>
                                  <ColorMenu :data="data.infoproduct.style" :attribute="`footerBackgroundColor`" :title="$t('footerBackgroundColor')" :fab="true" :icon="false"/>
                                  <v-spacer/>
                                </v-card-actions>
                              </v-card>
                            </v-card-text>
                            <v-card-actions class="pr-0 pl-0 mb-0 pb-0 primary--text">
                              {{ $t('logoOrlogotipo') }}
                              <v-spacer/>
                              <v-file-input
                                v-show="false"
                                x-small
                                class="ml-3"
                                ref="footerfileInputBrandImage"
                                accept="image/*"
                                dark
                                color="primary"
                                hide-input
                                hide-details
                                hide-actions
                                prepend-icon="mdi-file-upload"
                                icon
                                density="density"
                                rounded
                                flat
                                dense
                                @change="handleCrop($event, cropImageFooterLogoData)"
                                />
                            </v-card-actions>
                            <v-card-text class="pt-0 pr-0 pl-0">
                              <v-card elevation="0" outlined>
                                <v-img :src="data.infoproduct.footer && data.infoproduct.footer.brandImage && !data.infoproduct.footer.brandImage.deleted ? data.infoproduct.footer.brandImage.src : ''" height="100">
                                  <v-card-actions>
                                    <v-spacer/>
                                    <v-btn fab color="primary" @click="$refs.footerfileInputBrandImage[0].$refs.input.click()" x-small>
                                      <v-icon color="white">mdi-upload-circle</v-icon>
                                    </v-btn>
                                    <v-spacer/>
                                    <v-btn v-if="data.infoproduct.footer && data.infoproduct.footer.brandImage && !data.infoproduct.footer.brandImage.deleted && data.infoproduct.footer.brandImage.src" fab color="primary" @click="data.infoproduct.footer.brandImage.id ? data.infoproduct.footer.brandImage.deleted = true : data.infoproduct.footer.brandImage = null" x-small>
                                      <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                  </v-card-actions>
                                </v-img>
                              </v-card>
                            </v-card-text>
                            <v-card-text class="pr-0 pl-0">
                              <v-textarea
                                v-model="data.infoproduct.footer.text"
                                rounded
                                :label="$t('text')"
                                outlined
                                hide-actions
                                dense
                                :rules="[
                                  v => !!v || $t('required'),
                                  v => !!v && v.length <= 800 || $t('invalidSize'),
                                ]"
                                color="primary"
                                counter
                                maxlength="800"
                                rows="3"
                                no-resize
                                >
                                <template v-slot:prepend-inner>
                                  <v-icon class="mr-1" color="primary">
                                    mdi-text-short
                                  </v-icon>
                                </template>
                                <template v-slot:append>
                                  <ColorMenu :data="data.infoproduct.style" :attribute="`footerTextColor`" :title="$t('footerTextColor')" :fab="false" :icon="true"/>
                                </template>
                              </v-textarea>
                            </v-card-text>
                            <v-card-subtitle class="primary--text pr-0 pl-0">{{ $t('links') }}</v-card-subtitle>
                            <div v-if="data.infoproduct.footer && data.infoproduct.footer.links">
                              <v-card-text v-for="(link, index) in data.infoproduct.footer.links.filter(link => !link.deleted)" :key="index" class="mb-1 pb-0 pr-0 pl-0">
                                <v-card-actions>
                                  <v-spacer/>
                                  <v-btn
                                    color="primary"
                                    icon
                                    rounded
                                    @click="link.id ? link.deleted = true : data.infoproduct.footer.links = data.infoproduct.footer.links.filter(v => v != link)"
                                  >
                                    <v-icon color="primary">mdi-delete</v-icon>
                                  </v-btn>
                                </v-card-actions>
                                <v-card outlined elevation="0">
                                  <v-card-subtitle class="primary--text">
                                    {{index + 1}}° {{ $t('link') }}
                                    <v-spacer/>
                                  </v-card-subtitle>
                                  <v-card-actions class="mb-0 pb-0">
                                    <v-text-field 
                                      v-model="link.name"
                                      rounded
                                      :label="$t('name')"
                                      outlined
                                      hide-actions
                                      dense
                                      :rules="[
                                        v => !!v || $t('required'),
                                      ]"
                                      maxlength="50"
                                      color="primary"
                                      >
                                      <template v-slot:prepend-inner>
                                        <v-icon class="mr-1" color="primary">
                                          mdi-alpha-l-circle
                                        </v-icon>
                                      </template>
                                      <template v-slot:append>
              
                                      </template>
                                    </v-text-field>
                                    <v-text-field 
                                      class="ml-2"
                                      v-model="link.href"
                                      rounded
                                      :label="$t('onlineAddress')"
                                      outlined
                                      hide-actions
                                      dense
                                      :rules="[
                                        v => !!v || $t('required'),
                                        v => isUrl(v) || $t('invalidUrl')
                                      ]"
                                      color="primary"
                                      >
                                      <template v-slot:prepend-inner>
                                        <v-icon class="mr-1" color="primary">
                                          mdi-link-circle
                                        </v-icon>
                                      </template>
                                      <template v-slot:append>
                                      </template>
                                    </v-text-field>
                                  </v-card-actions>
                                </v-card>
                              </v-card-text>
                            </div>
                            <v-card-text class="mt-0 white pr-0 pl-0">
                              <v-btn small outlined color="primary" rounded block @click="data.infoproduct.footer.links ? data.infoproduct.footer.links.push({options: []}) : data.infoproduct.footer.links = []" :disabled="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                              <v-spacer/>
                            </v-card-text>
                          </div>
                        </v-card>
                      </div>
                    </v-form>
                    <v-card-actions class="pl-0 pr-0">
                      <v-spacer/>
                      <v-btn color="primary" @click="stage--" text small v-if="index > 0">
                        {{ $t(`toGoBack`) }}
                      </v-btn>
                      <v-btn color="primary" @click="stage++" rounded small :disabled="!panel.valid" v-if="index < panels.length -1" :loading="loadingVerifySubdomain">
                        {{ $t(`advance`) }}
                        <v-icon small class="ml-1">mdi-check-circle-outline</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-card-actions class="mr-0">
                <v-spacer/>
                <v-btn rounded color="primary" :disabled="!!panels.filter(v => !v.valid).length || loadingVerifySubdomain" :loading="loadingSave" @click="save">
                  {{ $t('save') }}
                </v-btn>
              </v-card-actions>
            </v-col>
            <v-col cols="12" sm="12" md="5" class="full-height" v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)">
              <v-card elevation="0" class="card-corner-radius">
                <v-card-title class="primary--text">
                  {{ $t('preview') }}
                  <v-spacer/>
                  <v-menu offset-y left :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        outlined
                        rounded
                        class="ml-1"
                      >
                        <v-icon color="primary">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>                        
                        <v-card-title class="primary--text">
                          {{ $t('Veja como seu site fica em outras telas') }}
                          <v-spacer/>
                        </v-card-title>
                      </v-list-item>
                      <v-list-item v-for="item in [
                        {id: 'sm', name: 'tablet', icon: 'mdi-tablet', view : tabletView},
                        {id: 'md', name: 'laptop', icon: 'mdi-laptop', view : laptopView},
                        {id: 'lg', name: 'desktop', icon: 'mdi-monitor', view : monitorView},
                        {id: 'xl', name: '4k', icon: 'mdi-television', view : televisionView},
                        ]" :key="item.id" @click="item.view.dialog = true">
                        <v-list-item-icon>
                          <v-icon color="primary">
                            {{ item.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-content>
                          <v-list-item-title>{{ $t(item.id) }}</v-list-item-title>
                        </v-list-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
                <v-card-text>
                  <v-img src="/mockup-iphone-02.svg" class="mobile-preview">
                    <div class="mobile-preview-container">
                      <InfoproductView :infoproduct="data.infoproduct" :edit="false" :view="'xs'"/>
                    </div>
                  </v-img>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmOperation :data="confimCancel" :confimAction="() => {data.dialog = false, Object.assign(data.infoproduct, aux)}" />
    <ConfirmOperation :data="confimDelete" :confimAction="deleteConfirm" />
    <CropImage :data="cropImageLogoData" :actionSave="setLogoHeader" :stencilSize="{width: 150, height: 40}"/>
    <CropImage :data="cropImageSiteData" :actionSave="setImageSite" :stencilSize="{width: 900, height: 500}"/>
    <CropImage :data="cropImageFooterLogoData" :actionSave="setLogoFooter" :stencilSize="{width: 180, height: 48}"/>

    <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="view.dialog" :width="view.width" v-for="view in [tabletView, laptopView, monitorView, televisionView]" :key="view.id">
      <v-card>
        <v-card-title class="primary--text">
          <v-btn icon color="primary" @click="view.dialog = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <b>{{$t(view.id)}}</b>
        </v-card-title>
        <v-card-text>
          <InfoproductView :infoproduct="data.infoproduct" :edit="false" :view="view.id"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ConfirmOperation from './ConfirmOperation.vue';
import CropImage from './CropImage.vue';
import InfoproductView from './InfoproductView.vue';
import IconInfoSubscriblePlan from './IconInfoSubscriblePlan.vue'
import SubscriptionAndPlans from './SubscriptionAndPlans.vue';
import { apiV1Infoproduct } from '../../../service/resources/api-v1-infoproduct';
import { apiV1OnlineAddresses } from '../../../service/resources/api-v1-online-addresses';
import ColorMenu from './ColorMenu.vue';
export default {
    name: 'InfoproductEdit',
    props: ['data', 'screenData', 'actionPosSave', 'actionPosDelete'],
    components: {
    ConfirmOperation,
    InfoproductView,
    CropImage,
    IconInfoSubscriblePlan,
    SubscriptionAndPlans,
    ColorMenu
},
    data: () => ({
      panels: [
        {title: 'siteType', subtitle: 'siteTypeSubtitle', valid: true, section: ''},
        {title: 'product', subtitle: 'productSubtitle', valid: true, section: ''},
        {title: 'onlineAddress', subtitle: 'editLinkSubdomainSubtitle', valid: true, section: ''},
        {title: 'pageAparence', subtitle: 'pageAparenceSubtitle', valid: true, section: 'header-section'},
        {title: 'topicsAndThemes', subtitle: 'topicsAndThemesSubtitle', valid: true, section: 'itens-section'},
        {title: 'infoproductMoreTitle', subtitle: 'infoproductMoreSubtitle', valid: true, section: 'more-section'},
        {title: 'infoproductContactTitle', subtitle: 'infoproductContactSubtitle', valid: true, premium: true, section: 'form-section'},
        {title: 'footerTitle', subtitle: 'footerSubtitle', valid: true, premium: true, section: 'footer-section'},
      ],
      cropImageLogoData: {dialog: false, img: null},
      cropImageSiteData: {dialog: false, img: null},
      tabletView: {dialog: false, width: 600, id: 'sm', name: 'tablet'},
      laptopView: {dialog: false, width: 960, id: 'md', name: 'laptop'},
      monitorView: {dialog: false, width: 1264, id: 'lg', name: 'desktop'},
      televisionView: {dialog: false, width: 1904, id: 'xl', name: '4K'},
      cropImageFooterLogoData: {dialog: false, img: null},
      confimCancel: {dialog: false},
      confimDelete: {dialog: false},
      protocol: '',
      host: null,
      rules: null,
      aux: null,
      planData: {
        dialogPlansAndPrices: false
      },
      fileName: '',
      dialogConfig: '',
      loadingSave: false,
      stage: 0,
      canUseSubdomain: true,
      loadingVerifySubdomain: false,
      requestNumberVerifySubdomain: 0
    }),
    watch: {
      'data.dialog': function(value) {
        if(value) {
          this.aux = JSON.parse(JSON.stringify(this.data.infoproduct))
          this.panels.forEach((panel) => {
            if(this.data.infoproduct.id){
              this.stage = -1
              panel.valid = true
            }else {
              this.stage = 0
              panel.valid = false
            }
          })
          // this.panels.forEach((panel, index) => {
          //   setTimeout(() => {
          //     this.stage = this.panels.lenght - index
          //     this.changePanel()
          //   }, 1 * (index + 1));
          // })
          // setTimeout(() => {
          //   if(this.data.infoproduct.id)
          //     this.stage = 2
          //   else
          //     this.stage = 0
          //   this.changePanel()
          // }, this.panels.length * 1.1);
        }
      },
      'stage' : function() {
        this.changePanel()
      },
      'data.infoproduct' : function() {
        this.changePanel()
      },
      'data.infoproduct.footer' : function() {
        this.changePanel()
      }
    },
    mounted: function() {
      this.protocol = `${window.location.protocol}`
      this.host = `${window.location.host.replace('www.', '')}`;
      this.rules = {
        url: value => {
          return (this.isUrl(value)) || this.$t('invalidUrl')
        }
      }
    },
    computed: {
    },
    methods: {
      setLogoHeader: function(image) {
        if(!this.data.infoproduct.brandImage)
          this.data.infoproduct.brandImage = {id: null, src: image.src}
        else
          this.data.infoproduct.brandImage.src = image.src
        this.data.infoproduct.brandImage.deleted = false
      },
      setLogoFooter: function(image) {
        if(!this.data.infoproduct.footer.brandImage)
          this.data.infoproduct.footer.brandImage = {id: null, src: image.src}
        else
          this.data.infoproduct.footer.brandImage.src = image.src
        this.data.infoproduct.footer.brandImage.deleted = false
      },
      setImageSite: function(image) {
        if(!this.data.infoproduct.productImage)
          this.data.infoproduct.productImage = {id: null, src: image.src}
        else
          this.data.infoproduct.productImage.src = image.src
        this.data.infoproduct.productImage.deleted = false
      },
      handleEbook: function(event) {
        var reader = new FileReader()
        reader.readAsDataURL(event)
        let self = this
        console.log(event)
        console.log(reader)
        if(event.size > 5100000) {
          self.$commons.updateSnackbar({show: true, color: 'error', text: self.$t('maxSize').replace('{0}', self.formatBytes(5100000)).replace('{1}', self.formatBytes(event.size))})
          return
        }
        reader.onload = function () {
          if(!self.data.infoproduct.resource){
            self.data.infoproduct.resource = {src: reader.result}
          } else {
            self.data.infoproduct.resource.src = reader.result
          }
          self.data.infoproduct.resource.deleted = false
        }
      },
      formatBytes: function(a,b=2){if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KiB","MiB","GiB","TiB","PiB","EiB","ZiB","YiB"][d]}`},
      handleCrop: function(event, data){
        var reader = new FileReader()
        reader.readAsDataURL(event)
        if(reader.size > 2000000) {
          this.$commons.updateSnackbar({show: true, color: 'error', text: this.$t('invalid')})
          return
        }
        reader.onload = function () {
          data.img = {src: reader.result}
          data.dialog = true
        }
      },
      deleteConfirm: function(){
        this.loadingSave = true
        apiV1Infoproduct.delete(this.data.infoproduct.id).then(() => {
          this.data.dialog = false
          this.confimDelete.dialog = false
          if(this.actionPosDelete)
            this.actionPosDelete(this.data.infoproduct)
        }).finally(() => {
          this.loadingSave = false
        })
      },
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        // const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        return pattern.test(value)
      },
      save: function() {
        this.loadingSave = true
        apiV1Infoproduct.save(this.data.infoproduct).then(result => {
          console.log(result)
          this.data.infoproduct = result.data
          this.data.dialog = false
          if(this.actionPosSave)
            this.actionPosSave(result.data)
        }).finally(() => {
          this.loadingSave = false
        })
      },
      verifySubDomain: function() {
        this.requestNumberVerifySubdomain++
        let r = this.requestNumberVerifySubdomain
        this.loadingVerifySubdomain = true
        setTimeout(() => {
          if(r == this.requestNumberVerifySubdomain && this.data.infoproduct.onlineAddress.code)
            apiV1OnlineAddresses.get(this.data.infoproduct.onlineAddress.code).then(response => {
              if(r == this.requestNumberVerifySubdomain) {
                if(response.data) {
                  this.canUseSubdomain = response.data.id == this.data.infoproduct.onlineAddress.id
                } else {
                  this.canUseSubdomain = true
                }
                this.$refs[`form_1`][0].validate()
              }
            }).finally(()=>{
              if(r == this.requestNumberVerifySubdomain)
                this.loadingVerifySubdomain = false
            })
            else 
              this.loadingVerifySubdomain = false
        }, 2000);
      },
      changePanel: function() {
        if(this.screenData.auth && this.screenData.auth.user.subscriptionPlan.id >= 3 && !this.data.infoproduct.footer) {
          this.data.infoproduct.footer = {
              brandImage: null,
              text: ``,
              links: []
            }
        }
        setTimeout(() => {
          this.$refs[`form_${this.stage}`][0].validate()
          document.getElementById(`${this.panels[this.stage].section}`).scrollIntoView({behavior: "smooth"});
        }, 300)
      }
    },
  }
</script>
<style scoped>
    .text-end-input >>> input {
      text-align: end !important
    }
</style>