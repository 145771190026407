import http from "../axios";

let apiV1OpenGraph = {
	baseUrl: '/api/v1/open-graphs',
	post: async (dto) => {
		return await http.post(apiV1OpenGraph.baseUrl, dto);
	},
	patch: async (dto) => {
		return await http.patch(apiV1OpenGraph.baseUrl, dto);
	},
	delete: async (id) => {
		return await http.delete(`${apiV1OpenGraph.baseUrl}/${id}`);
	},
	get: async (id) => {
		return await http.get(`${apiV1OpenGraph.baseUrl}/${id}`);
	},
	page: async (filter) => {
		return await http.get(`${apiV1OpenGraph.baseUrl}?page=${filter.page}&size=${filter.size}${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}${filter.text ? `&text=${filter.text}` : ''}${filter.type ? `&type=${filter.type}` : ''}${filter.embedBiography ? `&embedBiography=${filter.embedBiography}` : ''}`);
	}
}

export  { apiV1OpenGraph }