<template>
  <div v-if="data.infoproduct">
    <v-card-title class="blue--text mr-0 mt-4">
      <v-icon color="blue" class="mr-2">mdi-chart-bar</v-icon>
      <b>{{$t('leeds')}}</b>
      <v-spacer/>
      <v-btn color="primary" fab x-small @click="search(0)" :loading="loading" class="mr-1">
        <v-icon>mdi-update</v-icon>
      </v-btn>
      <v-btn color="primary" fab x-small @click="saveCsv" :loading="loadingCsv" :disabled="page.empty">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="loadingCsv">
      <v-card  elevation="0" outlined class="blue--text">
        <v-card-actions>
          <v-spacer/>
          {{ progressCsv }} %
          <v-spacer/>
        </v-card-actions>
        <v-progress-linear  indeterminate height="2"/>
      </v-card>
    </v-card-text>
    <v-card-text v-if="loading">
      <v-skeleton-loader type="card"/>
    </v-card-text>
    <div v-else>
      <v-card-text>
        <v-card elevation="0" outlined>
          <v-card-actions>
            <v-card elevation="0" max-width="60" class="mr-2">
              <v-img :src="data.infoproduct.brandImage && data.infoproduct.brandImage.src ?  data.infoproduct.brandImage.src : '/images/logo/logo-02.png'" contain max-height="60"/>
            </v-card>
            <p class="mt-4">
              {{data.infoproduct.title}}
            </p>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <v-card-actions class="ml-2 mr-2">
        <v-btn disabled rounded x-small>
          {{page.totalElements}} {{$t('leeds')}} 
        </v-btn>
        <v-spacer/>
        <v-chip-group show-arrows>
          <v-btn class="ml-1 primary--text" x-small :disabled="(p -1) == page.number" color="white" rounded light v-for="p in page.totalPages" :key="p" @click="search(p -1)">
            <v-icon x-small v-if="(p -1) == page.number">mdi-circle-medium</v-icon>
            {{$t('page')}} {{p}}
          </v-btn>
        </v-chip-group>
      </v-card-actions>
      <v-card-actions v-if="!page.totalElements">
        <v-spacer/>
        <v-chip disabled>
          {{ $t('periodWithoutResult') }}
        </v-chip>
        <v-spacer/>
      </v-card-actions>
      <v-card-text v-else>
        <v-card elevation="0" outlined v-for="(item) in page.content" :key="item.id + '-infoproduct-awnser'" class="mb-2">
          <v-card-subtitle>
            {{ item.email }}
          </v-card-subtitle>
          <v-card-actions v-if="item.email.includes('****') && screenData.auth && screenData.auth.user.subscriptionPlan.id < 3">
            <v-spacer/>
            <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
          </v-card-actions>
          <v-card-text v-if="item.answers &&  item.answers.length" class="pt-0 mt-0">
            <v-chip-group column>
              <v-chip v-for="(answer) in item.answers" :key="answer.id + '-awnser'" x-small readonly>
                <span>({{answer.field.name}}) </span>
                <strong class="ml-1">{{ answer.value }}</strong>
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions class="">
        <v-spacer/>
        <v-chip-group show-arrows>
          <v-btn class="ml-1 primary--text" x-small :disabled="(p -1) == page.number" color="white" rounded light v-for="p in page.totalPages" :key="p" @click="search(p -1)">
            <v-icon x-small v-if="(p -1) == page.number">mdi-circle-medium</v-icon>
            {{$t('page')}} {{p}}
          </v-btn>
        </v-chip-group>
      </v-card-actions>
      <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
        <v-card>
          <v-card-actions>
            <v-spacer/>
            <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
          </v-card-actions>
          <v-card-text>
            <SubscriptionAndPlans :screenData="screenData"/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { apiV1InfoproductFormAnswer } from '../../../service/resources/api-v1-infoproduct-form-answer';
import IconInfoSubscriblePlan from './IconInfoSubscriblePlan.vue';
import SubscriptionAndPlans from './SubscriptionAndPlans.vue';
export default {
    name: 'InfoproductAwnsers',
    props: ['data', 'screenData'],
    components: {
      IconInfoSubscriblePlan,
      SubscriptionAndPlans
    },
    data: () => ({
      planData: {
        dialogPlansAndPrices: false
      },
      page: {
        content: [],
        number: 1
      },
      loading: false,
      loadingCsv: false,
      progressCsv: 0,
    }),
    watch: {
      'data.infoproduct' : function() {
        this.search(0)
      }
    },
    mounted: function() {
      if(this.data.infoproduct)
        this.search(0)
    },
    computed: {
    },
    methods: {
      search: function(page) {
        this.loading = true
        apiV1InfoproductFormAnswer.page({page: page, size: 10, orderBy: 'dateCreate:DESC,dateUpdate:DESC', infoproductId: this.data.infoproduct.id}).then(response => {
          this.page = response.data
          this.loading = false
        })
      },
      saveCsv: async function() {
        this.progressCsv = 0
        this.loadingCsv = true
        var source = {content: [], }
        var p = 0
        var sourcePage = null;
        do {
          sourcePage = await apiV1InfoproductFormAnswer.page({page: p, size: 10, orderBy: 'dateCreate:DESC,dateUpdate:DESC', infoproductId: this.data.infoproduct.id})
          p++
          source.content = source.content.concat(sourcePage.data.content)
          this.progressCsv = Math.floor(source.content.length / sourcePage.data.totalElements * 100)
        } while(!sourcePage.data.last)
        this.loadingCsv = false

        let csvHeaders = ['email']
        let csvBody = [[],[]]
        source.content.forEach((item, index) => {
          if(!csvBody[index])
            csvBody[index] = []
          csvBody[index].push(item.email)
          item.answers.forEach(anwser => {
            if(!csvHeaders.includes(anwser.field.name)) {
              csvHeaders.push(anwser.field.name)
            }
            csvBody[index][csvHeaders.indexOf(anwser.field.name)] = anwser.value
          })
        })
        csvBody.unshift(csvHeaders)

        var content = ''
        csvBody.forEach(row => {
          row.forEach((collum, index) => {
            let dataString = collum.concat(';')
            content += index < row.length -1 ? dataString : dataString + '\n'
          })
        })
        let a = document.createElement('a')
        let mimeType = 'application/octet-stream'
        a.href = URL.createObjectURL(new Blob([content], {
          type: mimeType
        }));
        a.setAttribute('download', 'leeds.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
  }
</script>
<style scoped>
</style>