<template>
  <v-card :width="width" :elevation="border">
    <v-card-title></v-card-title>
    <v-card-text class="qrcode-migly">
      <vue-qr :text="`${value}?s=q`" logoSrc="/images/logo/logo-05.png" backgroundDimming="#fff" :margin='0' :callback="load" :logoCornerRadius="10"/>
    </v-card-text>
    <v-card-actions v-if="enableControlls">
      <v-spacer/>
      <a download="migly-qrcode" :href="base64" class="link">
        <v-icon color="primary">mdi-download</v-icon>
      </a>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script>

  export default {
    name: 'QRCodeMigly',
    props: ['value', 'enableControlls', 'width', 'border'],
    components: {
    },
    data: () => ({
      base64: null,
    }),
    mounted: function() {
    },
    computed: {

    },
    methods: {
       load: function (data) {
         this.src = data
      },
    },
  }
</script>
<style scoped>
.qrcode-migly img {
  width: 100% !important;
}
</style>