import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n';
import router from "./router";
import resources from "./service/index"
import VueCarousel from 'vue-carousel';
import VueGoogleCharts from 'vue-google-charts'
import VueQr from 'vue-qr'
import SlimCropper from 'vue-slim-cropper'
import VueGtm from 'vue-gtm';
Vue.use(SlimCropper)
Vue.use(VueQr)
Vue.use(VueGoogleCharts)
Vue.use(resources); 
Vue.use(VueCarousel);
Vue.config.productionTip = false

Vue.prototype.$commons = {
  
}

const expiration = localStorage.getItem("expiration");
if(expiration) {
  if(new Date(expiration) < new Date()) {
    localStorage.clear()
  }
}

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});
new Vue({
  i18n,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
