<template>
  <v-menu color="blue" offset-y left v-if="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3" open-on-hover>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="blue" v-bind="attrs" v-on="on" dark @click="planData.dialogPlansAndPrices = true" small>
        <v-icon class="mr-2" color="white">mdi-crown</v-icon>
        {{ $t('doUpgrade') }}
      </v-btn>
    </template>
    <v-card light color="blue lighten-4" elevation="0">
      <v-card-text>
        {{$t('resourceOnlyPlan')}} {{planName}}
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer/>
        <v-btn @click="planData.dialogPlansAndPrices = true" color="primary" dark>
          {{$t('plansAndPrices')}}
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-menu>
</template>
<script>
  export default {
    name: 'IconInfoSubscriblePlan',
    props: ['screenData', 'planName', 'planData'],
    components: {
    },
    data: () => ({

    }),
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
  }
</script>
<style scoped>
</style>