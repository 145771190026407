<template>
  <div>
    <div>
      <h3 class="display-title-3 header-title primary--text"><b>{{$t('plansAndPrices').toLowerCase()}}</b></h3>
    </div>
    <div class="subtitle-deafault mt-5">
      {{$t('payPerYearInfo')}}
    </div>
    <v-card elevation="0" :width="['xs'].includes($vuetify.breakpoint.name) ? '100%' : ['sm'].includes($vuetify.breakpoint.name) ? '80%' : ['md'].includes($vuetify.breakpoint.name) ? '30vw' : '20vw'">
        <v-tabs 
          class="mt-5" 
          hide-slider 
          background-color="#0079F1" 
          v-model="subscribleYear" 
          style="border-radius: 25px"
          grow
          >
          <v-tab v-for="item in [{value: 0, description: $t('montly')}, {value: 1, description: $t('yearly')}]" 
            :key="item.value" 
            :tab-value="item.value" 
            :class="(item.value == subscribleYear ? '': 'color-green-blue') + ` ma-1 text-lowercase`" 
            active-class="tab-item-subscrible-year">
            {{ item.description }}
          </v-tab>
        </v-tabs>
    </v-card>
    <div class="mt-4">
      <v-row v-if="loading">
        <v-col v-for="i in 3" :key="`plan_${i}`">
          <v-skeleton-loader
            type="list-item-three-line, card-heading"
          />
        </v-col>
      </v-row>
      <v-card v-else elevation="0">
        <carousel :autoplay="false" :perPage="['xs'].includes($vuetify.breakpoint.name) ? 1 : ['sm'].includes($vuetify.breakpoint.name) ? 2 : 3" :minSwipeDistance="10">
          <slide v-for="(plan) in (['xs'].includes($vuetify.breakpoint.name) ? plans.content.slice().reverse() : plans.content)" :key="plan.id" >
            <v-card elevation="0" outlined width="95%">
              <v-card-title class="mb-0 pb-0">
                <div class="color-green-blue">
                  {{$t(`plan`)}}
                </div>
                <v-spacer/>
                <v-icon color="primary" v-if="plan.id > 1">mdi-crown-outline</v-icon>
              </v-card-title>
              <v-card-title class="primary--text mt-0 pt-0 ml-5">
                <b>{{$t(`plan_${plan.name}`)}}</b>
              </v-card-title>
              <v-card-title class="primary--text">
                <v-spacer/>
                <v-slide-y-transition>
                  <div v-if="discount && !subscribleYear && plan.id != 1">
                    <v-card-title class="pa-0 ma-0"><v-spacer/><small>de R${{ formatPrice(plan.price) }} por</small><v-spacer/></v-card-title>
                    <v-card-title class="pa-0"><v-spacer/>R$<big><b>{{ formatPrice(plan.price - (plan.id == 2 ? 2 : 5)).split(`,`)[0] }}</b> </big>,<small>{{formatPrice(plan.price - (plan.id == 2 ? 2 : 5)).split(`,`)[1]}}</small>/{{$t('month')}}<v-spacer/></v-card-title>
                  </div>
                  <div v-else>
                    <span v-if="subscribleYear">
                      R$<big ><b>{{ formatPrice(plan.annualPrice).split(`,`)[0] }}</b> </big>,<small>{{formatPrice(plan.annualPrice).split(`,`)[1]}}</small>/{{$t('year')}}
                    </span>
                    <span v-else>
                      R$<big><b>{{ formatPrice(plan.price).split(`,`)[0] }}</b> </big>,<small>{{formatPrice(plan.price).split(`,`)[1]}}</small>/{{$t('month')}}
                    </span>
                  </div>
                </v-slide-y-transition>
                <v-spacer/>
              </v-card-title>
              <v-card-text class="text-center">
                <v-card v-if="plan.id == 1 && screenData && screenData.auth" small dark color="grey" class="card-corner-radius pa-3" disabled>
                  {{$t(`getPlan`)}}
                </v-card>
                <a :href="login" v-else-if="plan.id == 1">
                  <v-card  small dark color="primary" class="card-corner-radius pa-3" >
                    {{$t(`register`)}}
                  </v-card>
                </a>
                <v-card v-else small dark color="primary" @click="getPlan(plan)" class="card-corner-radius pa-3">
                  {{$t(`tryPlan`)}}
                </v-card>
              </v-card-text>
              <v-list>
                <div v-if=" discount && !subscribleYear && plan.id == 3 && false">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="primary--text">
                      <b>+R$8,00 de crédito para envio de SMS</b>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="primary--text">
                      <b>+Divulgação da sua link para bio em nosso site (somente para os 100 primeiros assinantes)</b>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <v-list-item v-for="(planInclude) in plan.subscriptionPlanIncludes" :key="`include-${planInclude.id}`">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{$t(`${planInclude.name}`)}}
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="grey lighten-1"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          icon
                        >
                        <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <v-card max-width="400" elevation="0" color="transparent" dark>
                        <v-icon class="mr-2">mdi-information-outline</v-icon>
                        <span v-html="$t(`desc_${planInclude.name}`).description"></span>
                      </v-card>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </slide>
        </carousel>


        <v-card-text v-if="false">
          <v-row>
            <v-col cols="6" xs="6" sm="6"/>
            <v-col cols="2" xs="2" sm="2" v-for="(plan, index) in plans.content" :key="`plan-${plan.id}`" :class="`grey lighten-${4 - index}`">
              <center><h4 class="plan-name-display">{{$t(`plan_${plan.name}`)}}</h4></center>
            </v-col>
          </v-row>
          <v-row>
            <v-expansion-panels tile flat>
              <v-expansion-panel v-for="(planInclude, planIncludeIndex) in planIncludes.content" :key="`include-${planInclude.id}`" >
                <v-expansion-panel-header class="pr-3 pl-3" expand-icon="" v-slot="{ open }">
                  <v-row>
                    <v-col cols="6" class="font-weight-bold dark--text">
                      <v-row>
                        <v-col>
                          {{$t(`${planInclude.name}`)}} 
                          <!-- <v-chip outlined color="blue" small v-if="planInclude.name == 'SEND_SMS'">{{ $t('comingSoon') }}</v-chip> -->
                        </v-col>
                        <v-spacer/>
                        <v-icon color="grey" v-if="!open" >mdi-menu-down</v-icon>
                        <v-icon color="grey" v-else >mdi-menu-up</v-icon>
                      </v-row>
                    </v-col>
                    <v-col v-for="(plan, index) in plans.content" :key="`plan-${plan.id}`" cols="2" :class="`grey lighten-${4 - index}`">
                      <center>
                        <v-icon v-if="plan.subscriptionPlanIncludes.filter(item => item.id == planInclude.id).length" color="primary">
                          {{planIncludeIndex > -1 ? `mdi-check-circle` : `mdi-clock-time-eleven-outline`}}
                        </v-icon>
                        <v-icon v-else color="grey">mdi-cancel</v-icon>
                      </center>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-0 pt-4 mr-0 pr-0">
                  <v-slide-x-transition>
                    <center>
                      <v-icon x-large color="primary" v-for="icon in $t(`desc_${planInclude.name}`).icons" :key="icon">{{icon}}</v-icon>
                    </center>
                  </v-slide-x-transition>
                  <center class="text-description-1 text-center"><p class="font-weight-bold" v-html="$t(`desc_${planInclude.name}`).description"></p></center>
                </v-expansion-panel-content>
                <v-divider/>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-row>
            <v-col cols="6" xs="6" sm="6">
            </v-col>
            <v-col cols="2" xs="2" sm="2" v-for="(plan, index) in plans.content" :key="`plan-${plan.id}`" :class="`plan-name-display grey lighten-${4 - index}`">
              <center>
                <v-slide-y-transition>
                  <span v-if="subscribleYear" :class="false ? `text-line-through` : ''">
                    R$<big><b>{{ formatPrice(plan.annualPrice).split(`,`)[0] }}</b> </big>,<small>{{formatPrice(plan.annualPrice).split(`,`)[1]}}</small>/{{$t('year')}}
                  </span>
                  <span v-else :class="false ? `text-line-through` : ''">
                    R$<big><b>{{ formatPrice(plan.price).split(`,`)[0] }}</b> </big>,<small>{{formatPrice(plan.price).split(`,`)[1]}}</small>/{{$t('month')}}
                  </span>
                </v-slide-y-transition>
              </center>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" xs="6" sm="6">
            </v-col>
            <v-col cols="2" xs="2" sm="2" v-for="(plan, index) in plans.content" :key="`plan-${plan.id}`" :class="`plan-name-display grey lighten-${4 - index}`">
              <center>
                <v-card v-if="plan.id == 1 && screenData && screenData.auth" small dark color="grey" class="pt-1 pb-1 pl-1 pr-1" disabled>
                  {{$t(`getPlan`)}}
                </v-card>
                <a :href="login" v-else-if="plan.id == 1">
                  <v-card  small dark color="primary" class="pt-1 pb-1 pl-1 pr-1" >
                    {{$t(`login`)}}
                  </v-card>
                </a>
                <v-card v-else small dark color="primary" @click="getPlan(plan)" class="pt-1 pb-1 pl-1 pr-1">
                  {{$t(`getPlan`)}}
                </v-card>
              </center>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog v-model="dialogLogin" width="400" persistent>
      <v-card v-if="plan">  
        <v-card-title>{{$t(`assignPlan`).replace('{plan}', $t(`plan_${plan.name}`))}}</v-card-title>
        <v-card-text class="mt-0 pt-0 mb-0 pb-0">
          <v-alert type="info">
            {{$t(`assignPlanAuth`)}}  
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <BtnLogin :screenData="screenData"/>
          <v-spacer/>
          <v-btn color="red" text @click="dialogLogin = false">
            Cancelar
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSubscrible" width="500" persistent :fullscreen="['xs'].includes($vuetify.breakpoint.name)">
      <v-card v-if="plan">
        <v-card-actions>
          <v-card color="primary" dark dense>
            <div v-if="discount && !subscribleYear && plan.id != 1" class="pa-2">
              <v-card-title class="pa-0"><v-spacer/>R$<big><b>{{ formatPrice(plan.price - (plan.id == 2 ? 2 : 5)).split(`,`)[0] }}</b> </big>,<small>{{formatPrice(plan.price - (plan.id == 2 ? 2 : 5)).split(`,`)[1]}}</small>/{{$t('month')}}<v-spacer/></v-card-title>
            </div>
            <v-card-text class="mt-1 mb-1 pt-1 pb-1" v-else>
              <span v-if="subscribleYear" :class="false ? `text-line-through` : ''">
                R$<big><b>{{ formatPrice(plan.annualPrice).split(`,`)[0] }}</b> </big>,<small>{{formatPrice(plan.annualPrice).split(`,`)[1]}}/{{$t('year')}}</small>
              </span>
              <span v-else :class="false ? `text-line-through` : ''">
                R$<big><b>{{ formatPrice(plan.price).split(`,`)[0] }}</b> </big>,<small>{{formatPrice(plan.price).split(`,`)[1]}}/{{$t('month')}}</small>
              </span>
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
          <v-btn color="red" icon @click="dialogSubscrible = false, dialogPlansAndPrices = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <div v-if="!success">
          <v-card-text>
            <v-card color="grey lighten-3" elevation="0">
              <v-card-title>
                {{$t(`assignPlan`).replace('{plan}', $t(`plan_${plan.name}`))}}
              </v-card-title>
              <v-card-subtitle>
                {{$t('trialPeriod')}}
              </v-card-subtitle>
            </v-card>
          </v-card-text>
          <v-card-text >
            <v-form @submit.prevent="" v-model="subscriptionValid">
              <v-card-subtitle class="pl-0 pr-0">
                <b>Documento</b> 
                <v-divider/>
              </v-card-subtitle>
              <span>CPF/CNPJ*</span>
              <v-row>
                <v-col cols="12" xs="12" sm="12">
                  <v-text-field
                    required
                    clearable
                    label="000.000.000-00"
                    solo
                    focus
                    v-model="subscription.customer.document"
                    :disabled="loadSubscription"
                    prepend-inner-icon="mdi-card-account-details"
                    @keyup="subscription.customer.document = subscription.customer.document.replace(/[^0-9]+/g,'')"
                    maxlength="16"
                    counter
                    outlined
                    :hide-details="isCpf(subscription.customer.document) || isCnpj(subscription.customer.document)"
                    :rules="[
                      v => !!v || 'Documento obrigatório no formato somente númerico',
                      v => (isCpf(v) || isCnpj(v)) || 'Documento inválido',
                    ]"
                  />
                </v-col>
              </v-row>
              <span>{{$t('name')}}*</span>
              <v-row>
                <v-col cols="12" xs="12" sm="12">
                  <v-text-field
                    required
                    clearable
                    :label="$t('name')"
                    solo
                    focus
                    v-model="subscription.customer.name"
                    :disabled="loadSubscription"
                    prepend-inner-icon="mdi-clipboard-account"
                    maxlength="40"
                    counter
                    outlined
                    hide-details
                    :rules="[
                      v => !!v || $t('required'),
                    ]"
                  />
                </v-col>
              </v-row>
              <v-card-subtitle class="pl-0 pr-0">
                <b>Contato</b> 
                <v-divider/>
              </v-card-subtitle>
              <v-row>
                <v-col cols="3" xs="3" sm="3">
                  <span>DDI*</span>
                  <v-text-field
                    outlined
                    required
                    label="+55"
                    solo
                    focus
                    v-model="subscription.customer.phone.countryCode"
                    :disabled="loadSubscription"
                    @keyup="subscription.customer.phone.countryCode = subscription.customer.phone.countryCode.replace(/[^0-9]+/g,'')"
                    maxlength="5"
                    counter
                    :hide-details="subscription.customer.phone.countryCode"
                    :rules="[
                      v => !!v || 'DDI obrigatório',
                    ]"
                  />
              </v-col>
                <v-col cols="3" xs="3" sm="3">
                  <span>DDD*</span>
                  <v-text-field
                    outlined
                    required
                    label="11"
                    solo
                    focus
                    v-model="subscription.customer.phone.areaCode"
                    :disabled="loadSubscription"
                    @keyup="subscription.customer.phone.areaCode = subscription.customer.phone.areaCode.replace(/[^0-9]+/g,'')"
                    maxlength="5"
                    counter
                    :hide-details="subscription.customer.phone.areaCode"
                    :rules="[
                      v => !!v || 'DDD obrigatório',
                    ]"
                  />
                </v-col>
                <v-col cols="6" xs="6" sm="6">
                  <span>Número*</span>
                  <v-text-field
                    outlined
                    required
                    clearable
                    label="000000000"
                    solo
                    focus
                    v-model="subscription.customer.phone.number"
                    :disabled="loadSubscription"
                    prepend-inner-icon="mdi-cellphone"
                    @keyup="subscription.customer.phone.number = subscription.customer.phone.number.replace(/[^0-9]+/g,'')"
                    maxlength="15"
                    counter
                    :hide-details="subscription.customer.phone.number >= 11"
                    :rules="[
                      v => !!v || 'Número obrigatório',
                      subscription.customer.phone.number >= 13 || 'Número inválido',
                    ]"
                  />
                </v-col>
              </v-row>
              <v-card-subtitle class="pl-0 pr-0">
                <b>Dados do cartão</b> 
                <v-divider/>
              </v-card-subtitle>
              <v-row>
                <v-col cols="7" xs="7" sm="7" md="7">
                  <span>Número do cartão*</span>
                  <v-text-field
                    outlined
                    required
                    clearable
                    label="0000 0000 0000 0000"
                    solo
                    focus
                    v-model="subscription.card.number"
                    :disabled="loadSubscription"
                    prepend-inner-icon="mdi-credit-card"
                    @keyup="subscription.card.number = subscription.card.number.replace(/[^0-9]+/g,'')"
                    maxlength="19"
                    counter
                    :hide-details="cardValidadion.card_number"
                    @blur="verifyCard"
                    :rules="[
                      v => !!v || 'Número do cartão obrigatório',
                      cardValidadion.card_number || 'Número do cartão inválido',
                    ]"
                  />
                </v-col>
                <v-col cols="5" xs="5" sm="5" md="5">
                  <span>CVV*</span>
                  <v-text-field
                    outlined
                    required
                    clearable
                    label="000"
                    solo
                    focus
                    v-model.trim="subscription.card.cvv"
                    :disabled="loadSubscription"
                    prepend-inner-icon="mdi-security"
                    maxlength="3"
                    @keyup="subscription.card.cvv = subscription.card.cvv.replace(/[^0-9]+/g,'')"
                    @blur="verifyCard"
                    counter
                    :hide-details="cardValidadion.card_cvv"
                    :rules="[
                      v => !!v || 'CVV obrigatório',
                      cardValidadion.card_cvv || 'CVV inválido',
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col ols="12" xs="12" sm="7" md="7">
                  <span>Nome (como escrito no cartão)*</span>
                  <v-text-field
                    outlined
                    required
                    label="Nome e Sobrenome"
                    solo
                    focus
                    v-model.trim="subscription.card.holderName"
                    :disabled="loadSubscription"
                    prepend-inner-icon="mdi-account"
                    maxlength="40"
                    clearable
                    :hide-details="cardValidadion.card_holder_name"
                    @keyup="subscription.card.holderName = subscription.card.holderName.toUpperCase()"
                    @blur="verifyCard"
                    :rules="[
                      v => !!v || 'Nome obrigatório',
                      cardValidadion.card_holder_name || 'Nome inválido',
                    ]"
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="5" md="5">
                  <span>Expiração*</span>
                  <v-text-field
                    outlined
                    required
                    label="00/00"
                    solo
                    focus
                    v-model="subscription.card.expiration"
                    :disabled="loadSubscription"
                    prepend-inner-icon="mdi-calendar"
                    mask="##/##"
                    clearable
                    @keyup="subscription.card.expiration = subscription.card.expiration.replace(/[^0-9//]+/g,''); subscription.card.expiration.length == 2 && !subscription.card.expiration.includes('/') ? subscription.card.expiration = `${subscription.card.expiration}/` : ''"
                    maxlength="5"
                    counter
                    @blur="verifyCard()"
                    :hide-details="cardValidadion.card_expiration_date"
                    :rules="[
                      v => !!v || 'Expiração obrigatória',
                      cardValidadion.card_expiration_date || 'Expiração inválida',
                    ]"
                  />
                </v-col>
              </v-row>
              <v-card-actions class="mr-0 pr-0">
                <v-spacer/>
                <v-fade-transition leave-absolute hide-on-leave>
                  <v-chip v-if="loadVerifyCard" color="green" dark>
                    <span class="mr-2">Verificando dados do cartão</span>
                    <v-progress-circular  indeterminate width="1" color="white" size="20"/>
                  </v-chip>
                  <v-chip dark v-else-if="isCardValid() && card_hash" color="green" small :loading="loadSubscription" :disabled="!subscriptionValid">
                    <v-icon small color="white">mdi-check</v-icon>
                  </v-chip>
                  <v-chip dark v-else color="red">
                    <span class="mr-2">Cartão inválido</span>
                    <v-icon small color="white">mdi-alert-circle-outline</v-icon>
                  </v-chip>
                </v-fade-transition>
              </v-card-actions>
              <v-card-subtitle class="pl-0 pr-0">
                Endereço da cobrança
                <v-divider/>
              </v-card-subtitle>
              <v-row>
                <v-col cols="12" xs="12" sm="12">
                  <span>Rua/Logradouro*</span>
                  <v-text-field
                    outlined
                    required
                    clearable
                    label="Rua/Logradouro"
                    solo
                    focus
                    v-model="subscription.card.billingAddress.line1"
                    :disabled="loadSubscription"
                    maxlength="55"
                    :hide-details="!!subscription.card.billingAddress.line1"
                    :rules="[
                      v => !!v || 'Rua/Logradouro obrigatória',
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  Complemento
                  <v-text-field
                    outlined
                    clearable
                    label="Complemento"
                    solo
                    focus
                    v-model.trim="subscription.card.billingAddress.line2"
                    :disabled="loadSubscription"
                    hide-details
                    maxlength="30"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="12">
                  CEP*
                  <v-text-field
                    outlined
                    required
                    label="CEP"
                    solo
                    focus
                    v-model.trim="subscription.card.billingAddress.zipCode"
                    :disabled="loadSubscription"
                    clearable
                    maxlength="9"
                    mask="#####-###"
                    @keyup="subscription.card.billingAddress.zipCode = subscription.card.billingAddress.zipCode.replace(/[^0-9-]+/g,''); subscription.card.billingAddress.zipCode.length == 5 && !subscription.card.billingAddress.zipCode.includes('-') ? subscription.card.billingAddress.zipCode = `${subscription.card.billingAddress.zipCode}-` : ''"
                    counter
                    :hide-details="subscription.card.billingAddress.zipCode.length >= 9"
                    :rules="[
                      v => !!v || 'CEP obrigatório',
                      v => v.length >= 9 || 'CEP inválido',
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="8">
                  Cidade*
                  <v-text-field
                    outlined
                    required
                    label="Cidade"
                    solo
                    focus
                    v-model="subscription.card.billingAddress.city"
                    :disabled="loadSubscription"
                    maxlength="50"
                    clearable
                    :hide-details="!!subscription.card.billingAddress.city"
                    :rules="[
                      v => !!v || 'Cidade obrigatória',
                    ]"
                  />
                </v-col>
                <v-col>
                  Estado*
                  <v-select
                    :items="['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO']"
                    outlined
                    required
                    label="Estado"
                    solo
                    focus
                    v-model="subscription.card.billingAddress.state"
                    :disabled="loadSubscription"
                    maxlength="50"
                    :hide-details="!!subscription.card.billingAddress.state"
                    :rules="[
                      v => !!v || 'Estado obrigatório',
                    ]"
                  />
                </v-col>
              </v-row>

              <v-card-actions class="pr-0 pl-0">
                <v-alert type="error" dense v-if="error">
                  Você já contratou esse plano ou seus dados do pagamento não são válidos. Confira e tente novamente
                </v-alert>
              </v-card-actions>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" @click="subscrible" rounded :loading="loadSubscription" :disabled="!subscriptionValid">
              Assinar Plano
            </v-btn>
          </v-card-actions>
          <v-card-text v-if="discount && !subscribleYear && plan.id != 1">
            <v-alert dense small color="#78FFFF">
              <v-card-actions style="color: #0C9B93;">
                <v-icon color="#0C9B93" small class="mr-2">mdi-information-slab-circle-outline</v-icon>
                <small>Você será cobrado R${{ formatPrice(plan.price - (plan.id == 2 ? 2 : 5)) }} pelos primeiros três meses, após esse período, a assinatura será ajustada para R${{ formatPrice(plan.price) }}.</small>
              </v-card-actions>
            </v-alert>
          </v-card-text>
        </div>
        <v-fade-transition>
          <v-card-actions>
            <v-alert type="success" dense v-if="success" outlined color="primary">
              {{$t(`assignPlanSuccess`)}}  
            </v-alert>
          </v-card-actions>
        </v-fade-transition>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlreadyPlan" width="400">
      <v-card v-if="user">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" icon @click="dialogAlreadyPlan = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-alert type="info">
            {{$t(`youAssignPlan`)}} {{$t(`plan_${user.subscriptionPlan.name}`)}}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BtnLogin from "./BtnLogin.vue"
import {apiV1SubscriptionPlan} from "../../../service/resources/api-v1-subscription-plan"
import {apiV1SubscriptionPlanInclude} from "../../../service/resources/api-v1-subscription-plan-include"
import {apiV1Subscription} from "../../../service/resources/api-v1-subscription"
import pagarme from 'pagarme'
import { apiV1Auth } from "../../../service/resources/api-v1-auth"
  export default {
    name: 'SubscrioptionAndPlans',
    components: {
    BtnLogin
},
    props: ['screenData', 'planId'],
    data: () => ({
      discount: false,
      subscription: {customer: {phone: {countryCode: '', number: '', areaCode: ''}, document: '', name: ''}, card: {number: '', cvv: '', expiration: '', holderName: '', billingAddress: {line1: '', line2:'', zipCode:'', city: '', state: ''}}},
      loadSubscription: false,
      subscriptionValid: false,
      error: null,
      success: false,
      loadVerifyCard: false,
      cardValidadion: {
        brand: null,
        card_cvv: false,
        card_expiration_date: false,
        card_holder_name: false,
        card_number: false,
      },
      card_hash: null,      
      
      loading: false,
      plan: null,
      loadingPlanIncludes: false,
      planIncludes: {content: []},
      plans: {content: []},
      dialogLogin: false,
      subscribleYear: false,
      dialogSubscrible: false,
      user: null,
      dialogAlreadyPlan: false
    }),
    mounted: function() {
      if(this.$route.query.d) {
        localStorage.setItem('discount', this.$route.query.d)
      }
      this.discount = localStorage.getItem("discount")
      //this.discount = true
      if(process.env.VUE_APP_ENVIROMENT == 'DEV') {
        this.subscription.customer.document = process.env.VUE_APP_DOCUMENT
        this.subscription.customer.name = process.env.VUE_APP_NAME
        this.subscription.customer.phone.countryCode = process.env.VUE_APP_DDI
        this.subscription.customer.phone.areaCode = process.env.VUE_APP_DDD
        this.subscription.customer.phone.number = process.env.VUE_APP_TEL

        this.subscription.card.holderName = process.env.VUE_APP_CARD_HOLDER_NAME
        this.subscription.card.number = process.env.VUE_APP_CARD_NUMBER
        this.subscription.card.expiration = process.env.VUE_APP_CARD_EXPIRATION
        this.subscription.card.cvv = process.env.VUE_APP_CARD_CVV
        this.subscription.card.billingAddress.line1 = process.env.VUE_APP_ADDRESS
        this.subscription.card.billingAddress.line2 = process.env.VUE_APP_COMPLEMENT
        this.subscription.card.billingAddress.zipCode = process.env.VUE_APP_CEP
        this.subscription.card.billingAddress.city = process.env.VUE_APP_CITY
        this.subscription.card.billingAddress.state = process.env.VUE_APP_UF
        this.verifyCard()
      }
      this.loading = true
      apiV1SubscriptionPlan.page({
        page: 0,
        size: 100,
        active: true,
        embedSubscriptionPlanInclude: true,
      }).then(result => {
        this.plans = result.data
        this.loading = false
        if(this.planId) {
          this.getPlan(this.plans.content.filter(p => p.id == this.planId)[0])
        }
      })
      this.loadingPlanIncludes = true
      apiV1SubscriptionPlanInclude.page({
        page: 0,
        size: 100,
        active: true,
        orderBy: 'order' 
      }).then(result => {
        result.data.content.forEach(element => {
          element.open = false
        });
        this.planIncludes = result.data
        this.loadingPlanIncludes = false
      })
    },
    watch: {
      'dialogLogin' : function(isOpen) {
        if(!isOpen) {
          localStorage.removeItem('planSelectAuth')
        }
      },
      'dialogSubscrible' : function(isOpen) {
        if(!isOpen) {
          localStorage.removeItem('planSelectAuth')
        }
      }
    },
    computed: {
      login: function() {
        return `${process.env.VUE_APP_API_V1_URL}/oauth2/authorization/google`
      },
    },
    methods: {
      isCardValid: function() {
        let status = this.cardValidadion.card_number 
          && this.cardValidadion.card_holder_name 
          && this.cardValidadion.card_expiration_date 
          && this.cardValidadion.card_cvv
          && this.cardValidadion.brand
        return status
      },
      isCpf: function(strCPF) {
        var Soma
        var Resto
        Soma = 0
        if (!strCPF || strCPF == "00000000000" || strCPF.length != 11) return false
        var i = 1
        for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i)
          Resto = (Soma * 10) % 11
        if ((Resto == 10) || (Resto == 11))  Resto = 0
        if (Resto != parseInt(strCPF.substring(9, 10)) ) return false
        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i)
        Resto = (Soma * 10) % 11
        if ((Resto == 10) || (Resto == 11))  Resto = 0
        if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false
        return true
      },
      isCnpj: function(cnpj) {
        cnpj = !cnpj ? cnpj.replace(/[^\d]+/g,'') : cnpj;
    
        if(!cnpj || cnpj == '' || cnpj.length != 14) return false;
    
        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999")
            return false;
            
        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0,tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        var i = 0
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;
            
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
              return false;
              
        return true;
      },
      verifyCard: function() {
        this.loadVerifyCard = true
        const card = {
          card_number: this.subscription.card.number,
          card_holder_name: this.subscription.card.holderName,
          card_expiration_date: this.subscription.card.expiration.replace('/', ''),
          card_cvv: this.subscription.card.cvv,
        }
        this.cardValidadion = pagarme.validate({card: card}).card
        setTimeout(() => {
          this.loadVerifyCard = false
        }, 1000);
        if(this.isCardValid()) {
          pagarme.client.connect({ encryption_key: process.env.VUE_APP_PAGARME_APP_ID })
          .then(client => client.security.encrypt(card))
          .then(card_hash => {
            this.card_hash = card_hash
            console.log(card_hash)
            this.loadVerifyCard = false
          })
        }
      },
      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      getPlan: function(plan){
        this.plan = plan
        if(!localStorage.authorization) {
          this.dialogLogin = true
          localStorage.setItem('planSelectAuth' , plan.id)
        }else {
          apiV1Auth.get().then(result => {
            this.user = result.data.user
            if(result.data.user.subscriptionPlan.id == plan.id) {
              this.dialogAlreadyPlan = true
            }else {
              this.dialogSubscrible = true
            }
          })
        }
      },
      subscrible: function() {
        this.error = null
        this.loadSubscription = true

        this.subscription.subscriptionPlan = this.plan
        this.subscription.early = this.subscribleYear
        this.subscription.discount = this.discount ? true : false
        apiV1Subscription.subscrible(this.subscription).then(() => {
          apiV1Auth.get().then(response => {
            this.loadSubscription = false
            this.success = true
            this.screenData.auth = response.data
          })
        }).catch(err => {
          this.error = err
          this.loadSubscription = false
        })
      },
    },
  }
</script>
<style scoped>
/* Large desktops and laptops */
@media (min-width: 1200px) {

}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .plan-name-display {
    writing-mode: vertical-rl !important;
  }

}

.header-title {
  color: #1A237E;
  margin-bottom: 0.66667rem;
}

.text-description-1 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  margin: 0;
  text-align: left;
  color: #718096;
}
.tab-item-subscrible-year{
  color: #0079F1;
  background-color: #78FFFF;
  border-radius: 25px;
}

.tabs-subscrible-year {
  padding: 2px !important;
}

.color-green-blue{
  color: #00E6DB !important 
}
</style>