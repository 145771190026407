import http from "../axios";

let apiV1Click = {
	baseUrl: '/api/v1/clicks',
	post: async (dto, parans) => {
		return await http.post(`${apiV1Click.baseUrl}${parans ? parans : ''}`, dto);
	},
	get: async (id, requestParams) => {
		return await http.get(`${apiV1Click.baseUrl}/${id}?${requestParams.view? `view=${requestParams.view}` : ''}`);
	},
	page: async (filter) => {
		return await http.get(`${apiV1Click.baseUrl}?page=${filter.page}&size=${filter.size}${filter.text ? `&text=${filter.text}` : ''}`);
	}
}

export  { apiV1Click }