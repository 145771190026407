import http from "../axios";

let apiV1OnlineAddresses = {
	baseUrl: '/api/v1/online-addresses',
	post: async (dto) => {
		return await http.post(apiV1OnlineAddresses.baseUrl, dto);
	},
	put: async (dto) => {
		return await http.put(apiV1OnlineAddresses.baseUrl, dto);
	},
	delete: async (id) => {
		return await http.delete(`${apiV1OnlineAddresses.baseUrl}/${id}`);
	},
	get: async (id) => {
		return await http.get(`${apiV1OnlineAddresses.baseUrl}/${id}`);
	},
	page: async (filter) => {
		return await http.get(`${apiV1OnlineAddresses.baseUrl}?page=${filter.page}&size=${filter.size}${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}${filter.text ? `&text=${filter.text}` : ''}${filter.type ? `&type=${filter.type}` : ''}${filter.embedBiography ? `&embedBiography=${filter.embedBiography}` : ''}
			${filter.error ? `&error=${filter.error}` : ''}
			${filter.domain ? `&domain=${filter.domain}` : ''}
			${filter.errorIn ? `&errorIn=${filter.errorIn}` : ''}
			${filter.typeIn ? `&typeIn=${filter.typeIn}` : ''}
			${filter.dateCreateIni ? `&dateCreateIni=${filter.dateCreateIni}` : ''}
			${filter.dateCreateEnd ? `&dateCreateEnd=${filter.dateCreateEnd}` : ''}
			`);
	},
	pageDomain: async (filter) => {
		return await http.get(`${apiV1OnlineAddresses.baseUrl}/domains?page=${filter.page}&size=${filter.size}${filter.orderBy ? `&orderBy=${filter.orderBy}` : ''}
		${filter.error ? `&error=${filter.error}` : ''}
		${filter.dateCreateIni ? `&dateCreateIni=${filter.dateCreateIni}` : ''}
		${filter.dateCreateEnd ? `&dateCreateEnd=${filter.dateCreateEnd}` : ''}
		${filter.type ? `&type=${filter.type}` : ''}
		`);
	}
}

export  { apiV1OnlineAddresses }