import Vue from "vue";
import Router from "vue-router";
import Index from "@/components/pages/Index.vue";
import Redirect from "@/components/Redirect.vue";
import IndexPage from "@/components/pages/IndexPage.vue";
import LoadingAuth from "@/components/pages/LoadingAuth.vue";
import PainelURLShortener from "@/components/pages/painel/PainelURLShortener.vue";
import Painel from "./components/pages/painel/Painel.vue";
import PainelBioPage from "./components/pages/painel/PainelBioPage"
import PainelLinks from "./components/pages/painel/PainelLinks"
import PainelApiReferencePage from "./components/pages/painel/PainelApiReferencePage"
import PainelSMSPage from "./components/pages/painel/PainelSMSPage"
import PainelInfoProduct from "./components/pages/painel/PainelInfoProduct"
import SMSPage from "@/components/pages/SMSPage.vue";
import ContactsPage from "@/components/pages/ContactsPage.vue";
import SmsOptIn from "@/components/SmsOptIn.vue";
import SmsOptOut from "@/components/SmsOptOut.vue";
import InfoProdutoPage from "@/components/pages/InfoProdutoPage.vue";
import TermsAndPrivacyPolicy from "@/components/pages/TermsAndPrivacyPolicy.vue";

Vue.use(Router);

const isLogged = (to, from, next) => {
  Vue.nextTick(() => {
    if(to.meta.title)
      document.title = to.meta.title;
  });
  const expiration = localStorage.getItem("expiration");
  if(expiration) {
    if(new Date(expiration) < new Date()) {
      localStorage.clear()
    }
  }
  if(!localStorage.authorization)
    next("/");
  else
    next()
};

export default new Router({
  routes: [
    {
      path: "/",
      component: Index,
      children: [
        {
          path: '',
          name: 'Index',
          component: IndexPage,
          meta: {
            title: 'migly',
          },
        }, 
        {
          path: '/privacy-policy',
          name: 'TermsAndPrivacyPolicy',
          component: TermsAndPrivacyPolicy,
          meta: {
            title: 'migly terms and privacy policy',
          },
        }, 
      ]
    },
    {
      path: "/g",
      component: Index,
      children: [
        {
          path: '',
          name: 'Index',
          component: IndexPage,
          meta: {
            title: 'migly',
          },
        }, 
      ]
    },
    {
      path: "/f",
      component: Index,
      children: [
        {
          path: '',
          name: 'Index',
          component: IndexPage,
          meta: {
            title: 'migly',
          },
        }, 
      ]
    },
    {
      path: "/loading",
      name: 'Loading Auth',
      component: LoadingAuth,
      meta: {
        title: 'login',
      },
    },
    {
      path: "/painel",
      component: Painel,
      beforeEnter: isLogged,
      children: [
        {
          path: "links",
          name: 'Painel links',
          component: PainelLinks,
          beforeEnter: isLogged,
          meta: {
            title: 'painel - encurtador de urls',
          },
          children: [
            {
              path: "shortened",
              name: 'Painel address',
              component: PainelURLShortener,
              beforeEnter: isLogged,
              meta: {
                title: 'painel - encurtador de urls',
              },
              children: [
    
              ]
            },
            {
              path: "page-bio",
              name: 'Painel page bio',
              component: PainelBioPage,
              beforeEnter: isLogged,
              meta: {
                title: 'painel - biografia',
              },
              children: [
    
              ]
            },
            {
              path: "shortened/:id",
              name: 'Painel Address',
              component: PainelURLShortener,
              beforeEnter: isLogged,
              meta: {
                title: 'painel - encurtador de urls',
              },
              children: [

              ]
            },
          ]
        },
        {
          path: "api-reference",
          name: 'Painel API Reference',
          component: PainelApiReferencePage,
          beforeEnter: isLogged,
          meta: {
            title: 'painel - API Reference',
          },
          children: [

          ]
        },
        {
          path: "sms",
          name: 'Painel SMS',
          component: PainelSMSPage,
          beforeEnter: isLogged,
          meta: {
            title: 'painel - sms',
          },
          children: [
            {
              path: "",
              name: 'Page SMS',
              component: SMSPage,
              beforeEnter: isLogged,
              meta: {
                title: 'painel - sms',
              },
              children: [
    
              ]
            },
            {
              path: "sender",
              name: 'Page SMS',
              component: SMSPage,
              beforeEnter: isLogged,
              meta: {
                title: 'painel - sms',
              },
              children: [
    
              ]
            },
            {
              path: "contacts",
              name: 'Page Contacts',
              component: ContactsPage,
              beforeEnter: isLogged,
              meta: {
                title: 'painel - contacts',
              },
              children: [
    
              ]
            },
          ]
        },
        {
          path: "nfoproduct",
          name: 'Painel Infoproduto',
          component: PainelInfoProduct,
          beforeEnter: isLogged,
          meta: {
            title: 'painel - Infoproduto',
          },
          children: [
            {
              path: "",
              name: 'Page Infoproduto',
              component: InfoProdutoPage,
              beforeEnter: isLogged,
              meta: {
                title: 'painel - infoproduto',
              },
              children: [
    
              ]
            },
          ]
        },
      ]
    },
    {
      title: 'migly - sms opt-in',
      path: "/i/:code",
      name: 'SmsOptIn',
      component: SmsOptIn,
      children: [
      ]
    },
    {
      title: 'migly - sms opt-out',
      path: "/o/:code",
      name: 'SmsOptOut',
      component: SmsOptOut,
      children: [
      ]
    },
    {
      title: 'migly - redirecionamento',
      path: "/:code",
      name: 'Redirect',
      component: Redirect,
      children: [
        {
          path: '',
          name: 'Index',
          component: Index,
          meta: {
            title: 'migly',
          },
          children: [
            {
              path: '',
              name: 'Index',
              component: IndexPage,
              meta: {
                title: 'migly',
              },
            }, 
          ]
        },
      ]
    },
    {
      title: 'migly - redirecionamento',
      path: "/:code/:contactCode",
      name: 'Redirect',
      component: Redirect,
      children: [
        {
          path: '',
          name: 'Index',
          component: Index,
          meta: {
            title: 'migly',
          },
          children: [
            {
              path: '',
              name: 'Index',
              component: IndexPage,
              meta: {
                title: 'migly',
              },
            }, 
          ]
        },
      ]
    },
  ],
  base: __dirname,
  mode: "history",
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
});
