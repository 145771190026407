<template>
  <div>
    <router-view v-if="showIndex"></router-view>
    <div class="full-height box" v-else>
      <head v-if="biography">
        <title>{{biography.name}}</title>
      </head>
      <v-fade-transition group hide-on-leave leave-absolute>
        <div class="verticle-center" v-if="loading" key="0001">
          <div width="600" class="horizontaly-center">
            <div key="1">
              <v-card-actions class="mb-0 -b-0">
                <v-spacer/>
                <v-avatar width="150" height="150">
                  <v-img v-if="config && config.picture && config.picture.src" :src="config.picture.src" key="1" width="150" height="150"/> 
                  <v-img v-else src="/images/logo/logo-04.png" contain key="2" width="150" /> 
                </v-avatar>
                <v-spacer/>
              </v-card-actions>
            </div>
            <v-fade-transition leave-absolute hide-on-leave>
              <v-card-title class="mb-0 -b-0" v-if="config">
                <v-spacer/>
                {{config.siteName}}
                <v-spacer/>
              </v-card-title>
            </v-fade-transition>
            <v-card-actions>
              <v-spacer/>
              <v-progress-circular x-small :indeterminate="!config" :value="progress" width="1" size="22" color="primary"/>
              <v-spacer/>
            </v-card-actions>
            <v-container>
              <v-card v-if="address && address.url" elevation="0" color="grey lighten-4"  style="line-break:anywhere">
                <v-card-subtitle><b class="">{{ $t('youWillBeRedirectedTo') }}</b> <p><i>{{ address.url.substring(0, 100) }}...</i></p></v-card-subtitle>
                <v-card-actions v-if="false">
                  <v-spacer/>
                  <v-btn small text>
                    <v-progress-circular x-small :value="progress" width="2" size="20" color="primary" class="mr-2"/>
                    {{ $t('cencelRedirected') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
            <v-app-bar color="transparent" fixed  v-if="address && (!address.user || address.user.subscriptionPlan.free) && false" elevation="0">
              <template v-slot:extension>
                <v-container>
                  <a class="link" target="_blank" href="https://shope.ee/6KhFO4RZzS">
                    <v-card color="orange darken-2">
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img src="https://i.pinimg.com/474x/90/b2/65/90b26573e0f822d9bb987d05ce396cbc.jpg"/>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-card-title class="white--text">

                            {{ $t('yourCompanyOrProductHere') }}
                          </v-card-title>
                          <v-card-subtitle v-if="false">
                            {{ $t('yourCompanyOrProductHereText') }}
                          </v-card-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </a>
                </v-container>
              </template>
            </v-app-bar>
            <v-footer color="transparent" fixed  v-if="address && address.type != 'CHROME_EXTENSION' && (!address.user || address.user.subscriptionPlan.free) && !isLoged()" elevation="0">
              <v-container>
                <a class="link" target="_blank" href="https://s.shopee.com.br/10jNZsNgqw" style="width: 100%;">
                  <v-card color="orange darken-2" width="100%">
                    <v-list-item>
                      <v-list-item-avatar size="80">
                        <v-img src="https://i.pinimg.com/474x/90/b2/65/90b26573e0f822d9bb987d05ce396cbc.jpg"/>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-card-title class="white--text">
                          {{ $t('yourCompanyOrProductHere') }}
                        </v-card-title>
                        <v-card-subtitle v-if="false">
                          {{ $t('yourCompanyOrProductHereText') }}
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-spacer/>
                          <v-btn text small dark color="white">
                            {{ $t('callToAction') }}
                          </v-btn>
                        </v-card-actions>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </a>
              </v-container>
            </v-footer>
            <v-footer absolute color="transparent" v-else-if="config && config.bannerImage && !config.bannerImage.deleted && config.bannerImage.src">
              <v-container>
                <a :href="config.bannerLink" target="_blank">
                  <v-card>
                    <v-img :src="config.bannerImage.src" height="100"/>
                  </v-card>
                </a>
              </v-container>
            </v-footer>
          </div>
        </div>
        <div v-else-if="biography" key="0002">
          <v-container class="verticle-center" >
            <v-card width="100%">
              <BiographySmartphoneView  :bio="biography" :onlySmartphone="false"/>
            </v-card>
          </v-container>
        </div>
        <div v-else-if="infoproduct" key="0002">
          <InfoproductView :infoproduct="infoproduct" :edit="false" :view="$vuetify.breakpoint.name"/>
        </div>
      </v-fade-transition>
    </div>
  </div>
</template>


<script>
  import { apiV1Click } from "../service/resources/api-v1-click"
import { apiV1OnlineAddresses } from "../service/resources/api-v1-online-addresses"
import { apiV1ContactAccessAddress } from "../service/resources/api-v1-contact-access-address"
import BiographySmartphoneView from "./pages/fragments/BiographySmartphoneView.vue"
import InfoproductView from './pages/fragments/InfoproductView.vue'


  export default {
    name: 'Redirect',
    components: {
    BiographySmartphoneView,
        InfoproductView
},
    data: () => ({  
      loading: true,
      biography: null,
      config: null,
      progress: 10,
      address: null,
      showIndex: false,
      infoproduct: null,
    }),
    mounted: function() {
      this.loading = false
      apiV1OnlineAddresses.get(this.$route.params.code, {view : true}).then(result => {
        this.address = result.data
        if(result && result.data) {
          if(!this.$route.query.countClick)
            apiV1Click.post({
              onlineAddress: result.data,
              clickProperties: [
                {name: "platform" , value: window.navigator.platform, type : "STANDARD"},
                {name: "breakpoint-name" , value: this.$vuetify.breakpoint.name, type : "STANDARD"},
              ],
              },
            )
          if(result.data.user)
            this.config = result.data.user.config
          if(result.data.biography) {
            this.loading = false
            this.biography = result.data.biography
          } else if(result.data.infoproduct){
            this.loading = false
            this.infoproduct = result.data.infoproduct
            this.infoproduct.user = this.address.user
          } else {
            this.loading = true
            setTimeout(() => {
              // this.showIndex = true
              if(result.data.url.includes(`http`) || result.data.url.includes(`http`))
                window.location.href = result.data.url
              else
                window.location.href = `http://${result.data.url}`
            }, this.config ? this.config.loaderTime * 1000 : (!this.address.user || this.address.user.subscriptionPlan.free) ? 4000 : 2000);
            if(this.config)
              setInterval(() => {
                this.progress = this.progress + (100 / this.config.loaderTime)
              }, 1000);
            else
              setInterval(() => {
                this.progress = this.progress + (100 / 4)
            }, 1000);
          }

          // apiV1Configs.getByOnlineAddress(result.data.code).then(resultConfig => {
          //   //this.loading = true
          //     if(resultConfig.data && this.address.user && this.address.user.subscriptionPlan.id == 3) {
          //       this.config = resultConfig.data
          //     }
          //     setTimeout(() => {
          //       if(result.data.biography) {
          //         this.loading = false
          //         this.biography = result.data.biography
          //       } else if(result.data.infoproduct){
          //         this.loading = false
          //         this.infoproduct = result.data.infoproduct
          //         this.infoproduct.user = this.address.user
          //       } else {
          //         //this.showIndex = true
          //         // if(result.data.url.includes(`http`) || result.data.url.includes(`http`))
          //         //   window.location.href = result.data.url
          //         // else
          //         //   window.location.href = `http://${result.data.url}`
          //       }
          //     }, this.config ? this.config.loaderTime * 1000 : (!this.address.user || this.address.user.subscriptionPlan.free) ? 4000 : 2000);

          //     if(this.config)
          //       setInterval(() => {
          //         this.progress = this.progress + (100 / this.config.loaderTime)
          //       }, 1000);
          //     else
          //       setInterval(() => {
          //           this.progress = this.progress + (100 / 4)
          //         }, 1000);
          //   })
        }else {
          this.$router.push('/')
        }
      })
    
      if(!this.$route.query.countClick)
        if(this.$route.params.contactCode) {
          apiV1ContactAccessAddress.save({
            smsShootingHasContact: {contact: {code: this.$route.params.contactCode}}, 
            smsShootingHasOnlineAddress: {onlineAddress: {code: this.$route.params.code}}}
            )
        }

    },
    methods: {
      isLoged: function() {
        return !!localStorage.getItem("authorization")
      }
    },
  }
</script>
<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 600px !important; 
  }
}

@media (min-width: 960px) {
  .container {
      max-width: 600px !important; 
  }
}
</style>