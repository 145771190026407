<template>
  <a :href="login" class="link" v-if="isShow()">
    <v-btn :loading="loading" color="primary" block rounded>
      <b>{{$t('login')}}</b>
    </v-btn>
  </a>
</template>
<script>
  export default {
    name: 'BtnLogin',
    props: ['screenData'],
    components: {
    },
    data: () => ({
      loading: false,
    }),
    mounted: function() {
      if(this.$route.query.authorization) {
        this.loading = true
        localStorage.authorization = this.$route.query.authorization
        this.$router.replace({'query': null});
        this.$forceUpdate()
      }
    },
    computed: {
      login: function() {
        return `${process.env.VUE_APP_API_V1_URL}/oauth2/authorization/google`
      },
    },
    methods: {
      isShow: function() {
        return !localStorage.authorization
      }
    },
  }
</script>
<style scoped>
</style>