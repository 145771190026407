<template>
  <v-tabs v-if="block" full-width fixed-tabs v-model="tab" style="border-top: 1px solid #E7EBF2" @change="$router.push(tab ? '/painel/links/page-bio' : '/painel/links/shortened')">
    <v-tabs-slider></v-tabs-slider>
    <v-tab :value="0" style="text-transform:capitalize" >
      <div>{{ $t('shortenAddress') }}</div>
      <v-menu offset-y centered v-if="linksErrors">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="warning"
            dark
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon>mdi-alert-circle</v-icon>
          </v-btn>
        </template>
        <v-card color="warning" style="padding: 1px">
          <v-card>
            <v-card-title>
              <b>{{ $t('linksErrorTitle') }}</b>
            </v-card-title>
            <v-card-subtitle>
              {{$t('linksErrorVerify')}}
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer/>
              <v-btn rounded color="primary" dark @click="verifyLinks()">
                {{$t('toCheck')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-menu>
    </v-tab>
    <v-tab :value="1" style="text-transform:capitalize">
      <div >{{ $t('pageBio') }}</div>
      <v-menu offset-y centered v-if="linksErrorsBio">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="warning"
            dark
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon>mdi-alert-circle</v-icon>
          </v-btn>
        </template>
        <v-card color="warning" style="padding: 1px;">
          <v-card>
            <v-card-title>
              <b>{{ $t('linksErrorTitle') }}</b>
            </v-card-title>
            <v-card-subtitle>
              {{$t('linksErrorVerify')}}
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer/>
              <v-btn rounded color="primary" dark @click="verifyLinksBio()">
                {{$t('toCheck')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-menu>
    </v-tab>
  </v-tabs>
  <v-btn-toggle
    v-else
    :value="$route.path"
    :rounded="!block"
    color="blue"
    background-color="#7E8EA6"
    readonly
    :flat="!block"
    borderless
    :dense="!block"
    block
  >
    <v-btn :small="!block" value="'/painel/links/shortened" @click="$router.push(`/painel/links/shortened`)" :color="['shortened'].includes($route.path)  ? 'primary': ''" light>
      <div>{{ $t('shortenAddress') }}</div>
    </v-btn>
    <v-btn :small="!block" value="'/painel/links/page-bio'" @click="$router.push(`/painel/links/page-bio`)"  :color="['page-bio'].includes($route.path)  ? 'primary': ''" light>
      <div >{{ $t('pageBio') }}</div>
    </v-btn>
  </v-btn-toggle>
</template>
<script>
import { apiV1OnlineAddresses } from '../../../service/resources/api-v1-online-addresses'

  export default {
    name: 'TabOptions',
    props: ['block'],
    components: {
    },
    data: () => ({
      tab: 0,
      linksErrors: false,
      linksErrorsBio: false
    }),
    watch: {
      '$route': function() {
        this.verifytab()
        this.findLinks()
      },
    },
    mounted: function() {
      this.verifytab()
      this.findLinks()
    },
    computed: {
    },
    methods: {
      verifytab: function() {
        this.tab = this.$route.path.includes('page-bio')  ? 1 : 0
      },
      findLinks: function() {
        apiV1OnlineAddresses.page({page: 0, size: 1, error: true}).then(result => {
          if(!result.data.empty) {
            this.linksErrors = true
          }else {
            this.linksErrors = false
          }
        })
        apiV1OnlineAddresses.page({page: 0, size: 1, error: true, type: 'BIO'}).then(result => {
          if(!result.data.empty) {
            this.linksErrorsBio = true
          }else {
            this.linksErrorsBio = false
          }
        })
      },
      verifyLinks: function() {
        var query = this.$route.query
        query.error = true
        query.page = 0
        query.typeIn = null
        this.$router.push('/painel/links/shortened?page=0&errorIn=true')
      },
      verifyLinksBio: function() {
        var query = this.$route.query
        query.error = true
        query.page = 0
        query.typeIn = null
        this.$router.push('/painel/links/page-bio?page=0&errorIn=true&typeIn=BIO')
      }
    },
  }
</script>
<style scoped>
</style>