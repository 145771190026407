<template>
  <v-row v-if="screenData.auth" no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12">
      <v-card-title class="primary--text">
        <b>{{$t('API_ACCESS')}}</b>
        <v-spacer/>
        <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
        <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
          <v-card>
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
            </v-card-actions>
            <v-card-text>
              <SubscriptionAndPlans :screenData="screenData"/>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-title>
    </v-col>
    <v-col cols="12">
      <v-card class="card-corner-radius mt-4" elevation="0">
        <v-card-title>
          {{$t('authorization')}}
          <v-spacer/>
          <v-btn rounded @click="generateAuthorization" color="primary" :loading="loading" :disabled="screenData.auth.user.subscriptionPlan.id < 3">
            {{$t('generateAuthorization')}}
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          <v-spacer/>
          <a :href="`/api-doc`" target="_blank" class="link"> {{$t('swaggerUi')}}</a>
        </v-card-subtitle>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card elevation="0" class="mt-5 full-height">
        <embed src="/api-doc" style="width: 100%; height: 100%;"/>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog" width="400">
        <v-card v-if="authV2">
          <v-card-actions>
            <span>
              {{$t('authorization')}}
            </span>
            <v-spacer/>
            <v-btn icon color="red" @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <v-text-field
              :value="`Bearer ${authV2.authorization}`"
              id="authorization"
              readonly
              outlined
              rounded
              flat
              dense
              :append-icon="copied ? `mdi-check-circle` : `mdi-content-copy`"
              @click="copy()"
              @click:append="copy()"
              :messages="$t('clickToCopy')"
              >

            </v-text-field>
            <v-chip color="primary" class="primary--text" outlined v-show="false">
              <input id="authorization"  :value="authV2.authorization" class="primary--text" readonly v-show="false"/>
              <v-icon color="primary" class="ml-1" @click="copy()">
                mdi-content-copy
              </v-icon>
              <v-slide-x-transition>
                <v-icon color="green" class="ml-1" v-if="copied">
                  mdi-check-circle
                </v-icon>
              </v-slide-x-transition>
            </v-chip>
          </v-card-text>
          <v-card-subtitle class="red--text">
            {{ $t('expiredAt')}} {{ new Date(authV2.expiredAt.replace('[UTC]' , '')) }}
          </v-card-subtitle>
          <v-card-subtitle>
            
          </v-card-subtitle>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { apiV1Auth } from '../../../service/resources/api-v1-auth';
import IconInfoSubscriblePlan from '../fragments/IconInfoSubscriblePlan.vue';
import SubscriptionAndPlans from '../fragments/SubscriptionAndPlans.vue';

  export default {
    name: 'PainelApiReferencePage',
    components: {
    IconInfoSubscriblePlan,
    SubscriptionAndPlans
},
    props: ['screenData'],
    data: () => ({
      dialog: false,
      copied: false,
      loading: false,
      authV2 : null,
      planData: {
        dialogPlansAndPrices: false,
        dialogSubscriptionPlan: false
      },
    }),
    computed: {
    },
    mounted: function() {
    },
    methods: {
      getBackendHost: function(){
        return process.env.VUE_APP_API_V1_URL
      },
      generateAuthorization: function(){
        this.loading = true
        apiV1Auth.post().then(result => {
          this.dialog = true
          this.authV2 = result.data
        }).finally(() => {
          this.loading = false
        })
      },
      copy: function () {
        let element = document.querySelector('#authorization');
        element.setAttribute('type', 'text') 
        element.select()
        document.execCommand('copy');
        console.log(element)
        this.copied = true
        this.$forceUpdate()
        setTimeout(() => {
          this.copied = false
          this.$forceUpdate()
        }, 3000);
      },
    },
  }
</script>
<style>

</style>