<template>
  <v-menu offset-y right :open-on-hover="false" :close-on-content-click="false" v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" small icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </template>
    <v-card width="280" class="card-corner-radius">
      <v-card-actions>
        {{$t('shareOn')}}
        <v-spacer/>
        <v-btn color="red" icon @click="menu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider/>
      <v-card-title>
        <v-chip color="transparent" class="primary--text pa-2">
          <input :id="`copy-link-${onlineAddress.id}`"  :value="`${host}/${onlineAddress.code}`" class="primary--text" readonly />
          <v-icon color="primary" class="ml-1" @click="copyLink(onlineAddress)">
            mdi-content-copy
          </v-icon>
          <v-slide-x-transition>
            <v-icon color="green" class="ml-1" v-if="onlineAddress.copy">
              mdi-check-circle
            </v-icon>
          </v-slide-x-transition>
        </v-chip>
      </v-card-title>
      <v-card-actions v-for="(o, index) in $t('shareOptions')" :key="`share-option-${index}`" @click="share(o)" class="pointer pl-5 pr-5">
        <v-icon class="mr-1" :color="o.color">{{o.icon}}</v-icon>
        {{o.description}}
        <v-spacer/>
        <v-icon>mdi-open-in-new</v-icon>
      </v-card-actions>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <QRCodeMigly :value="`${host}/${onlineAddress.code}`" :enableControlls="true" width="120" border="0"/>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import QRCodeMigly from './QRCodeMigly.vue';

  export default {
    name: 'ShareOptions',
    props: ['onlineAddress'],
    components: {
    QRCodeMigly
},
    data: () => ({
      host: null,
      menu: false
    }),
    mounted: function() {
      this.host = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;
    },
    computed: {

    },
    methods: {
      share(shareOption) {
        window.open(`${shareOption.url}${window.location.origin}/${this.onlineAddress.code}?s=${shareOption.value}`); 
      },
      copyLink: function (item) {
        let element = document.querySelector('#copy-link-'+ item.id);
        element.setAttribute('type', 'text') 
        element.select()
        document.execCommand('copy');
        console.log(element)
        item.copy = true
        this.$forceUpdate()
        setTimeout(() => {
          item.copy = false
          this.$forceUpdate()
        }, 3000);
      },
    },
  }
</script>
<style scoped>
</style>