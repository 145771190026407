<template>
  <v-main>
    <v-row no-gutters >
      <v-col cols="12" xs="12" sm="12" md="7" class="pl-0 ml-0 full-height" id="painel-urls">
        <section>
          <v-row no-gutters>
            <v-col cols="12" class="mb-0 pb-0">
              <v-card-title class="primary--text ml-0 pl-0"><b>{{$t('shortenAddress')}}</b></v-card-title>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12">
              <v-card class="card-corner-radius" elevation="0">
                <v-card-text>
                  <UrlShortener :screen-data="screenData"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </section>
        <section>
          <UrlShortenerTable :screenData="screenData"/>
        </section>
      </v-col>
      <v-col md="5" v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)" class="full-height">
        <UrlShortenerDashboard :screenData="screenData" ref="urlShortenerDashboard"/>
      </v-col>
    </v-row>
    <v-navigation-drawer
      v-if="['xs', 'sm'].includes($vuetify.breakpoint.name)"
      v-model="screenData.dashboard"
      fixed
      temporary
      width="300"
      height="100%"
      class="grey lighten-5"
    >
      <v-card-actions v-if="false">
        <v-spacer/>
        <v-btn icon text color="red" @click="screenData.dashboard = false, screenData.address = null, $router.go(-1)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer/>
      </v-card-actions>
     <UrlShortenerDashboard :screenData="screenData" ref="urlShortenerDashboard"/>
    </v-navigation-drawer>
  </v-main>
</template>

<script>
import UrlShortener from "../fragments/UrlShortener.vue";
import UrlShortenerTable from "../fragments/UrlShortenerTable.vue";
import UrlShortenerDashboard from "../fragments/UrlShortenerDashboard.vue";
  export default {
    name: 'PainelURLShortener',
    components: {
    UrlShortener,
    UrlShortenerTable,
    UrlShortenerDashboard
},
    props: ['screenData'],
    data: () => ({

    }),
    watch: {
      'screenData.dashboard' : function() {

      }
    },
    computed: {

    },
    mounted: function() {
      if(this.$route.params.id)
        this.screenData.dashboard = true
    },
    methods: {
    },
  }
</script>
<style>

</style>