<template>
  <div v-if="screenData.auth">
    <v-menu bottom offset-y left open-on-focus open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-card class="card-circle" v-bind="attrs" v-on="on" elevation="4">
          <v-avatar v-if="screenData.auth">
            <v-img :src="screenData.auth.provider.picture" lazy-src="/images/logo/logo-02.png"/>
            <v-footer absolute color="black" elevation="0" class="ma-0 pa-0 " style="opacity: 0.5;">
              <v-icon color="white">mdi-menu-down</v-icon>
            </v-footer>
          </v-avatar>
        </v-card>
      </template>
      <v-list>
        <v-list-item @click="$router.push('/painel/links/shortened')">
          <v-list-item-avatar>
            <v-avatar v-if="screenData.auth">
              <v-img :src="screenData.auth.provider.picture" lazy-src="/images/logo/logo-02.png"/>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{screenData.auth.user.name}}
            </v-list-item-title>
            <v-list-item-subtitle class="grey--text">
              {{screenData.auth.user.email}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ml-2 mr-2"/>
        <v-list>
          <v-list-item @click="$router.push('/painel/links/shortened')">
            <v-list-item-icon>
              <v-icon color="blue">mdi-link-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="blue--text">
              {{$t('links')}}
            </v-list-item-title>
          </v-list-item>


          <v-list-item @click="$router.push('/painel/nfoproduct')">
            <v-list-item-icon>
              <v-icon color="blue">mdi-weather-cloudy</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="blue--text">
              {{$t('infoProduct')}}
            </v-list-item-title>
            <v-list-item-icon v-if="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3">
              <v-tooltip color="blue" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue" v-bind="attrs" v-on="on">mdi-alert-decagram</v-icon>
                </template>
                <span>{{$t('novelty')}}</span>
              </v-tooltip>
            </v-list-item-icon> 
          </v-list-item>


          <v-list-item @click="$router.push('/painel/sms')">
            <v-list-item-icon>
              <v-icon color="blue">mdi-message-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="blue--text">
              {{$t('sms')}}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="dialogConfig = true">
            <v-list-item-icon>
              <v-icon color="blue">mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="blue--text">
              {{$t('settings')}}
            </v-list-item-title>
            <v-list-item-icon v-if="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3">
              <v-tooltip color="blue" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue" v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                <span>{{$t('resourceOnlyPlan')}} {{$t('plan_PRO')}}</span>
              </v-tooltip>
            </v-list-item-icon> 
          </v-list-item>
          <v-list-item @click="getSubscriptionPlan" v-if="screenData.auth">
            <v-list-item-icon>
              <v-icon color="blue">mdi-ticket</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="blue--text">
              {{$t(`plan`)}} {{$t(`plan_${screenData.auth.user.subscriptionPlan.name}`)}}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/painel/api-reference')">
            <v-list-item-icon>
              <v-icon color="green">mdi-api</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="green--text">
              {{$t('apiRefSwagger')}}
            </v-list-item-title>
            <v-list-item-icon v-if="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3">
              <v-tooltip color="blue" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="blue" v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                <span>{{$t('resourceOnlyPlan')}} {{$t('plan_PRO')}}</span>
              </v-tooltip>
            </v-list-item-icon> 
          </v-list-item>
          <v-list-item @click="dialogProfitWithUs = true">
            <v-list-item-icon>
              <v-icon color="blue">mdi-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="blue--text">
              {{$t(`ProfitWithUs`)}}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="false">
            <v-list-item-icon>
              <v-icon color="blue">mdi-help-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="blue--text">
              {{$t('help')}}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon color="primary">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text">
              {{$t('loggout')}}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list>
    </v-menu>

    <v-dialog v-model="dialogConfig" width="800" persistent :fullscreen="['xs'].includes($vuetify.breakpoint.name)">
      <v-card>
        <v-card-actions>
          <v-btn @click="dialogConfig = false" icon><v-icon color="primary">mdi-arrow-left</v-icon></v-btn>
          <v-spacer/>
          <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
        </v-card-actions>
        <Config :screenData="screenData"/>
      </v-card>
    </v-dialog>
    <v-dialog v-model="planData.dialogPlansAndPrices" width="800" persistent :fullscreen="['xs'].includes($vuetify.breakpoint.name)">
      <v-card>
        <v-card-actions>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="primary">mdi-arrow-left</v-icon></v-btn>
          <v-spacer/>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData" :planId="planData.planId"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog  v-model="planData.dialogSubscriptionPlan" width="550" :persistent="loadSubscriptionPlan">
      <v-card>
        <v-card-actions>
          <v-btn @click="planData.dialogSubscriptionPlan = false" icon><v-icon color="primary">mdi-arrow-left</v-icon></v-btn>
          <v-spacer/>
          <v-btn color="primary" dark rounded @click="planData.dialogPlansAndPrices = true">
            <v-icon>mdi-crown</v-icon>
            {{$t('plansAndPrices')}}
          </v-btn>
        </v-card-actions>
        <template v-if="!assign">
          <v-card-text>
            <v-skeleton-loader type="card-heading"/>
            <v-skeleton-loader type="list-item-avatar" v-for="i in 3" :key="`loading-subscriotins-${i}`"/>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-title>
            {{$t(`youAssignPlan`)}} {{$t(`plan_${assign.subscriptionPlan.name}`)}}
          </v-card-title>
          <v-card-text>
            <template v-if="loadSubscriptionPlan">
              <v-skeleton-loader type="list-item-avatar" v-for="i in 3" :key="`loading-subscriotins-${i}`"/>
            </template>
            <template v-else>
              <div v-for="subscription, index in assign.subscriptions" :key="`assign-card-${index}`" class="mb-2">
                <v-card :color="subscription.status == 'active' ? `green` : subscription.status == 'canceled' ? `grey` : subscription.status == 'future' ? 'primary' : 'red'" dark>
                  <v-card-actions>
                    <v-icon small color="white" class="mr-1">mdi-circle-slice-8</v-icon>
                    <v-chip color="white" light small>
                      {{$t(`plan_${subscription.subscriptionPlan.name}`)}} 
                    </v-chip>
                    <v-spacer/>
                    <v-chip small :color="subscription.status == 'active' ? 'primary' : 'dark'" dark >
                      {{$t(subscription.status)}}
                    </v-chip>
                  </v-card-actions>
                  <div v-if="subscription.status == 'active' || subscription.status == 'future'">
                    <v-card-text >
                      <v-chip-group column>
                        <v-chip small color="white" light>
                           <v-icon small class="mr-1">mdi-calendar</v-icon>
                           <span v-if="subscription.status == 'future'">
                            {{$t(`start_in`)}}
                           </span>
                           <span v-else>
                            {{$t(`start_at`)}}
                           </span>
                           <span class="ml-1">
                             {{subscription.start_at}}
                           </span>
                        </v-chip>
                        <v-chip color="white" light small v-if="subscription.status == 'active'">
                           <v-icon small class="mr-1">mdi-calendar</v-icon>
                          {{$t(`next_billing_at`)}} {{subscription.next_billing_at}}
                        </v-chip>
                        <v-chip small color="white" light>
                           <v-icon small class="mr-1">mdi-update</v-icon>
                          {{$t(`interval`)}} {{$t(subscription.interval)}}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="subscription.current_cycle">
                      <v-chip-group>
                        <v-chip color="white" light outlined small>
                          {{$t(`current_cycle_cycle`)}} {{subscription.current_cycle.cycle}}
                        </v-chip>
                        <v-chip small :color="subscription.current_cycle.status == 'billed' ? 'light' : 'dark'" dark >
                          {{$t(subscription.current_cycle.status)}}
                        </v-chip>
                        <v-chip color="white" light outlined small>
                          {{$t(`current_cycle_billing_at`)}} {{subscription.current_cycle.billing_at}}
                        </v-chip>
                        <v-chip color="white" light outlined small>
                          {{$t(`current_cycle_start_at`)}} {{subscription.current_cycle.start_at}} {{$t(`endAt`)}} {{subscription.current_cycle.end_at}}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </div>
                </v-card>
              </div>
            </template>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn color="red" text x-small :disabled="!assign.subscriptions.filter(s => s.status != 'canceled').length" @click="dialogCancel = true">
              {{$t('cancelSubscrible')}}
            </v-btn>
            <v-spacer/>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCancel" :persistent="loadCancelSubscriptionPlan" width="350">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn icon color="red" @click="dialogCancel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-title>
          {{$t('titleCancel')}}
        </v-card-title>
        <v-card-text>
          {{$t('textCancel')}}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="red" @click="cancelSubscrible" :loading="loadCancelSubscriptionPlan">
            {{$t('confirmCancelSubscrible')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProfitWithUs" width="800" persistent>
      <v-card>
        <v-card-title class="primary--text">
          <v-btn @click="dialogProfitWithUs = false" icon color="primary"><v-icon>mdi-arrow-left</v-icon></v-btn>
          {{ $t('ProfitWithUs') }}
          <v-spacer/>
        </v-card-title>
        <v-card-title class="primary--text">
          Regras para novas indicações
        </v-card-title>
        <v-card-text>
          1 - Somente novos usuários. A pessoa com quem você está compartilhando o link não pode ter nenhuma conta existente no migly.
        </v-card-text>
        <v-card-text>
          2 - Somente para assinaturas BÁSICA e PROFISSIONAL. As comissões são elegíveis apenas para primeiras assinaturas mensais. 
        </v-card-text>
        <v-card-text>
          3 - O cliente indicado não pode pedir reembolso e precisa ficar ativo por pelo menos 45 dias. 
        </v-card-text>
        <v-card-text class="red--text">
          Nosso programa de afiliados ainda não está ativo, mas você pode entrar na fila enviando um email para <b>contato@exatamente-solucoes.com</b> com o assunto <i>PROGRAMA DE AFILIADOS MIGLY</i>. 
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import {apiV1Auth} from "../../../service/resources/api-v1-auth";
import { apiV1Subscription } from "../../../service/resources/api-v1-subscription";
import Config from "./Config.vue";
import SubscriptionAndPlans from "./SubscriptionAndPlans.vue";
  export default {
    name: 'BtnLogin',
    props: ['screenData', 'disableLoggout'],
    components: {
    Config,
    SubscriptionAndPlans
},
    data: () => ({
      loading: true,
      dialogConfig: false,
      dialogProfitWithUs: false,
      planData: {
        dialogPlansAndPrices: false,
        dialogSubscriptionPlan: false,
        planId: null
      },
      loadSubscriptionPlan: false,
      assign: null,
      dialogCancel: false,
      loadCancelSubscriptionPlan: false,
    }),
    mounted: function() {
      if(this.isShow()) {
        apiV1Auth.get().then(response => {
          this.screenData.auth = response.data
          this.loading = false

          if(localStorage.planSelectAuth) {
            this.planData.dialogPlansAndPrices = true
            this.planData.planId = localStorage.planSelectAuth
          }
        }).catch(error => {
          this.loading = false
          console.log(error)
          this.logout()
        })
      }
    },
    computed: {
      login: function() {
        return `${process.env.VUE_APP_API_V1_URL}/oauth2/authorization/google`
      },
    },
    methods: {
      isShow: function() {
        return localStorage.authorization
      },
      logout: function() {
        localStorage.clear()
        this.screenData.auth = null
        this.$router.push('/loading')
      },
      getSubscriptionPlan: function() {
        this.planData.dialogSubscriptionPlan = true
        this.loadSubscriptionPlan = true
        this.assign = null
        apiV1Subscription.get().then(result => {
          this.loadSubscriptionPlan = false
          this.assign = result.data
        }).catch(() => {
          this.loadSubscriptionPlan = false
        })
      },
      cancelSubscrible: function() {
        this.loadCancelSubscriptionPlan = true
        apiV1Subscription.delete().then(() => {
          this.loadSubscriptionPlan = true
          this.dialogCancel = false
          apiV1Subscription.get().then(result => {
            this.assign = result.data
            this.loadCancelSubscriptionPlan = false
            this.loadSubscriptionPlan = false
          })
        }).catch(() => {
          this.loadCancelSubscriptionPlan = false
        })
      }
    },
  }
</script>
<style scoped>
</style>