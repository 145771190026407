<template>
  <div v-if="data.address">
    <v-dialog persistent v-model="data.dialog" width="800" :fullscreen="['xs', 'sm'].includes($vuetify.breakpoint.name)">
      <v-card elevation="0" rounded>
        <v-card-title class="primary--text">
          <v-btn icon color="primary" @click="JSON.stringify(data.address.openGraph) == JSON.stringify(aux) ? data.dialog = false : confimCancel.dialog = true">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <b>{{$t(`previewLink`)}}</b>
          <v-spacer/>
          <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
        </v-card-title>
        <v-card-subtitle v-if="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3" class="mt-2">
          <v-icon>mdi-crown-outline</v-icon>
          {{$t('resourceOnlyPlan')}} <b>{{$t('plan_PRO')}}</b>            
        </v-card-subtitle>
        <v-card-text>
          <v-alert type="info">
            {{ $t('editAparenceLinkInfo') }}
          </v-alert>
        </v-card-text>
        <v-tabs full-width fixed-tabs v-model="tab" style="border-top: 1px solid #E7EBF2">
          <v-tabs-slider></v-tabs-slider>
          <v-tab :value="tab.path" style="text-transform:capitalize" v-for="tab, index in tabs" :key="index" :disabled="index > 0">
            <v-badge overlap right color="blue" :value="index > 0" :content="$t('comingSoon')">
              <div  v-bind="attrs" v-on="on">{{ $t(tab.name) }}</div>
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-card-text v-if="data.address.openGraph && data.dialog">
          <v-window v-model="tab">
            <v-window-item :value="index" v-for="tab, index in tabs" :key="index">
              <v-form v-model="formOG" :disabled="screenData.auth && screenData.auth.user.subscriptionPlan.id < 3">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-card elevation="0" outlined class="mt-2">
                      <v-img :src="data.address.openGraph.openGraphFields.filter(f => f.name == tab.ogFields[0] && f.value)[0] ? data.address.openGraph.openGraphFields.filter(f => f.name == tab.ogFields[0])[0].value : `/images/logo/logo.jpg`" min-height="60">
                        <iframe v-if="data.address.openGraph.openGraphFields.filter(f => f.name.includes('type') && f.value && f.value.includes('video'))[0]" width="100%" height="100%" 
                          :src="`${data.address.openGraph.openGraphFields.filter(f => f.name.includes('video') && f.name.includes('url'))[0].value}?si=clr9EZ-aZk53eNwN&amp;controls=0;modestbranding=1;autohide=1;showinfo=0;controls=0`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <v-footer absolute color="transparent" v-else>
                          <v-spacer/>
                          <v-avatar color="blue">
                            <v-file-input
                              small
                              class="ml-3"
                              @change="handlePicture($event, data.address.openGraph.openGraphFields.filter(f => f.name == tab.ogFields[0])[0])"
                              ref="file"
                              accept="image/png, image/jpeg, image/bmp"
                              dark
                              hide-input
                              hide-details
                              hide-actions
                              prepend-icon="mdi-camera"
                              icon
                              density="density"
                              rounded
                              flat
                              dense
                              />
                          </v-avatar>
                          <v-spacer/>
                        </v-footer>
                      </v-img>
                    </v-card>
                  </v-col>
                  <v-col  cols="12" sm="6" md="8">
                    <div v-for="(field, index) in tab.ogFields.filter(f => !f.includes('image'))" :key="`open-graph-field-${index}`" class="mt-2">
                      <v-select
                        v-if="field.includes('type')"
                        v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                        :items="[
                          {value: 'application', text: $t('application')},
                          {value: 'audio', text: $t('audio')},
                          {value: 'image', text: $t('image')},
                          {value: 'message', text: $t('message')},
                          {value: 'multipart', text: $t('multipart')},
                          {value: 'text', text: $t('text')},
                          {value: 'video.other', text: $t('video')},
                          {value: 'website', text: $t('website')},

                          {value: 'article', text: $t('article')},
                          {value: 'video.other', text: $t('video')},
                          {value: 'product', text: $t('product')},
                          {value: 'profile', text: $t('profile')},
                          {value: 'music', text: $t('music')},
                          {value: 'game', text: $t('game')},
                          // {value: '', text: $t('')},
                          // {value: '', text: $t('')},
                          // {value: '', text: $t('')},
                          // {value: '', text: $t('')},
                          // {value: '', text: $t('')},
                          // {value: '', text: $t('')},
                          // {value: '', text: $t('')},

                          // {value: '', text: $t('')},
                          // {value: '', text: $t('')},
                          // {value: '', text: $t('')},
                          // {value: '', text: $t('')},

// 'instapp:photo'
// 'nuvemshop:product'
// 'Book'
// 'site'
// 'video.movie'
// 'music.playlist'
// ''
// 'text'
// 'application'
// 'meli-br:product'
// 'music.song'
// 'multipart'
// 'activity'
// 'game'
// 'organization'

                          ]"
                        rounded
                        :label="$t(field)"
                        outlined
                        hide-details
                        hide-actions
                        dense
                        item-text="text"
                        item-value="value"
                        color="primary"
                      >
                        <template v-slot:prepend-inner>
                          <v-icon class="mr-1">
                            mdi-code-tags
                          </v-icon>
                        </template>
                      </v-select>
                      <v-textarea
                        v-else-if="field.includes('description')"
                        v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                        rounded
                        :label="$t(field)"
                        outlined
                        hide-details
                        hide-actions
                        dense
                        :rules="field.includes('url') ? [rules.url] : []"
                        color="primary"
                        counter
                        maxlength="300"
                        rows="3"
                        no-resize
                        >
                        <template v-slot:prepend-inner>
                          <v-icon class="mr-1">
                            mdi-code-tags
                          </v-icon>
                        </template>
                        <template v-slot:append>
                        </template>
                      </v-textarea>
                      <v-text-field 
                        v-else-if="data.address.openGraph.openGraphFields.filter(f => f.name.includes('type') && f.value && f.value.includes('video'))[0] || !field.includes('video')"
                        v-model="data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value"
                        rounded
                        :label="$t(field)"
                        outlined
                        hide-details
                        hide-actions
                        dense
                        :rules="field.includes('url') ? [rules.url] : []"
                        color="primary"
                        counter
                        maxlength="200"
                        @change="field.includes('video') && field.includes('url') ? !data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value.includes('https://www.youtube.com/embed/') ? 
                        data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value = 'https://www.youtube.com/embed/' + (data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value.split('/')[data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value.split('/').length -1]).replace('watch?v=', '') 
                        :
                        data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value = 'https://www.youtube.com/embed/' + data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value.split('/')[[data.address.openGraph.openGraphFields.filter(f => f.name == field)[0].value.split('/').length -1]] 
                        : null"
                        >
                        <template v-slot:prepend-inner>
                          <div v-if="field.includes('video') && field.includes('url')">
                            <v-card-actions class="ma-0 pa-0">
                              <v-icon class="mr-1">
                                mdi-youtube
                              </v-icon>
                            </v-card-actions>
                          </div>
                          <div v-else>
                            <v-icon class="mr-1">
                              mdi-code-tags
                            </v-icon>
                          </div>
                        </template>
                        <template v-slot:append v-if="field.includes('video') && field.includes('url')">
                          <v-tooltip bottom max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                dark
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-information</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              {{ $t('copyYoutubeUrl') }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-divider vertical/>
                </v-row>
              </v-form>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn rounded :disabled="(screenData.auth && screenData.auth.user.subscriptionPlan.id < 3) || !formOG" color="primary" @click="saveOG" :loading="loadingSave">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cropImageDialog" width="400">
      <v-card>
        <SlimCropper ref="cropperImgOpenGraph" :src="imageCrop" width="100%" :cropperOptions="{background: false}" :aspectRatio="1">
        </SlimCropper>
        <v-footer absolute>
          <v-spacer/>
          <v-btn color="primary" @click="cropImage">
            {{ $t('save') }}
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>
    <ConfirmOperation :data="confimCancel" :confimAction="() => {data.dialog = false, data.address.openGraph = aux}" />
  </div>
</template>
<script>
import { apiV1OpenGraph } from '../../../service/resources/api-v1-open-graph';
import { apiV1OpenGraphFields } from '../../../service/resources/api-v1-open-graph-fields';
  import ConfirmOperation from './ConfirmOperation.vue';
  import IconInfoSubscriblePlan from './IconInfoSubscriblePlan.vue'
  import SubscriptionAndPlans from './SubscriptionAndPlans.vue';

  export default {
    name: 'OpenGraphEdit',
    props: ['data', 'screenData'],
    components: {
    IconInfoSubscriblePlan,
    SubscriptionAndPlans,
        ConfirmOperation
},
    data: () => ({
      confimCancel: {dialog: false},
      planData: {
        dialogPlansAndPrices: false
      },
      rules: {url:false},
      tabs: [ 
        {name: 'default', ogFields: ['og:image', 'og:type', 'og:title', 'og:url', 'og:site_name', 'og:description', 'og:video:url']},
        {name: 'facebook', ogFields: []},
        {name: 'pinterest', ogFields: []}
      ],
      tab: 0,
      loadingSave: false,
      formOG: false,
      cropImageDialog: false,
      imageCrop: null,
      aux: null
    }),
    watch: {
      'data.dialog': function(value) {
        if(value) {
          this.prepare()
          this.aux = JSON.parse(JSON.stringify(this.data.address.openGraph))
        }
      },
    },
    mounted: function() {
      this.rules = {
        url: value => {
          return (this.isUrl(value)) || this.$t('invalidUrl')
        }
      }
    },
    computed: {
    },
    methods: {
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        // const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        return pattern.test(value)
      },
      prepare: function() {
        if(!this.data.address.openGraph)
          this.data.address.openGraph = {openGraphFields: []}
        if(!this.data.address.openGraph.openGraphFields)
          this.data.address.openGraph.openGraphFields = []
        this.data.address.openGraph.onlineAddress = {id: this.data.address.id}
        this.tabs.forEach(tab => {
          tab.ogFields.forEach(og => {
            if(!this.data.address.openGraph.openGraphFields.filter(f => f.name == og)[0])
              this.data.address.openGraph.openGraphFields.push({name: og, value: ''})
          })
        })
      },
      saveOG : function() {
        this.loadingSave = true
        apiV1OpenGraph.post(this.data.address.openGraph).then(result => {
          this.data.address.openGraph.id = result.data.id
          //this.prepare()
          this.data.address.openGraph.openGraphFields.filter(f => f.value).forEach((f, index) => {
            f.openGraph = {id: result.data.id}
            apiV1OpenGraphFields.post(f).then(result => {
              f.id = result.data.id
              if(index >= this.data.address.openGraph.openGraphFields.filter(f => f.value).length -1) {
                this.loadingSave = false
                this.data.dialog = false
                this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
                this.$forceUpdate()
              }
            })
          }).catch(() => {
            this.loadingSave = false
          })
        }).catch(() => {
          this.loadingSave = false
        })
      },
      handlePicture: function(event) {
        var reader = new FileReader()
        reader.readAsDataURL(event)
        let self = this
        if(reader.size > 2000000) {
          this.$commons.updateSnackbar({show: true, color: 'error', text: this.$t('maxFileSizeOG')})
          return
        }
        reader.onload = function () {
          self.imageCrop = reader.result
          self.cropImageDialog = true
          // setTimeout(() => {
          //   self.cropImage(field)
          // }, 500);
        }
      },
      async cropImage() {
        console.log(this.$refs.cropperImgOpenGraph)
        const blob = await this.$refs.cropperImgOpenGraph.getCroppedBlob('image/png', 1)
        var reader = new FileReader();
        reader.readAsDataURL(blob); 
        let self = this
        reader.onloadend = function() {
          self.data.address.openGraph.openGraphFields.filter(f => f.name == self.tabs[self.tab].ogFields[0])[0].value = reader.result;  
          console.log('actual base64');
          console.log(blob);              
          console.log('-------------------');
          self.cropImageDialog = false
        }
      },
    },
  }
</script>
<style scoped>
</style>