<template>
  <v-menu offset-y left :close-on-content-click="false" v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        color="dark"
        v-bind="attrs"
        v-on="on"
        :fab="fab"
        :icon="icon"
        class="px-1 pa-1"
        elevation="4"
      >
        <v-icon :color="data[attribute]">mdi-palette</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-row>
          <v-col>
            <v-card-title class="primary--text pl-0 pr-0">
              <v-btn icon color="primary" @click="menu = false">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              {{ title }}
              <v-spacer/>
            </v-card-title>
            <v-card outlined elevation="0">
              <v-color-picker
                dot-size="25"
                show-swatches
                swatches-max-height="200"
                mode="hexa"
                :value="data[attribute]"
                @update:color="v =>  data[attribute] = v.hexa"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>

  export default {
    name: 'ColorMenu',
    props: ['data', 'attribute', 'title', 'fab', 'icon'],
    components: {
},
    data: () => ({
      menu: false
    }),
    watch: {
    },
    mounted: function() {
    },
    computed: {
    },
    methods: {
    }
  }
</script>
<style scoped>
</style>