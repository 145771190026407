<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2063436339360861" crossorigin="anonymous">
</script>
<template>
  <v-card elevation="0" color="transparent">
    <v-card-title class="primary--text mr-0 pr-0 ml-0 pl-0">
      <v-badge overlap bordered right color="blue" :value="Object.keys(filterApply).map((key) => filterApply[key]).filter(v => Array.isArray(v) ? v.length :  v).length" :content="Object.keys(filterApply).map((key) => filterApply[key]).filter(v => Array.isArray(v) ? v.length :  v).length">
        <v-btn
          color="primary"
          outlined
          @click="filterBottomSheet = true"
          class="rounded-lg text-none"
        >
          <v-icon>mdi-filter-variant</v-icon>
          <label>{{ $t('filter') }}</label>
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-badge>
      <v-spacer/>
      <v-badge overlap right bordered color="blue" :value="!screenData.auth.user.config" content="!" :class="!screenData.auth.user.config ? 'mr-3' : ''" v-if="false">
        <v-btn icon color="primary" @click="dialogConfig = true">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </v-badge>
    </v-card-title>
    <v-card-actions class="pl-0 pr-0" v-if="!loading || onlineAddressPage.totalElements">
      <small><b>{{onlineAddressPage.totalElements}} {{ $t('results') }}</b></small>
      <v-spacer/>
      <v-badge overlap bordered left color="primary" :value="Object.keys(orderBy).map((key) => orderBy[key]).filter(v => Array.isArray(v) ? v.length :  v).length" :content="Object.keys(orderBy).map((key) => orderBy[key]).filter(v => Array.isArray(v) ? v.length :  v).length">
        <v-menu offset-y right :open-on-hover="false" :close-on-content-click="true" rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              outlined
              dark
              class="rounded-lg text-none"
               v-bind="attrs" v-on="on"
            >
              {{ $t('orderBy') }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list v-for="item in [
              {id: 'dateCreateOrderBy', propertie: 'dateCreate'},
              {id: 'clicksOrderBy', propertie: 'clicks'}
            ]"
            :key="item.id"
            class="mb-0 pb-0 mt-0 pt-0"
            >
            <v-list-item-group
              v-model="orderBy[item.propertie]"
              color="primary"
            >
              <v-list-item
                v-for="order in ['ASC', 'DESC']" :key="order"
                link
                cli
                :value="`${item.propertie}:${order}`"
              >
                <v-list-item-title>
                  {{ $t(`${item.id}${order}`) }}
                </v-list-item-title>
                <v-list-item-icon v-if="orderByVector.indexOf(`${item.propertie}:${order}`) > -1">
                  {{orderByVector.indexOf(`${item.propertie}:${order}`) + 1}}º
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
            <v-divider/>
          </v-list>
        </v-menu>
      </v-badge>
    </v-card-actions>
    <v-card-actions class="pr-4 pl-4" v-else>
      <v-skeleton-loader type="chip"/>
      <v-spacer/>
      <v-skeleton-loader type="chip" class="mr-1"/>
      <v-skeleton-loader type="chip"/>
    </v-card-actions>
    <div>
      <v-slide-x-transition>
        <v-row v-if="loading">
          <v-col cols="12"
            sm="12"
            md="12"
            lg="12" v-for="i in itemsPerPage" :key="`loader-data-table-${i}`">
            <v-skeleton-loader
              type="article, actions"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          
          <v-col
            v-for="item in onlineAddressPage.content"
            :key="item.id"
            :cols="12"
            sm="12"
            md="12"
            lg="12"
          >
            <v-card v-if="item.ads" elevation="2" class="card-corner-radius" style="margin-left: 1px; margin-right: 1px;">
              <!-- <a target="_blank" href="https://www.amazon.com.br/b?_encoding=UTF8&tag=exatamente0c-20&linkCode=ur2&linkId=cdd8b91d887651538d55c1cccabfe95d&camp=1789&creative=9325&node=21579999011">
                <v-img 
                class="card-corner-radius"
                src="https://m.media-amazon.com/images/I/61UJi1lnupL._SX3000_.jpg" 
                >
                  <v-footer absolute color="transparent">
                    <v-col cols="4">
                      <v-card>
                        <v-img src="https://images-na.ssl-images-amazon.com/images/G/32/kindle/journeys/PUx_1hPmoyVDyBdI/M2QxNjk5ZmYt-w372._SY232_CB587518891_.jpg" />
                      </v-card>
                    </v-col>
                    <v-col cols="4">
                      <v-card>
                        <v-img src="https://images-na.ssl-images-amazon.com/images/G/32/kindle/journeys/PUx_1hPmoyVDyBdI/Y2Q3ZjA5MWMt-w186._SY116_CB587518891_.jpg" />
                      </v-card>
                    </v-col>
                    <v-col cols="4">
                      <v-card>
                        <v-img src="https://images-na.ssl-images-amazon.com/images/G/32/kindle/journeys/PUx_1hPmoyVDyBdI/ZDgzMGJhM2Et-w372._SY232_CB587518891_.jpg" />
                      </v-card>
                    </v-col>
                  </v-footer>
                </v-img>
              </a> -->
              <a class="link" target="_blank" href="https://shope.ee/6KhFO4RZzS">
                <v-card>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img src="https://i.pinimg.com/474x/90/b2/65/90b26573e0f822d9bb987d05ce396cbc.jpg"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-card-title class="primary--text">
                        {{ $t('yourCompanyOrProductHere') }}
                      </v-card-title>
                      <v-card-subtitle>
                        {{ $t('yourCompanyOrProductHereText') }}
                      </v-card-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </a>
            </v-card>
            <v-card v-else :color="onlineAddress == item || (onlineAddress && onlineAddress.id == item.id) ? 'primary' : ''" class="card-corner-radius" style="padding: 1px; margin: 1px;" elevation="1">
              <v-card class="card-corner-radius" light elevation="0">
                <v-list-item three-line >
                  <v-list-item-content>
                    <v-list-item three-line class="pa-0" @click="onlineAddress = null, select(item), screenData.dashboard = true">
                      <v-list-item-avatar
                        :tile="!item.biography"
                        :size="['xs', 'sm'].includes($vuetify.breakpoint.name) ? 50 : 80"
                      >
                        <template v-if="item.biography">
                          <v-img lazy-src="/images/logo/logo-02.png" :src="item.biography.picture && item.biography.picture.src ? item.biography.picture.src : null"/>
                        </template>
                        <template v-else-if="item.openGraph && item.openGraph.openGraphFields && item.openGraph.openGraphFields.filter(f => f.name == ('og:image'))[0]">
                          <v-img lazy-src="/images/logo/logo-02.png" v-for="(field) in item.openGraph.openGraphFields.filter(f => f.name == ('og:image'))" :key="field.id" :src="field.value" position="center center" contain style="border-radius: 10px 0px 0px 10px;" aspect-ratio="1"/>
                        </template>
                        <template v-else-if="item.infoproduct">
                          <v-img lazy-src="/images/logo/logo-02.png" :src="item.infoproduct.brandImage ? item.infoproduct.brandImage.src : null" position="center center" contain style="border-radius: 10px 0px 0px 10px;" aspect-ratio="1"/>
                        </template>
                        <v-img v-else src="/images/logo/logo-02.png" contain/>
                      </v-list-item-avatar>
                      <v-list-item-content
                        class="pointer mb-0 pb-0 mt-0 pt-0">
                        <div class="text-overline mb-0 pb-0">
                          <template v-if="item.biography">
                            {{ $t('bio') }} <v-icon x-small>mdi-circle-medium</v-icon> {{ item.code }}
                          </template>
                          <template v-else-if="item.openGraph && item.openGraph.openGraphFields && item.openGraph.openGraphFields.filter(f => f.name == ('og:site_name') && f.value.trim())[0]">
                            <div v-for="(field) in item.openGraph.openGraphFields.filter(f => f.name == ('og:site_name'))" :key="field.id">{{field.value}}</div>
                          </template>
                          <template v-else-if="item.infoproduct">
                            {{ $t('infoproduct') }}  <v-icon x-small>mdi-circle-medium</v-icon> {{ item.infoproduct.title }}
                          </template>
                          <div v-else>
                            {{item.url.replace('http://', '').replace('https://', '').substring(0, item.url.replace('http://', '').replace('https://', '').indexOf('/') > 0 ? item.url.replace('http://', '').replace('https://', '').indexOf('/') : item.url.replace('http://', '').replace('https://', '').length)}}
                          </div>
                        </div>
                        <v-list-item-subtitle class="mb-0 pb-0" style="white-space: inherit;" v-if="item.infoproduct || item.biography || item.openGraph && item.openGraph.openGraphFields">
                          <template v-if="item.biography">
                              <b>{{ item.biography.name }}</b>
                          </template>
                          <div v-else-if="item.openGraph && item.openGraph.openGraphFields && item.openGraph.openGraphFields.filter(f => f.name == ('og:title') && f.value.trim())[0]">
                            <b><p v-for="(field) in item.openGraph.openGraphFields.filter(f => f.name == ('og:title'))" :key="field.id">{{field.value}}</p></b>
                          </div>
                          <div v-else-if="item.infoproduct">
                            <b><p>{{ item.infoproduct.subtitle }}</p></b>
                          </div>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <template v-if="item.biography">
                            {{ item.biography.description }}
                          </template>
                          <div v-else-if="item.openGraph && item.openGraph.openGraphFields && item.openGraph.openGraphFields.filter(f => f.name == ('og:description' && f.value.trim()))[0]">
                            <p v-for="(field) in item.openGraph.openGraphFields.filter(f => f.name == ('og:description'))" :key="field.id">{{field.value}}</p>
                          </div>
                          <div v-else-if="item.infoproduct">
                            <p>{{item.infoproduct.shortDescription}}</p>
                          </div>
                          <div v-else>
                            {{ item.url }}
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-card-text class="pa-0">
                      <v-chip color="transparent" class="primary--text pl-0">
                        <input :id="`link-${item.id}`"  :value="`${host}/${item.code}`" class="primary--text" readonly style="min-width: 200px;"/>
                        <v-icon color="primary" class="ml-1" @click="copyLink(item)">
                          mdi-content-copy
                        </v-icon>
                        <v-slide-x-transition>
                          <v-icon color="green" class="ml-1" v-if="item.copy">
                            mdi-check-circle
                          </v-icon>
                        </v-slide-x-transition>
                      </v-chip>
                      <div style="max-height: 80px; overflow-x: auto;" v-if="item.tags">
                        <v-chip-group class="mt-0 pt-0 mb-0 pb-0" column  content-class="mt-0 pt-0">
                          <template v-if="item.tags"> 
                            <v-chip class="mr-1 mb-1" v-for="(tag, index) in item.tags.split(`||`)" :key="index" v-show="tag" small>
                              <v-icon x-small class="mr-1">mdi-tag</v-icon>
                              {{tag}}
                            </v-chip>
                          </template>
                        </v-chip-group>
                      </div>
                    </v-card-text>
                  </v-list-item-content>
                  <v-divider vertical class="mt-4 mb-4"/>
                  <v-list-item-action class="pl-0 ml-1" style="margin: auto;">
                    <a :href="`${host}/${item.code}`" target="_blank" style="text-decoration: none;" v-if="false">
                      <v-btn color="primary" small icon>
                        <v-icon>mdi-navigation-variant</v-icon>
                      </v-btn>
                    </a>
                    <v-badge overlap right bordered :color="item.error ? 'warning' : !item.openGraph || !item.openGraph.openGraphFields ? 'blue': ''" :content="item.error ? '!' : !item.openGraph || !item.openGraph.openGraphFields ? '!': false" :value="item.error || !item.openGraph || !item.openGraph.openGraphFields ? '!' : false">
                      <v-menu offset-y right :open-on-hover="false" :close-on-content-click="true">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" v-bind="attrs" v-on="on" small icon>
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <v-card width="300">
                          <v-card-subtitle class="mb-0 pb-0">
                            <v-badge overlap left color="blue" :content="!item.openGraph || !item.openGraph.openGraphFields ? '!': false" :value="!item.openGraph || !item.openGraph.openGraphFields ? '!' : false">
                              <v-icon small color="primary" class="mb-1">mdi-octagram-edit-outline</v-icon>
                            </v-badge>
                            <a @click="openGraphEditData.dialog = true,openGraphEditData.address = item">
                              {{ $t('editAparenceLink') }}
                            </a>
                            <v-spacer/>
                          </v-card-subtitle>
                          <v-card-subtitle class="mb-0 pb-0" v-if="item.type != 'INFOPRODUCT'">
                            <v-icon small color="primary" class="mb-1">mdi-pencil</v-icon>
                            <a v-if="item.type == 'BIO'" @click="$router.push('/painel/links/page-bio')">
                              {{ $t('editLink') }}
                            </a>
                            <a v-else @click="LinkEditData.dialog = true, LinkEditData.address = item">
                              {{ $t('editCodeAndDirectionLink') }}
                            </a>
                            <v-spacer/>
                          </v-card-subtitle>
                          <v-card-subtitle v-if="item.type != 'INFOPRODUCT'">
                            <v-icon small color="primary" class="mb-1">mdi-tag-multiple</v-icon>
                            <a @click="LinkEditTagsData.dialog = true, LinkEditTagsData.address = item">
                              {{ $t('editLinkTags') }}
                            </a>
                            <v-spacer/>
                          </v-card-subtitle>
                          <v-divider v-if="item.error"/>
                          <v-card-text v-if="item.error" @click="LinkEditData.dialog = true, LinkEditData.address = item" class="pointer warning--text">
                            <v-icon color="warning" small class="mr-1">mdi-alert-circle-outline</v-icon>
                            {{ $t('linkErrorTitle') }}
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-badge>
                    <ShareOptions :onlineAddress="item"/>
                    <v-btn color="primary" small icon @click="open(item)">
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card-actions class="pl-0 pr-0">
              <v-chip-group>
                <v-btn x-small class="mr-1 primary--text" :disabled="(p -1) == onlineAddressPage.number" color="white" rounded light v-for="p in onlineAddressPage.totalPages" :key="p" @click="page = p -1, $router.replace({query: Object.assign({ ...$route.query }, { page: p -1 }),
                    },
                    () => {}
                  ),search(page)">
                  <v-icon x-small v-if="(p -1) == onlineAddressPage.number">mdi-circle-medium</v-icon>
                  {{$t('page')}} {{p}}
                </v-btn>
              </v-chip-group>
              <v-spacer/>
              <v-btn icon color="primary" :disabled="onlineAddressPage.first" @click="search(onlineAddressPage.number -1)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon color="primary" :disabled="onlineAddressPage.last" @click="search(onlineAddressPage.number +1)">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4">
            <ins class="adsbygoogle"
                style="display:block"
                data-ad-format="fluid"
                data-ad-layout-key="-6t+ed+2i-1n-4w"
                data-ad-client="ca-pub-2063436339360861"
                data-ad-slot="6524487030"></ins>
          </v-col>
        </v-row>
      </v-slide-x-transition>
    </div>
    <v-dialog v-model="dialogQrcode" width="300">
      <QRCodeMigly v-if="selectQrcodeItem" :value="`${host}/${selectQrcodeItem.code}`" :enableControlls="true"/>
    </v-dialog>
    <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <OpenGraphEdit :data="openGraphEditData" :screenData="screenData"/>
    <LinkEdit :data="LinkEditData" :screenData="screenData"/>
    <LinkEditTags :data="LinkEditTagsData" :screenData="screenData"/>
    <v-dialog v-model="dialogConfig" width="800" persistent :fullscreen="['xs'].includes($vuetify.breakpoint.name)">
      <v-card>
        <v-card-actions>
          <v-btn @click="dialogConfig = false" icon><v-icon color="primary">mdi-arrow-left</v-icon></v-btn>

          <v-spacer/>
          <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
        </v-card-actions>
        <Config :screenData="screenData"/>
      </v-card>
    </v-dialog>
    <v-snackbar
      top
      right
      v-model="snackbar.show"
      close-delay="2000"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          {{$t('close')}}
        </v-btn>
      </template>
    </v-snackbar>
    <v-bottom-sheet v-model="filterBottomSheet" inset>
      <v-sheet>
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('filters') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="filterBottomSheet = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="mr-4 ml-4"/>

        <v-card elevation="0" max-height="50vh" class="overflow-auto">
          <v-card-text>
            <v-chip-group show-arrows column>
              <v-btn class="ml-1 mb-1 text-lowercase" :color="d.domain != filter.domain ? '#7E8EA6' : 'primary'" rounded :outlined="d.domain != filter.domain" dark v-for="d in domainsPage.content" :key="d.domain" @click="d.domain != filter.domain ? filter.domain = d.domain : filter.domain = null, filterUpdate()" small>
                {{d.domain.replace('https://', '').replace('http://', '')}} ({{ d.total }})
              </v-btn>
              <v-btn v-if="!domainsPage.last" color="#7E8EA6" icon rounded small class="ml-1 mb-1" @click="findDomains(domainsPage.number + 1, true)" :loading="loadingDomains">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-chip-group>
          </v-card-text>
          <v-card-subtitle><b>{{ $t('situation') }}</b></v-card-subtitle>
          <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('situationDesc') }}</v-card-subtitle>
          <v-card-text>
            <v-checkbox
              v-for="item in [{id : true, text: 'irregular'}, {id : false, text: 'regular'}, {id : null, text: 'notVerified'}]" :key="item.id"
              v-model="filter.errorIn"
              :label="$t(item.text)"
              :value="item.id"
              flat
              dense
              @change="filterUpdate()"
            ></v-checkbox>
          </v-card-text>
          <v-card-subtitle><b>{{ $t('addressType') }}</b></v-card-subtitle>
          <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('addressTypeDesc') }}</v-card-subtitle>
          <v-card-text>
            <div v-for="item in [
                  {id : 'API', text: 'API'}, 
                  {id : 'CHROME_EXTENSION', text: 'CHROME_EXTENSION'}, 
                  {id : 'STANDARD', text: 'STANDARD'},
                  {id : 'BIO', text: 'BIO'},
                  {id : 'SMS', text: 'SMS'},
                  {id : 'INFOPRODUCT', text: 'INFOPRODUCT'},
              ]" :key="item.id">
                <v-checkbox
                  v-model="filter.typeIn"
                  :label="$t('addressType_'+item.text).toUpperCase()"
                  :value="item.id"
                  flat
                  dense
                  hide-details
                  @change="filterUpdate()"
                ></v-checkbox>
              <label class="description--text">
                {{ $t('addressTypeDesc_'+item.text) }}
              </label>
            </div>
          </v-card-text>
          <v-card-subtitle><b>{{ $t('creationDate') }}</b></v-card-subtitle>
          <v-card-subtitle class="description--text mt-0 pt-0">{{ $t('creationDateDesc') }}</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-menu
                  ref="startAt"
                  v-model="menuStartAt"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  left
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="filter.dateCreateIni ? new Date(`${filter.dateCreateIni}T00:00:00`).toLocaleDateString() : null"
                      :label="$t('startAt')"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      rounded
                      flat
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filter.dateCreateIni"
                    :active-picker.sync="activePickerStart"
                    :max="filter.dateCreateEnd ? filter.dateCreateEnd : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="menuStartAt = false,filterUpdate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu
                  ref="endAt"
                  v-model="menuEndAt"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  left
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="filter.dateCreateEnd ? new Date(`${filter.dateCreateEnd}T23:59:59`).toLocaleDateString() : null"
                      :label="$t('endAt')"
                      append-icon="mdi-calendar-end"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      rounded
                      flat
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="filter.dateCreateEnd"
                    :active-picker.sync="activePickerEnd"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    :min="filter.dateCreateIni"
                    @change="menuEndAt = false,filterUpdate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <v-card-actions>
            <v-spacer/>
            <v-btn outlined color="white">
            </v-btn>
            <v-spacer/>
          </v-card-actions> -->
          <v-footer color="transparent">
            <!-- <v-container>
              <v-card color="white" elevation="0"> -->
                <!-- <v-card-actions> -->
                  <v-btn outlined color="primary" @click="clearFilter()" width="40%" class="rounded-lg text-none">
                    {{ $t('clear') }}
                  </v-btn>
                  <v-spacer/>
                  <v-btn color="primary" @click="applyFilter()" width="55%" class="rounded-lg text-none" :loading="loadingFilterPreviewResults">
                    {{ $t('apply') }}
                    <label class="ml-4">
                      <small>({{ pageFilterPreviewResults.totalElements }} {{ !['xs', 'sm'].includes($vuetify.breakpoint.name) ? $t('results') : '' }})</small>
                    </label>
                  </v-btn>
                <!-- </v-card-actions>
              </v-card>
            </v-container> -->
          </v-footer>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </v-card>
</template>
<script>
    (adsbygoogle = window.adsbygoogle || []).push({});
</script>
<script>
import {apiV1OnlineAddresses} from "../../../service/resources/api-v1-online-addresses";
import {apiV1OpenGraph} from "../../../service/resources/api-v1-open-graph";
import BiographySmartphoneView from "./BiographySmartphoneView.vue";
import QRCodeMigly from "./QRCodeMigly.vue";
import IconInfoSubscriblePlan from './IconInfoSubscriblePlan.vue';
import SubscriptionAndPlans from './SubscriptionAndPlans.vue';
import ShareOptions from './ShareOptions.vue'
import OpenGraphEdit from './OpenGraphEdit.vue'
import LinkEdit from './LinkEdit.vue';
import LinkEditTags from './LinkEditTags.vue';
import Config from './Config.vue';
  export default {
    name: 'UrlShortener',
    props: ['screenData'],
    components: {
      BiographySmartphoneView,
      QRCodeMigly,
      IconInfoSubscriblePlan,
      SubscriptionAndPlans,
      ShareOptions,
      apiV1OpenGraph,
      OpenGraphEdit,
      LinkEdit,
      LinkEditTags,
      Config,
    },
    data: () => ({
      dialogConfig: false,
      onlineAddress: null,
      onlineAddressPage: {
        content: [],
      },
      domainsPage: {
        content: [],
      },
      openGraphEditData: {dialog: false, address: null},
      LinkEditData: {dialog: false, address: null},
      LinkEditTagsData: {dialog: false, address: null},
      planData: {
        dialogPlansAndPrices: false
      },
      requestNumber: 0,
      dialogQrcode: false,
      selectQrcodeItem: null,
      dialogDelete: false,
      dialogFilter: false,
      snackbar: {
        show: false,
        color: 'primary',
        text: 'asdasd'
      },
      dialogEdit: false,
      host: null,
      loading: false,
      loadingSave: false,
      loadingDelete: false,
      itemsPerPageArray: [10, 50, 100],
      text: '',
      filter: {
        domain: null,
        errorIn: [],
        typeIn: [],
        dateCreateIni: null,
        dateCreateEnd: null,
      },
      orderBy: {
      },
      sortDesc: false,
      itemsPerPage: 10,
      page: 0,
      error: null,
      domain: null,
      loadingDomains: false,
      menuStartAt: false,
      menuEndAt: false,
      activePickerStart: null,
      activePickerEnd: null,
      filterBottomSheet: false,
      loadingFilterPreviewResults: true,
      pageFilterPreviewResults: {
        totalElements: 0
      },
      filterApply: {},
      orderByVector: []
    }),
    mounted: function() {
      this.setFilter()
      this.host = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;
      this.filterApply = JSON.parse(JSON.stringify(this.filter))
      this.search(this.page)
      this.filterUpdate()
      this.findDomains(0)
    },
    computed: {
    },
    watch: {
      '$route.query.errorIn': function() {
        this.updateQueryFilter()
      },
      '$route.query.text': function() {
        this.updateQueryFilter()
      },
      // '$route.query.text': function() {
      //   this.updateQueryFilter()
      // },
      '$route.path': function() {
      },
      'dialogEdit': function() {
        this.onlineAddress = structuredClone(this.onlineAddress);
      },
      'orderBy.clicks' : function(value) {
        if(value)
          this.addOrderBy(value, true)
        else
          this.addOrderBy('clicks:ASC', false)
      },
      'orderBy.dateCreate' : function(value) {
        if(value)
          this.addOrderBy(value, true)
        else
          this.addOrderBy('dateCreate:ASC', false)
      }
    },
    methods: {
      addOrderBy: function(value, add) {
        this.orderByVector = this.orderByVector.filter(v => v.split(':')[0] != value.split(':')[0])
        if(add)
          this.orderByVector.push(value)
        this.search(this.page)
      },
      updateQueryFilter: function() {
        this.setFilter()
        this.search(this.page)
      },
      setFilter: function() {
        if(this.$route.query.page)
          this.page = this.$route.query.page
        if(this.$route.query.text)
          this.filter.text = this.$route.query.text
        if(this.$route.query.domain)
          this.filter.domain = this.$route.query.domain
        if(this.$route.query.errorIn)
          this.filter.errorIn = Array.isArray(this.$route.query.errorIn) ? this.$route.query.errorIn.map(v => v == 'true') : [this.$route.query.errorIn].map(v => v == 'true')
        if(this.$route.query.typeIn)
          this.filter.typeIn = Array.isArray(this.$route.query.typeIn) ? this.$route.query.typeIn : [this.$route.query.typeIn]
        if(this.$route.query.dateCreateIni)
          this.filter.dateCreateIni = this.$route.query.dateCreateIni
        if(this.$route.query.dateCreateEnd)
          this.filter.dateCreateEnd = this.$route.query.dateCreateEnd
        if(this.$route.query.orderBy) {
          this.orderBy = JSON.parse(this.$route.query.orderBy)
          if(this.orderBy.dateCreate && !this.orderByVector.includes(this.orderBy.dateCreate)){
            this.orderByVector.push(this.orderBy.dateCreate)
          }
          if(this.orderBy.clicks && !this.orderByVector.includes(this.orderBy.clicks)){
            this.orderByVector.push(this.orderBy.clicks)
          }
        }
      },
      findDomains: function(page, add){
        this.loadingDomains = true
        apiV1OnlineAddresses.pageDomain({page: page, size: 5, orderBy: 'total:desc'}).then(response => {
          if(add) {
             let merged = [...this.domainsPage.content, ...response.data.content.filter(item => this.domainsPage.content.map(v => v.domain).indexOf(item.domain) < 0)]
             this.domainsPage = response.data
             this.domainsPage.content = merged
          }else {
            this.domainsPage = response.data
          }
          this.loadingDomains = false
        })
      },
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        // const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        return pattern.test(value)
      },
      open: function(item){
        const routeData = this.$router.resolve({ path: `/${item.code}`, query:  {countClick: false}});
        window.open(routeData.href, '_blank');
      },
      select: function(item) {
        if(this.onlineAddress != item) {
          this.onlineAddress = item
        } else {
          this.onlineAddress = null
        }
        // this.$route.params.id = item.id
        this.$router.replace({path: `/painel/links/shortened/${item.id}`, query: this.$route.query})
      },
      copyLink: function (item) {
        let element = document.querySelector('#link-'+ item.id);
        element.setAttribute('type', 'text') 
        element.select()
        document.execCommand('copy');
        console.log(element)
        item.copy = true
        this.$forceUpdate()
        setTimeout(() => {
          item.copy = false
          this.$forceUpdate()
        }, 3000);
      },
      clearFilter: function(){
        this.filter = {
          domain: null,
          errorIn: [],
          typeIn: [],
          dateCreateIni: null,
          dateCreateEnd: null,
        }
        this.filterUpdate()
      },
      applyFilter: function(){
        this.filterBottomSheet = false
        this.filterApply = JSON.parse(JSON.stringify(this.filter))
        this.page = 0
        this.$router.push({ query: { 
          page: this.page, 
          domain: this.filter.domain, 
          errorIn: this.filter.errorIn, 
          typeIn: this.filter.typeIn,
          dateCreateIni: this.filter.dateCreateIni, 
          dateCreateEnd: this.filter.dateCreateEnd,
          orderBy: JSON.stringify(this.orderBy)
        }})
        this.search(this.page)
      },
      search: function(page) {
        let request = this.requestNumber
        this.loading = true
        apiV1OnlineAddresses.page({page: page, size: this.itemsPerPage, text: this.filter.text,errorIn:this.filter.errorIn,  
          typeIn: this.filter.typeIn, 
          dateCreateIni: this.filter.dateCreateIni? `${new Date(`${this.filter.dateCreateIni}T00:00:00`).toISOString()}`  : null, 
          dateCreateEnd: this.filter.dateCreateEnd ? `${new Date(`${this.filter.dateCreateEnd}T23:59:59`).toISOString()}`  : null, 
          embedBiography: true, 
          orderBy:  this.orderByVector,
          error: this.filter.error, domain: this.filter.domain}).then(response => {
          if(request == this.requestNumber) {
            this.onlineAddressPage = response.data
            this.onlineAddressPage.content.forEach(i => {
              i.showUrl = false;
            })
            if(this.$route.params.id)
            this.onlineAddressPage.content.filter(element => element.id == this.$route.params.id).forEach(element => {
              this.onlineAddress = element
            });
            if(this.screenData.auth && this.screenData.auth.user.subscriptionPlan.free)
              this.onlineAddressPage.content.splice(Math.floor(Math.random() * (this.onlineAddressPage.content.length - 0 + 1) + 0), 0, {ads: true, id: 0})
          }
          this.loading = false
          this.requestNumber++
        })
      },
      filterUpdate : function() {
        this.loadingFilterPreviewResults = true
        apiV1OnlineAddresses.page({
          page: 0,
          size: 1,
          domain: this.filter.domain,
          errorIn:this.filter.errorIn,  
          typeIn: this.filter.typeIn, 
          dateCreateIni: this.filter.dateCreateIni? `${new Date(`${this.filter.dateCreateIni}T00:00:00`).toISOString()}`  : null, 
          dateCreateEnd: this.filter.dateCreateEnd ? `${new Date(`${this.filter.dateCreateEnd}T23:59:59`).toISOString()}`  : null, 
          embedBiography: false, 
        }).then(response => {
          this.loadingFilterPreviewResults = false
          this.pageFilterPreviewResults = response.data
        })
      }
    },
  }
</script>
<style scoped>
.height-100{
  height: 100%;
}

.item-verticle-center {
  min-height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
}
.item-horizontaly-center {
  margin: auto;
}
</style>