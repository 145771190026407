import http from "../axios";

let apiV1ClickDashboard = {
	baseUrl: '/api/v1/click-dashboards',
	get: async (requestParams) => {
		return await http.get(`${apiV1ClickDashboard.baseUrl}?
		${requestParams.onlineAddressId? `onlineAddressId=${requestParams.onlineAddressId}` : ''}
		${requestParams.startAt? `&startAt=${requestParams.startAt}` : ''}
		${requestParams.endAt? `&endAt=${requestParams.endAt}` : ''}
		`);
	},
}

export  { apiV1ClickDashboard }