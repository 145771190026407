<template>
  <v-main class="full-width pt-5">
    <v-app-bar elevation="0" color="white" fixed class="ma-0 pa-0">
      <v-container class="pt-0 pb-0 mt-0 mb-0">
        <v-card-actions class="">
          <a href="/" class="link">
            <v-img src="/images/logo/logo-04.png" width="120" class="mr-2"/> 
          </a>
          <v-spacer/>
          <small v-if="!['xs'].includes($vuetify.breakpoint.name)">
            <a href='#sec-1' class="link mr-3 primary--text" >
              {{$t('shortenAddress').toUpperCase()}}
            </a>
            <a href='#sec-5' class="link mr-3 primary--text" >
              {{$t('infoProduct').toUpperCase()}}
            </a>
            <a href='#sec-3' class="link mr-3 primary--text">
              {{$t('plansAndPrices').toUpperCase()}}
            </a>
            <a href='#sec-4' class="link mr-3 primary--text">
              {{$t('sms').toUpperCase()}} 
            </a>
            <a href='#sec-2' class="link mr-3 primary--text" >
              {{$t('pageBio').toUpperCase()}}
            </a>
            <!-- <a href='#sec-5' class="link mr-3 primary--text" >
              {{$t('infoProduct').toUpperCase()}}
            </a> -->
          </small>
          <BtnLogin :screenData="screenData" v-if="isShow() && !['xs'].includes($vuetify.breakpoint.name)"/>
          <v-menu offset-y left v-else open-on-focus open-on-hover min-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                outlined
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="['xs'].includes($vuetify.breakpoint.name)">
                <v-list-item-content>
                  <a href='#sec-1' class="link mr-3 primary--text">
                    {{$t('shortenAddress')}}
                  </a>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="['xs'].includes($vuetify.breakpoint.name)">
                <v-list-item-content>
                  <a href='#sec-5' class="link mr-3 primary--text">
                    {{$t('infoProduct')}}
                  </a>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="['xs'].includes($vuetify.breakpoint.name)">
                <v-list-item-content>
                  <a href='#sec-3' class="link mr-3 primary--text" >
                    {{$t('plansAndPrices')}}
                  </a>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="['xs'].includes($vuetify.breakpoint.name)">
                <v-list-item-content>
                  <a href='#sec-4' class="link mr-3 primary--text">
                    {{$t('sms').toUpperCase()}}
                  </a>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="['xs'].includes($vuetify.breakpoint.name)">
                <v-list-item-content>
                  <a href='#sec-2' class="link mr-3 primary--text" >
                    {{$t('pageBio')}}
                  </a>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="['xs'].includes($vuetify.breakpoint.name)"/>
              <v-list-item>
                <v-list-item-content>
                  <BtnLogin :screenData="screenData" class="link mr-2"/>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
      </v-container>
    </v-app-bar>
    <div>
      <router-view :screenData="screenData"></router-view>
      <v-divider/>
      <v-footer class="main-footer" color="#252F3F" dark>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card-actions>
              <v-spacer/>
              <a href="/" class="link">
                <img src="/images/logo/logo-04.png" width="120" class="mr-2" alt="migly"/> 
              </a>
              <v-spacer/>
            </v-card-actions>
          </v-col>
          <v-col cols="12">
            <v-card-actions class="mb-5">
              <v-spacer/>
              <a class="link" href="mailto:contato@exatamente-solucoes.com">contato@exatamente-solucoes.com</a>
              <v-spacer/>
            </v-card-actions>
            <v-card-actions class="pl-0 pr-0">
              <v-spacer/>
              <small><p>migly.in é um produto <a class="link" href="https://exatamente-solucoes.com"> exatamente soluções.</a></p></small>
              <v-spacer/>
            </v-card-actions>
          </v-col>

        </v-row>
      </v-footer>
    </div>
  </v-main>
</template>

<script>
import BtnLogin from "./fragments/BtnLogin.vue"
  export default {
    name: 'Index',
    components: {
    BtnLogin,
},
    data: () => ({
      screenData: {
        auth: null,
        page: {content: []}
      }
    }),
    computed: {

    },
    mounted: function() {
      if(!this.isShow()){
        this.$router.push('/painel/links/shortened')
      }
    },
    methods: {
      isShow: function() {
        return !localStorage.authorization
      }
    },
  }
</script>
<style>
.pt-20{
  margin-top: 5em;
}
.v-toolbar__content {
  margin: 0px !important;
  padding: 0px !important;
}
</style>