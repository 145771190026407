<template>
    <v-autocomplete
      v-model="item"
      flat
      dense
      solo-inverted
      :placeholder="$t('search')"
      rounded
      hide-details
      :search-input.sync="search"
      :items="onlineAddressePage.content.concat(contactPage.content)"
      item-text="text"  
      no-filter
      return-object
      @change="select"
      :menu-props="{transition: true}"
      width="400"
      >
      <template v-slot:append>
        <v-slide-x-transition leave-absolute hide-on-leave>
          <v-progress-circular x-small  indeterminate width="1" size="22" v-if="loadingOnlineAddresses || loadingContacts" color="white"/>
          <v-icon v-else>mdi-magnify</v-icon>
        </v-slide-x-transition>
        <v-btn icon color="red" small @click="clear" v-if="item">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <template v-slot:append-outer>

      </template>
      <template v-slot:no-data>
        <v-list-item max-width="400" width="400">
          <v-card elevation="0">
            <v-card-title>
              {{$t('noData')}}
            </v-card-title>
            <v-card-subtitle>
              <strong>{{$t('noDataDescription')}}</strong>
            </v-card-subtitle>
          </v-card>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-card width="400" class="mb-1" elevation="0" color="transparent" v-if="onlineAddressePage.content.includes(item)">
          <v-list-item>
            <v-list-item-avatar
              color="white"
              tile
            >
              <template v-if="item.openGraph && item.openGraph.openGraphFields && item.openGraph.openGraphFields.filter(f => f.name == ('og:image'))[0]">
                <v-img lazy-src="/images/logo/logo-02.png" v-for="(field) in item.openGraph.openGraphFields.filter(f => f.name == ('og:image'))" :key="field.id" :src="field.value" position="center center" contain style="border-radius: 10px 0px 0px 10px;" aspect-ratio="1"/>
              </template>
              <v-img v-else src="/images/logo/logo-02.png" contain/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.code }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.url }}</v-list-item-subtitle>
              <div style="max-height: 80px; overflow-x: auto;">
                <v-chip-group class="mt-0 pt-0 mb-0 pb-0" column v-if="item.tags" >
                  <v-card class="ma-1 pl-2 pr-2 card-corner-radius" color="primary" dark v-if="false">
                    <v-icon small>mdi-link</v-icon>
                    {{ $t('originalLink') }}
                    <a :href="item.url" target="_blank" style="text-decoration: none;" class="white--text">
                      {{ !item.showUrl ? item.url.substring(0, 10).concat('...') : item.url}}
                    </a>
                    <a :href="item.url" target="_blank" style="text-decoration: none;">
                      <v-btn color="white" small icon>
                        <v-icon small>mdi-navigation-variant</v-icon>
                      </v-btn>
                    </a>
                    <v-btn icon @click="!item.showUrl ? item.showUrl = true : item.showUrl = false;$forceUpdate()" small color="white">
                      <v-icon v-if="!item.showUrl" small>mdi-eye-outline</v-icon>
                      <v-icon v-else small>mdi-eye-off</v-icon>
                    </v-btn>
                  </v-card>
                  <template v-if="item.tags"> 
                    <v-chip class="mr-1 mb-1" v-for="(tag, index) in item.tags.split(`||`)" :key="index" v-show="tag" small>
                      <v-icon x-small class="mr-1">mdi-tag</v-icon>
                      {{tag}}
                    </v-chip>
                  </template>
                </v-chip-group>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-link</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-card>
        <v-card width="400" class="mb-1" elevation="0" color="transparent" v-else-if="contactPage.content.includes(item)">
          <v-list-item>
            <v-list-item-avatar
              color="white"
              tile
            >
              <v-icon>mdi-card-account-mail-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>+{{ item.number }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-chip-group column>
                  <v-chip v-for="p, index in item.properties" :key="index" color="blue" dark small>
                    <v-icon class="mr-1">mdi-variable</v-icon>
                    {{ p.name }}: {{ p.value }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-subtitle>
              <div style="max-height: 80px; overflow-x: auto;">
                <v-chip-group class="mt-0 pt-0 mb-0 pb-0" column v-if="item.tags" >
                  <v-chip v-for="tag, index in item.tags.split(',')" :key="index" small color="blue" outlined>
                    <v-icon class="mr-1">mdi-tag</v-icon>
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-cellphone</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </template>
    </v-autocomplete>
</template>
<script>
import { apiV1Contacts } from '../../../service/resources/api-v1-contacts';
import { apiV1OnlineAddresses } from '../../../service/resources/api-v1-online-addresses';

  export default {
    name: 'Search',
    props: ['screenData'],
    components: {
    },
    data: () => ({
      loadingOnlineAddresses: false,
      text: '',
      item: null,
      search: null,
      onlineAddressePage: {content:[]},
      contactPage: {content:[]},
      loadingContacts: false
    }),
    mounted: function() {
    },
    computed: {
    },
    watch: {
      search: function (text) {
        this.searchOnlineAddresses(text)
        this.searchContacts(text)
      }
    },
    methods: {
      clear: function() {
        this.$router.push({query: {}})
        this.loadingOnlineAddresses = true
        this.item = null
        // apiV1OnlineAddresses.page({page: 0, size: 10, embedBiography: true, orderBy: 'type,dateCreate:DESC'}).then(response => {
        //   this.onlineAddressePage = response.data
        //   this.loadingOnlineAddresses = false
        //   if(this.screenData.auth && this.screenData.auth.user.subscriptionPlan.free)
        //     this.onlineAddressePage.content.splice(Math.floor(Math.random() * (this.onlineAddressePage.content.length - 0 + 1) + 0), 0, {ads: true})
        //   this.screenData.page = this.onlineAddressePage
        // })
        this.onlineAddressePage.content = []
        this.contactPage.content = []
      },
      select: function() {
        if(this.onlineAddressePage.content.includes(this.item)){
          if(this.onlineAddressePage.content[0])
            this.onlineAddressePage.content.splice(0, 0, this.onlineAddressePage.content.splice(this.onlineAddressePage.content.indexOf(this.item), 1)[0])
          if(this.screenData.auth && this.screenData.auth.user.subscriptionPlan.free)
            this.onlineAddressePage.content.splice(Math.floor(Math.random() * (this.onlineAddressePage.content.length - 0 + 1) + 0), 0, {ads: true, id: 0})
          this.screenData.page = this.onlineAddressePage
          this.screenData.address = this.item
          this.$router.push({path: `/painel/links/shortened/${this.item.id}`, query: {page: this.screenData.page.number, text: this.text}})
        }else if(this.contactPage.content.includes(this.item)) {
          let query = {page:0, text: this.text, select: this.item.id}
          let path = `/painel/sms/contacts`
          if(this.$route.path == path) {
            this.$router.push({query: query})
          } else {
            this.$router.push({path: path, query: query})
          }
        }
      },
      searchOnlineAddresses: function(text){
        this.loadingOnlineAddresses = true
        apiV1OnlineAddresses.page({page: 0, size: 10, text: `${text}`, embedBiography: true, orderBy: 'type,dateCreate:DESC'}).then(response => {
          this.onlineAddressePage = response.data
          this.loadingOnlineAddresses = false
          this.text = text
          this.onlineAddressePage.content.forEach(item => item.text = item.code)
        })
      },
      searchContacts: function(text){
        this.loadingContacts = true
        apiV1Contacts.page({page: 0, size: 10, text: `${text}`, embedBiography: true, orderBy: 'dateCreate:DESC'}).then(response => {
          this.contactPage = response.data
          this.loadingContacts = false
          this.text = text
          this.contactPage.content.forEach(item => item.text = item.number)
        })
      }
    },
  }
</script>
<style scoped>
</style>