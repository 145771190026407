<template>
  <v-row no-gutters v-if="screenData.auth">
    <v-col cols="12" sm="7">
      <v-card-text class="pt-0 mt-0">
        <v-card>
          <v-card-title class="primary--text">{{$t('screenLoading')}}</v-card-title>
          <v-card-subtitle>{{$t('screenLoadingDescription')}}</v-card-subtitle>
          <v-card-text v-if="loading">
            <v-skeleton-loader type="card-heading" />
            <v-skeleton-loader type="list-item-two-line" />
            <v-skeleton-loader type="article, actions"/>
          </v-card-text>
          <v-form v-else v-model="formConfig">
            <v-card-subtitle class="primary--text">{{$t('logoPicture')}}</v-card-subtitle>
            <v-card-actions class="white--text mt-0">
              <v-spacer/>
              <v-btn color="primary" rounded dark class="mr-2">
                <v-file-input
                  class="mt-0 pt-0 mr-0 pr-0"
                  @change="handlePicture"
                  hide-input
                  prepend-icon="mdi-camera"
                  hide-details
                  hide-actions
                  hide-controls
                  accept="image/png, image/jpeg, image/bmp"
                  :disabled="screenData.auth.user.subscriptionPlan.id < 3"
                />
              </v-btn>
              <v-card class="rounded-card">
                <v-card-text class="pt-1 pl-1 pr-1 pb-1">
                  <v-avatar size="100" >
                    <v-img v-if="config.picture && config.picture.src" :src="config.picture.src"/>
                    <v-icon v-else>mdi-camera</v-icon>
                  </v-avatar>
                </v-card-text>
              </v-card>
              <v-btn color="red" rounded class="ml-2" @click="(config.picture ? config.picture.src = null : config.picture = null),$forceUpdate()" :disabled="!config.picture || !config.picture.src || screenData.auth.user.subscriptionPlan.id < 3">
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
              <v-spacer/>
            </v-card-actions>
            <v-card-text>
              <v-text-field
                v-if="config.picture"
                v-show="false"
                v-model="config.picture.src"
                outlined
                required
                :rules="[
                  v => !!v
                ]"
                />
              <v-text-field
                v-model="config.siteName"
                outlined
                :label="$t('siteName')"
                :placeholder="$t('siteName')"
                prepend-inner-icon="mdi-rename-box"
                required
                maxlength="20"
                counter
                :rules="[
                  v => !!v
                ]"
                :disabled="screenData.auth.user.subscriptionPlan.id < 3"
                />
            </v-card-text>
            <v-subheader>{{$t('loaderTime')}} {{config.loaderTime}}s</v-subheader>
            <v-card-text>
              <v-slider
                v-model="config.loaderTime"
                max="10"
                min="1"
                :tick-labels="['1s','2s','3s','4s','5s','6s','7s','8s','9s','10s']"
                step="1"
                ticks="always"
                track-color="grey"
                always-dirty
                tick-size="4"
                prepend-icon="mdi-clock-time-eleven-outline"
                :disabled="screenData.auth.user.subscriptionPlan.id < 3"
                @change="progress = 0"
              ></v-slider>
            </v-card-text>
            <v-divider/>
            <v-card-title class="primary--text">{{$t('googleAnalitcs')}}</v-card-title>
            <v-card-subtitle>
                {{$t('googleAnalitcsDescription')}}
                <a target="_blanc" href="https://support.google.com/analytics/answer/12270356?hl=pt-BR">Google Analytics</a>.
            </v-card-subtitle>
            <v-card-text v-if="loading" >
              <v-skeleton-loader type="list-item" />
            </v-card-text>
            <v-card-text v-else>
              <v-text-field
                v-model="config.googleAnalyticsId"
                outlined
                :label="$t('googleAnalitcsId')"
                :placeholder="$t('googleAnalitcsExample')"
                prepend-inner-icon="mdi-google-analytics"
                required
                maxlength="12"
                counter
                :disabled="screenData.auth.user.subscriptionPlan.id < 3"
                />
            </v-card-text>
            <v-divider/>


            <v-card-text v-if="loading" >
              <v-skeleton-loader type="list-item" />
            </v-card-text>
            <div v-else>
              <v-card-title class="primary--text">{{$t('disclosure')}}</v-card-title>
              <v-card-subtitle>
                  {{$t('disclosureDescription')}}
              </v-card-subtitle>
              <v-card-subtitle class="primary--text">
                  <b>{{ $t('imageBanner') }}</b>
                  <v-spacer/>
                  <v-file-input
                    x-small
                    class="ml-3"
                    ref="imageBannerfileInput"
                    accept="image/*"
                    dark
                    color="primary"
                    hide-input
                    hide-details
                    hide-actions
                    prepend-icon="mdi-file-upload"
                    icon
                    density="density"
                    rounded
                    flat
                    dense
                    @change="handleCrop($event, cropImageBannerData)"
                    />
                </v-card-subtitle>
                <v-card-text>
                  <v-card elevation="0" outlined>
                    <v-img :src="config && config.bannerImage && !config.bannerImage.deleted ? config.bannerImage.src : ''" height="100">
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn fab color="primary" @click="$refs.imageBannerfileInput.$refs.input.click()" x-small>
                          <v-icon color="white">mdi-upload-circle</v-icon>
                        </v-btn>
                        <v-spacer/>
                        <v-btn v-if="config && config.bannerImage && !config.bannerImage.deleted && config.bannerImage.src" fab color="primary" @click="config.bannerImage.id ? config.bannerImage.deleted = true : config.bannerImage = null" x-small>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-img>
                  </v-card>
                  <v-text-field
                    class="mt-2"
                    v-model="config.bannerLink"
                    outlined
                    :label="$t('link')"
                    :placeholder="$t('link')"
                    prepend-inner-icon="mdi-link"
                    required
                    maxlength="400"
                    counter
                    :disabled="screenData.auth.user.subscriptionPlan.id < 3 || !(config && config.bannerImage && !config.bannerImage.deleted && config.bannerImage.src)"
                    :rules="[rules.url]"
                    />
                </v-card-text>
            </div>
            <v-divider/>



            <v-card-actions>
              <v-spacer/>
              <v-btn rounded color="primary" :disabled="!formConfig || screenData.auth.user.subscriptionPlan.id < 3" @click="save" :loading="loadingSave">
                {{$t('save')}}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-card-text>
    </v-col>
    <v-col cols="12" sm="5">
      <v-card-text v-if="loading" >
        <v-card-actions class="mb-0 -b-0">
          <v-spacer/>
          <v-skeleton-loader type="avatar" />
          <v-spacer/>
        </v-card-actions>
        <v-skeleton-loader type="list-item" />
      </v-card-text>
      <v-card v-else class="mr-4 mb-4 ml-4 overflow-auto" height="98%">
        <v-card-actions>
          <v-chip color="primary">
            {{$t('previewSCreenLoader')}}
          </v-chip>
        </v-card-actions>
        <div class="verticle-center">
          <div class="horizontaly-center">
            <v-card-actions class="mb-0 -b-0">
              <v-spacer/>
              <v-avatar width="150" height="150">
                <v-img v-if="config.picture && config.picture.src" :src="config.picture.src" /> 
                <v-img v-else src="/images/logo/logo-04.png" contain/> 
              </v-avatar>
              <v-spacer/>
            </v-card-actions>
            <v-card-title class="mb-0 -b-0">
              <v-spacer/>
              {{config.siteName}}
              <v-spacer/>
            </v-card-title>
            <v-card-actions>
              <v-spacer/>
              <v-progress-circular x-small :value="progress" width="1" size="22" color="primary"/>
              <v-spacer/>
            </v-card-actions>
          </div>
        </div>
        <v-footer absolute color="transparent" v-if="config && config.bannerImage && !config.bannerImage.deleted && config.bannerImage.src">
          <v-container>
            <a :href="config.bannerLink" target="_blank">
              <v-card>
                <v-img :src="config.bannerImage.src" height="100"/>
              </v-card>
            </a>
          </v-container>
        </v-footer>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="snackbar"
      top
      right
      color="green"
    >
      {{ $t('saved') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <CropImage :data="cropImageBannerData" :actionSave="setImageBanner" :stencilSize="{width: 200, height: 40}"/>
  </v-row>
</template>
<script>
import { apiV1Configs } from '../../../service/resources/api-v1-config'
import CropImage from './CropImage.vue';

  export default {
    name: 'Config',
    props: ['screenData'],
    components: {
      CropImage,
    },
    data: () => ({
      progress: 0,
      snackbar: false,
      formConfig: false,
      loading: true,
      loadingSave: false,
      config: null,
      cropImageBannerData: {dialog: false, img: null},
      rules: {}
    }),
    mounted: function() {
      this.loading = true
      apiV1Configs.getByAuth().then(result => {
        if(result.data) {
          this.config = result.data
        } else {
          this.config = {}
        }
        this.loading = false
      })

      setInterval(() => {
        this.progress = this.progress + (100 / this.config.loaderTime)
      }, 1000);

      this.rules = {
        url: value => {
          return (this.isUrl(value)) || this.$t('invalidUrl')
        }
      }

    },
    computed: {

    },
    methods: {
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        // const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        return pattern.test(value)
      },
      setImageBanner: function(image) {
        if(!this.config.bannerImage)
          this.config.bannerImage = {id: null, src: image.src}
        else
          this.config.bannerImage.src = image.src
        this.config.bannerImage.deleted = false
      },
      handlePicture: function(event) {
        var reader = new FileReader()
        reader.readAsDataURL(event)
        let self = this
        reader.onload = function () {
          if(!self.config.picture)
            self.config.picture = {}
          self.config.picture.src = reader.result
          self.$forceUpdate()
        }
      },
      handleCrop: function(event, data){
        var reader = new FileReader()
        reader.readAsDataURL(event)
        if(reader.size > 2000000) {
          this.$commons.updateSnackbar({show: true, color: 'error', text: this.$t('invalid')})
          return
        }
        reader.onload = function () {
          data.img = {src: reader.result}
          data.dialog = true
        }
      },
      save: function() {
        this.loadingSave = true
        apiV1Configs.post(this.config).then(result => {
          this.config = result.data
          this.loadingSave = false
          this.snackbar = true
        })
      }
    },
  }
</script>
<style scoped>
</style>